import * as yup from "yup";

/**
 * @deprecated use the new Zod schema from `common-client`
 */
export const emailSchema = yup.object({
  email: yup
    .string()
    .email("Please provide a valid email")
    .max(320)
    .required("Please provide a valid email"),
});

/**
 * @deprecated use the new Zod schema from `common-client`
 */
export const passwordSchema = yup.object({
  password: yup
    .string()
    .min(8, "Password should be longer than 8 characters")
    .matches(/[A-Z]/, {
      message: "Password should contain at least an uppercase letter",
    })
    .matches(/[0-9]/, {
      message: "Password should contain at least one digit",
    }),
});

/**
 * @deprecated use the new Zod schema from `common`
 */
export const taxRateSchema = yup.object({
  federalIncomeTaxRate: yup
    .number()
    .typeError("Invalid value")
    .min(0)
    .max(100)
    .required("Required"),
  federalCapitalGainsTaxRate: yup
    .number()
    .typeError("Required")
    .required("Required"),
  stateCapitalGainsTaxRate: yup
    .number()
    .typeError("Invalid value")
    .min(0)
    .max(100)
    .required("Required"),
});
