import { PlaidAccountStatus } from "../generated/graphql";

export enum PlaidVerificationStatus {
  AutomaticallyVerified = "automatically_verified",
  PendingAutomaticVerification = "pending_automatic_verification",
  PendingManualVerification = "pending_manual_verification",
  ManuallyVerified = "manually_verified",
  VerificationExpired = "verification_expired",
  VerificationFailed = "verification_failed",
}

type Args = {
  status: PlaidAccountStatus;
  verificationStatus?: PlaidVerificationStatus | string | null;
};
export const isPlaidAccountGranted = (a: Args) => {
  return (
    [PlaidAccountStatus.OpsGranted, PlaidAccountStatus.SystemGranted].includes(
      a.status
    ) &&
    !(
      a.verificationStatus &&
      [
        PlaidVerificationStatus.PendingManualVerification,
        PlaidVerificationStatus.PendingAutomaticVerification,
        PlaidVerificationStatus.VerificationFailed,
      ].includes(a.verificationStatus as PlaidVerificationStatus)
    )
  );
};

export const isPlaidAccountMicrodepositVerified = (a?: Args) => {
  // microdeposit verification will connect a bank account via plaid, but
  // won't give us any more info than the bank account number
  return (
    a?.verificationStatus &&
    [
      PlaidVerificationStatus.ManuallyVerified,
      PlaidVerificationStatus.AutomaticallyVerified,
    ].includes(a.verificationStatus as PlaidVerificationStatus)
  );
};

export const isPlaidAccountNonRejected = (a: Args) => {
  return [
    PlaidAccountStatus.OpsGranted,
    PlaidAccountStatus.SystemGranted,
    PlaidAccountStatus.SystemPending,
  ].includes(a.status);
};

export const isPlaidAccountNonOpsRejected = (a: Args) => {
  return [
    PlaidAccountStatus.OpsGranted,
    PlaidAccountStatus.SystemGranted,
    PlaidAccountStatus.SystemPending,
    PlaidAccountStatus.SystemRejected,
  ].includes(a.status);
};

export const isPlaidDepositAccountDeleteable = (a?: Args) => {
  // users can only delete their own deposit accounts if they are pending or rejected name checks
  return (
    a &&
    ([
      PlaidAccountStatus.SystemPending,
      PlaidAccountStatus.SystemRejected,
      PlaidAccountStatus.OpsRejected,
    ].includes(a.status) ||
      (a.verificationStatus &&
        [
          PlaidVerificationStatus.PendingManualVerification,
          PlaidVerificationStatus.PendingAutomaticVerification,
          PlaidVerificationStatus.VerificationFailed,
        ].includes(a.verificationStatus as PlaidVerificationStatus)))
  );
};

// https://plaid.com/docs/errors/
export enum PlaidApiErrorCodes {
  AccessNotGranted = "ACCESS_NOT_GRANTED",
  ItemLoginRequired = "ITEM_LOGIN_REQUIRED",
  NoAccounts = "NO_ACCOUNTS",
}

export const PlaidErrorCodesRequiringReconnect = [
  PlaidApiErrorCodes.ItemLoginRequired,
  PlaidApiErrorCodes.NoAccounts, // User found Chase returned this error
];
