import Decimal from "decimal.js";
import z from "zod";

import {
  BuyBackIndicatorEnum,
  LongTermShortTermIndicatorEnum,
  RealizedIndicatorEnum,
  WashSaleIndicatorEnum,
} from "../../common";
import { DateOnly } from "../../date_utils";
import {
  DateInterval,
  DirectIndexSolver,
  StockIndex,
} from "../../generated/graphql";
import { makeDecimal, Percentage } from "../../utils";

/**
 * Type definitions which are shared across the full simulator stack:
 *  - API
 *  - Database
 *  - Business logic
 */
const percentage = z
  .object({
    percent: z.string(),
    rate: z.string(),
  })
  .transform((s) => new Percentage({ percent: s.percent }));

const stringDecimal = z.string().transform((s) => new Decimal(s));
const dateOnlyString = z
  .string()
  .transform((s) => DateOnly.fromYYYYMMDDFormat(s));

export const directIndexSimulationParamsToString = ({
  startDate,
  endDate,
  ...rest
}: DirectIndexSimulationParams) => {
  return JSON.stringify({
    startDate: startDate.toYYYYMMDDFormat(),
    endDate: endDate.toYYYYMMDDFormat(),
    ...rest,
  });
};

const DecimalZod = z.string().transform((s) => makeDecimal(s));

const DateOnlyZod = z
  .object({
    date: z.string().transform((s) => {
      return DateOnly.fromISO8601FormatStringToTz(s, "UTC");
    }),
  })
  .transform(({ date }) => date);

const TaxLotSchema = z.object({
  id: z.string(),
  subAccountId: z.string(),
  securityId: z.string(),
  eventTime: z.string().transform((s) => new Date(s)),
  settlementDate: DateOnlyZod.optional(),
  taxLotOpenBuyDate: DateOnlyZod.optional(),
  taxLotCloseSellDate: DateOnlyZod.optional(),
  openBuyPrice: DecimalZod,
  openBuyCostAmount: DecimalZod,
  openTransactionId: z.string(),
  openLotId: z.string(),
  closedLotId: z.string().optional(),
  closedTransactionId: z.string().optional(),
  quantity: DecimalZod,
  cost: DecimalZod,
  netProceed: DecimalZod,
  realizedGainLoss: DecimalZod,
  longTermShortTermIndicator: z.nativeEnum(LongTermShortTermIndicatorEnum),
  realizedIndicator: z.nativeEnum(RealizedIndicatorEnum),
  washSalesIndicator: z.nativeEnum(WashSaleIndicatorEnum),
  washSalesDisallowed: DecimalZod,
  washSaleAdjustmentAmount: DecimalZod,
  buyBackIndicator: z.nativeEnum(BuyBackIndicatorEnum),
});

const TaxLotWithSymbolSchema = z.object({
  symbol: z.string(),
  taxLot: TaxLotSchema,
});

const HoldingSchema = z.object({
  symbol: z.string(),
  quantity: DecimalZod,
  taxLots: z.array(TaxLotWithSymbolSchema),
});

const WeightSchema = z.object({
  symbol: z.string(),
  weight: DecimalZod,
  computedAt: z
    .string()
    .transform((s) => new Date(s))
    .optional(),
});
export type Weight = z.infer<typeof WeightSchema>;

const ExecutionSchema = z.object({
  executionId: z.string(),
  dateTime: z.string().transform((s) => new Date(s)),
  weights: z.array(WeightSchema),
});
export type Execution = z.infer<typeof ExecutionSchema>;

const CashTransferSchema = z.object({
  dateTime: z.string().transform((s) => new Date(s)),
  cashAmount: DecimalZod,
  // TODO: stock deposits?
});
export type CashTransfer = z.infer<typeof CashTransferSchema>;

const WithdrawalAmountSchema = z.discriminatedUnion("kind", [
  z.object({ kind: z.literal("full") }),
  z.object({ kind: z.literal("partial"), amount: DecimalZod }),
]);
export type WithdrawalAmount = z.infer<typeof WithdrawalAmountSchema>;

const FutureEventsSchema = z.object({
  cashTransfers: z.array(CashTransferSchema),
  weights: z.array(WeightSchema).default([]),
});
export type FutureEvents = z.infer<typeof FutureEventsSchema>;

const SnapshotStateSchema = z.object({
  cash: DecimalZod,
  holdings: z.array(HoldingSchema),
});

const DailyValueSchema = z.object({
  date: z.string().transform((s) => new Date(s)),
  value: DecimalZod,
});

export const SnapshotSchema = z.object({
  asOfDate: DateOnlyZod,
  snapshot: SnapshotStateSchema,
  futureEvents: FutureEventsSchema,
  performance: z.array(DailyValueSchema).optional(),
});

export type Snapshot = z.infer<typeof SnapshotSchema>;

/**
 * This will form the schema of the input to the simulation. It will be
 * serialized in the API layer as a string & in the DB as an object
 */
export const DirectIndexSimulationParams = z.object({
  // just to keep track of who ran the simulation
  user: z.string().optional(),

  /**
   * If present, we will use this to drive the initial state of the simulation
   */
  fromAccountSnapshot: SnapshotSchema.optional(),

  cash: stringDecimal,
  depositInfo: z
    .object({
      amount: stringDecimal,
      interval: z.nativeEnum(DateInterval),
    })
    .optional(),
  endDate: dateOnlyString,
  etfOnlyHarvestingSymbol: z.string().optional(),
  indexMatchOnly: z.boolean().optional(),
  interval: z.nativeEnum(DateInterval),
  startDate: dateOnlyString,
  symbolsToIndex: z.array(z.string()).optional(),
  targetIndex: z.nativeEnum(StockIndex).optional(),
  simulationOptions: z
    .object({
      // When a corporate action occurs (i.e. disappears from the data) we
      // convert this percentage to cash.
      corporateActionCashPercentage: percentage.optional(),
      reinvestLossesPercentage: percentage.optional(),
      trackHistory: z.boolean(),
      monthlyFeePercentage: percentage.optional(),
    })
    .optional(),
  directIndexOptions: z
    .object({
      // allow selling of tax lots that have capital gains
      allowGains: z.boolean().optional(),
      // allow selling of any tax lots whatsoever
      allowSelling: z.boolean().optional(),
      // allow selling of any tax lots whatsoever
      lossHarvestingFactor: stringDecimal.optional(),
      avoidDayTrades: z.boolean().optional(),
      avoidWashSales: z.boolean().optional(),
      avoidSmallWeightPositions: z.boolean().optional(),
      avoidBuyingSymbolsWithLoss: z.boolean().optional(),

      /**
       *  Max percentage of holdings-with-losses to prevent buying. A value of 10.0
       *  means at most 10% of the holdings will be set to "cant buy". The
       *  calculatorService will use MAX_PERCENTAGE_TO_PREVENT_BUYING default if not
       *  provided
       */
      maxPercentageToPreventBuying: stringDecimal.optional(),
      /**
       * Minimum amount of cash to keep as a buffer, calculatorService will use
       * CASH_BUFFER default if not provided.
       */
      minCashBuffer: stringDecimal.optional(),
      /**
       * value of 5.0 will filter out trades less than $5, calculatorService will use
       * MINIMUM_DOLLAR_VALUE_FOR_STOCK_TRADE default if not provided
       */
      minimumTradeAmount: stringDecimal.optional(),
      /**
       *  The target value for a single stock must be greater than this amount in
       *  order for the optimization to purchase it. The calculatorService will use
       *  MINIMUM_VALUE_FOR_INVESTMENT default if not provided
       */
      minimumValueForInvestment: stringDecimal.optional(),
      /**
       * Will internally calculate and use the covariance matrix based on past price
       * movements.  If false, will use the identity matrix.
       */
      useCovarianceMatrix: z.boolean().optional(),

      // default is 20bps (0.002)
      transactionCosts: stringDecimal.optional(),
    })
    .optional(),
  directIndexSolverOptions: z
    .object({
      forceLiquidateZeroTargets: z.boolean().optional(),
      solver: z.nativeEnum(DirectIndexSolver).optional(),
      timeoutMs: z.number().optional(),
    })
    .optional(),
});

export const directIndexSimulationResultToString = ({
  states,
  ...rest
}: DirectIndexSimulationResult) => {
  const states1 = states.map(({ date, ...s }) => ({
    date: date.toYYYYMMDDFormat(),
    lastFeeDate: s.lastFeeDate?.toYYYYMMDDFormat(),
    ...s,
  }));

  return JSON.stringify({
    states: states1,
    ...rest,
  });
};

/**
 * This will form the schema of the result of the simulation. It will be
 * serialized in the API layer as a string & in the DB as an object
 */
export const DirectIndexSimulationResult = z.object({
  id: z.string(),
  states: z.array(
    z.object({
      date: dateOnlyString,
      holdings: z.array(
        z.object({
          symbol: z.string(),
          price: stringDecimal,
          targetWeight: stringDecimal,
          quantity: stringDecimal,
          cost: stringDecimal,
        })
      ),
      cash: stringDecimal,
      reinvestedLosses: stringDecimal,
      lastFeeDate: dateOnlyString.optional(),
      logData: z.object({
        feesPaid: stringDecimal.optional(),
        stockValue: stringDecimal,
        shortTermGains: stringDecimal,
        longTermGains: stringDecimal,
        deposits: stringDecimal,
        corporateActionCash: stringDecimal,
        tradeVolume: stringDecimal.optional(),
        tradeFees: stringDecimal.optional(),
        actions: z
          .array(
            z.object({
              direction: z.string(),
              symbol: z.string(),
              quantity: stringDecimal,
              price: stringDecimal,
              proceeds: stringDecimal,
              shortTermGains: stringDecimal,
              longTermGains: stringDecimal,
            })
          )
          .default([]),
      }),
    })
  ),
});

export type DirectIndexSimulationParams = z.infer<
  typeof DirectIndexSimulationParams
>;

export type DirectIndexSimulationResult = z.infer<
  typeof DirectIndexSimulationResult
>;
