import { FieldPolicy } from "@apollo/client";
import { DateOnly } from "@frec-js/common";

export const dateOnly: FieldPolicy<
  DateOnly | null | undefined,
  string | DateOnly | null | undefined
> = {
  merge: (_, incoming) => {
    if (incoming === null || incoming === undefined) {
      // It's important for these methods to return null if passed null
      return incoming;
    } else if (incoming instanceof DateOnly) {
      // In tests our mocks already have DateOnly
      return incoming;
    } else {
      return new DateOnly(incoming as string);
    }
  },
};
