import { configure } from "safe-stable-stringify";

const safeStableStringify = configure({
  bigint: true,
  circularValue: "[Circular]",
});

// Safe, deterministic and fast serialization alternative to JSON.stringify; Gracefully handles circular structures and bigint instead of throwing.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function safeStringify(value: any): string {
  return safeStableStringify(value);
}

// While California doesn't allow accents or other non-latin characters in names, some states do.
// Apex does not support them, so we strip them out when talking to Apex.
// When throwing an error, Apex says: "This field may only contain letters, periods, apostrophes, hyphens, slashes, or spaces"
export function normalizeName(name?: string) {
  return (
    name?.normalize("NFD").replace(/([\u0300-\u036f]|[^0-9a-zA-Z.' -])/g, "") ??
    ""
  );
}

export function normalizeAddress(name?: string) {
  return name
    ?.normalize("NFD")
    .replace(/([\u0300-\u036f]|[^0-9a-zA-Z.' -])/g, "");
}

export function normalizeFullName(firstName: string, lastName: string): string {
  return normalizeName(firstName) + " " + normalizeName(lastName);
}

/**
 * Apex expects the full/legal name to be 30 characters max and it must include the
 * last (family) name.
 */
export function normalizeAndTruncateFullName(
  firstName: string,
  lastName: string,
  length = 30
): string {
  const normalizedFirstName = normalizeName(firstName);
  const normalizedLastName = normalizeName(lastName);
  // minus 1 for the space between first and last name
  const charsLeftForFirstName = Math.max(
    0,
    length - normalizedLastName.length - 1
  );
  return (
    normalizedFirstName.slice(0, charsLeftForFirstName) +
    (charsLeftForFirstName > 0 ? " " : "") +
    normalizedLastName
  );
}

/**
 * Generate unique alphanumeric id of specified length
 */
export const alphaNumericId = (len = 21) => {
  return (Date.now().toString(36) + Math.random().toString(36)).slice(-len);
};

// Takes the file name and truncates name to be of length defined by maxLength
// If name is truncated, adds an identifier ([T]) at the end
const TRUNCATION_IDENTIFIER = "[T]";
export const fileNameTruncate = (
  fileName: string,
  maxLength: number
): string => {
  if (fileName.length <= maxLength) {
    return fileName; // Return the original file name (with extension) if it's already within the length limit
  }

  const [fileNameWithoutExtension, extension] =
    getFileNameAndExtension(fileName);

  const truncatedName =
    (fileNameWithoutExtension?.substring(
      0,
      maxLength - TRUNCATION_IDENTIFIER.length - (extension?.length ?? 0)
    ) ?? "") + TRUNCATION_IDENTIFIER; // Truncate the file name without extension and add identifier at the end
  return truncatedName + extension; // Append the file extension to the truncated file name
};

/**
 * Get the name and extension of a document
 */
export const getFileNameAndExtension = (fileName: string): string[] => {
  const extensionIndex = fileName.lastIndexOf(".");
  const fileNameWithoutExtension =
    extensionIndex !== -1 ? fileName.slice(0, extensionIndex) : fileName;
  const extension = extensionIndex !== -1 ? fileName.slice(extensionIndex) : "";

  return [fileNameWithoutExtension, extension];
};

export const pluralWords = (num: number, word: string) => {
  return num !== 1 ? `${num} ${word}s` : `${num} ${word}`;
};

export function convertToTitleCase(input: string): string {
  return input
    .replace(/\s+/g, " ")
    .trim()
    .toLowerCase()
    .replace(/(^|\s)\S/g, function (match) {
      return match.toUpperCase();
    });
}

export const sanitizeSymbolParam = (unsafeSymbol?: string) => {
  return unsafeSymbol?.replace(/[^a-zA-Z0-9./-]/g, "");
};

export const formatSSN = (value: string) => {
  const rawValue = value.replace(/\D/g, "");
  // Add dashes at correct positions
  return rawValue
    .replace(/^(\d{3})(\d)/, "$1-$2")
    .replace(/-(\d{2})(\d)/, "-$1-$2")
    .slice(0, 11);
};
