import Decimal from "decimal.js";

import { ValidationResultLevel } from "../common";
import { SubAccount } from "../generated/graphql";
import { get, ZERO } from "../utils";

export enum SubAccountStockTransferValidationResult {
  NoTaxLotsSpecified = "NoTaxLotsSpecified",
  QtyMismatchPortfolioTaxLots = "QtyMismatchPortfolioTaxLots",
  SameSubAccounts = "SameSubAccounts",
  SecurityQuantityMissing = "InvalidSecurityQuantity",
  SubAccountNotFound = "SubAccountNotFound",
  TaxLotsNotPresentInSourceSubAccount = "TaxLotsNotPresentInSourceSubAccount",
}

export const SubAccountStockTransferValidationResultLevelMap: Record<
  SubAccountStockTransferValidationResult,
  ValidationResultLevel
> = {
  InvalidSecurityQuantity: ValidationResultLevel.Error,
  NoTaxLotsSpecified: ValidationResultLevel.Error,
  QtyMismatchPortfolioTaxLots: ValidationResultLevel.Error,
  SameSubAccounts: ValidationResultLevel.Error,
  SubAccountNotFound: ValidationResultLevel.Error,
  TaxLotsNotPresentInSourceSubAccount: ValidationResultLevel.Error,
};

export type LotToTransferBySymbol = {
  securityId: string;
  symbol: string;
  lotsToTransferIds: string[];
};

export type SubAccountStockTransferArgs = {
  clearingAccountId: string;
  fromSubAccountPortfolioQuantity: Map<string, Decimal>;
  fromSubAccountTaxLotQuantity: Map<string, Decimal>;
  fromSubAccount?: SubAccount;
  toSubAccount?: SubAccount;
  lotsToTransferBySymbol: LotToTransferBySymbol[];
  sourceSubAccountLots: Set<string>;
  roundingError?: Decimal;
};

export const validateSubAccountStockTransfer = (
  args: SubAccountStockTransferArgs
) => {
  const results = new Set<SubAccountStockTransferValidationResult>();

  const lotsToTransferIds = args.lotsToTransferBySymbol
    .map((l) => l.lotsToTransferIds)
    .flat();

  // check that we have some lots to move
  if (lotsToTransferIds.length === 0) {
    results.add(SubAccountStockTransferValidationResult.NoTaxLotsSpecified);
  }

  // check if lots view and portfolio view lines up
  args.lotsToTransferBySymbol.forEach((l) => {
    const securityId = l.securityId;
    if (
      args.fromSubAccountPortfolioQuantity.has(securityId) &&
      args.fromSubAccountTaxLotQuantity.has(securityId)
    ) {
      const qP = get(args.fromSubAccountPortfolioQuantity.get(securityId));
      const qL = get(args.fromSubAccountTaxLotQuantity.get(securityId));
      if (
        !qP
          .sub(qL)
          .abs()
          .lte(args.roundingError ?? ZERO)
      ) {
        results.add(
          SubAccountStockTransferValidationResult.QtyMismatchPortfolioTaxLots
        );
      }
    } else {
      results.add(
        SubAccountStockTransferValidationResult.SecurityQuantityMissing
      );
    }
  });

  // check that both sub accounts are valid
  if (!args.fromSubAccount || !args.toSubAccount) {
    results.add(SubAccountStockTransferValidationResult.SubAccountNotFound);
  } else if (get(args.fromSubAccount).id === get(args.toSubAccount).id) {
    results.add(SubAccountStockTransferValidationResult.SameSubAccounts);
  }

  // check that all lots we want to move are present in source sub account
  const validLots = lotsToTransferIds.every((id) =>
    args.sourceSubAccountLots.has(id)
  );
  if (!validLots) {
    results.add(
      SubAccountStockTransferValidationResult.TaxLotsNotPresentInSourceSubAccount
    );
  }

  return Array.from(results);
};
