import Decimal from "decimal.js";

import { ValidationResultLevel } from "../common/enums";
import { ACH_DAILY_TRANSFER_LIMIT } from "../constants/cashTransfer";
import {
  DI_ONBOARDING_PRICE_FLUCTUATION_BUFFER,
  DI_SUPPORTED_LIQUIDATION_DESTINATIONS,
  DirectIndexingTypeShortText,
  DirectIndexingTypeText,
} from "../constants/directIndex";
import {
  CashTransferDirection,
  CashTransferMethod,
  DirectIndexType,
  GicsCode,
  MoneyMovementSourceType,
} from "../generated/graphql";
import { formatUsd, ZERO } from "../utils";
import {
  CreateCashTransferValidationErrorMessage,
  CreateCashTransferValidationResult,
  validateExternalCashTransfer,
} from "./cashTransfer";

export enum SubAccountCashTransferValidationResult {
  InsufficientCash = "InsufficientCash",
  AmountShouldBeGreaterThanZero = "AmountShouldBeGreaterThanZero",
}

export const SubAccountCashTransferValidationResultLevelMap: Record<
  SubAccountCashTransferValidationResult,
  ValidationResultLevel
> = {
  InsufficientCash: ValidationResultLevel.Error,
  AmountShouldBeGreaterThanZero: ValidationResultLevel.Error,
};

export type SubAccountCashTransferArgs = {
  amount: Decimal;
  cashAvailable: Decimal;
};

export const validateSubAccountCashTransfer = (
  args: SubAccountCashTransferArgs
) => {
  const results = new Set<SubAccountCashTransferValidationResult>();

  if (args.amount.lessThanOrEqualTo(0)) {
    results.add(
      SubAccountCashTransferValidationResult.AmountShouldBeGreaterThanZero
    );
  }

  if (args.amount.greaterThan(args.cashAvailable)) {
    results.add(SubAccountCashTransferValidationResult.InsufficientCash);
  }

  return Array.from(results);
};

export enum LiquidatePortfolioValidationResult {
  InsufficientPortfolioValue = "InsufficientPortfolioValue",
  AmountShouldBeGreaterThanZero = "AmountShouldBeGreaterThanZero",
  InvalidLiquidationAmount = "InvalidLiquidationAmount",
  InvalidLiquidationDestination = "InvalidLiquidationDestination",
}

export const LiquidatePortfolioValidationResultLevelMap: Record<
  LiquidatePortfolioValidationResult,
  ValidationResultLevel
> = {
  InsufficientPortfolioValue: ValidationResultLevel.Error,
  AmountShouldBeGreaterThanZero: ValidationResultLevel.Error,
  InvalidLiquidationAmount: ValidationResultLevel.Error,
  InvalidLiquidationDestination: ValidationResultLevel.Error,
};

export type LiquidateDirectIndexPortfolioArgs = {
  liquidateAmount?: Decimal;
  isFullLiquidation?: boolean;
  portfolioValue: Decimal;
  destinationId?: string;
  destinationType?: MoneyMovementSourceType;
};

export enum DirectIndexCustomizationValidationResult {
  ExceedsEditableStocksLimit = "ExceedsEditableStocksLimit",
  ExceedsEditableSectorsLimit = "ExceedsEditableSectorsLimit",
  CannotRemoveSectorsFromSPInfoTech = "CannotRemoveSectorsFromSPInfoTech",
}

export type DirectIndexCustomizationArgs = {
  directIndexType: DirectIndexType;
  removeGICSSectorIds: GicsCode[];
  addSecuritySymbols: string[];
  removeSecuritySymbols: string[];
  editableNumberOfStocks: number;
  editableNumberOfSectors: number;
};

// Create direct index sub account arg types
export enum CreateDirectIndexSubAccountValidationResult {
  AmountShouldBeGreaterThanZero = "AmountShouldBeGreaterThanZero",
  AmountShouldBeAtLeastMiniumCashOnly = "AmountShouldBeAtLeastMiniumCashOnly",
  AmountShouldBeAtLeastMiniumCashAndStocks = "AmountShouldBeAtLeastMiniumCashAndStocks",
  InsufficientBalance = "InsufficientBalance",
  OverlappingDirectIndicesExist = "OverlappingDirectIndicesExist",
  UndefinedSourceType = "UndefinedSourceType",
}

export type ValidateCreateDirectIndexSubAccountArgs = {
  sourceType?: MoneyMovementSourceType;
  directIndexType: DirectIndexType;
  minimumAmount: number;
  amountBuffer?: Decimal;
  amount: Decimal; // amount not coming from stocks
  stockValue: Decimal; // total value of stocks, can be 0
  balance?: Decimal; // balance of account the amount is coming from
  overlappingDirectIndices: DirectIndexType[];
  customization?: DirectIndexCustomizationArgs;
  // ACH specific args
  achDailyTransferLimitOverride?: number;
  currentDayAchDepositTotal?: Decimal;
};

export type CreateDirectIndexSubAccountValidationResultType =
  | CreateDirectIndexSubAccountValidationResult
  | DirectIndexCustomizationValidationResult
  | CreateCashTransferValidationResult;

export const CreateDirectIndexSubAccountValidationErrorMessage: Record<
  CreateDirectIndexSubAccountValidationResultType,
  (args: ValidateCreateDirectIndexSubAccountArgs) => string
> = {
  [CreateDirectIndexSubAccountValidationResult.AmountShouldBeGreaterThanZero]:
    () => "Deposit amount should be greater than $0.",
  [CreateDirectIndexSubAccountValidationResult.AmountShouldBeAtLeastMiniumCashOnly]:
    ({ directIndexType, minimumAmount }) =>
      `The minimum amount to invest is ${formatUsd(minimumAmount)} for the ${
        DirectIndexingTypeText[directIndexType]
      } index.`,
  [CreateDirectIndexSubAccountValidationResult.AmountShouldBeAtLeastMiniumCashAndStocks]:
    ({ directIndexType, stockValue, minimumAmount }) =>
      `The minimum additional cash to deposit is ${formatUsd(
        stockValue.minus(minimumAmount).abs(),
        0,
        2
      )} for the ${DirectIndexingTypeText[directIndexType]} index.`,
  [CreateDirectIndexSubAccountValidationResult.InsufficientBalance]: () =>
    "The account you are transferring from does not have sufficient balance.",
  [CreateDirectIndexSubAccountValidationResult.OverlappingDirectIndicesExist]:
    ({ overlappingDirectIndices }) =>
      `The positions within this index overlap significantly with one or more of your existing direct indices: ${overlappingDirectIndices
        .map((type) => DirectIndexingTypeShortText[type])
        .join(", ")}.`,
  [CreateDirectIndexSubAccountValidationResult.UndefinedSourceType]: () =>
    "A source of funds must be specified when depositing cash.",
  [DirectIndexCustomizationValidationResult.ExceedsEditableStocksLimit]: ({
    customization,
  }) =>
    `The number of stocks you added or excluded exceeds the limit of ${customization?.editableNumberOfStocks}.`,
  [DirectIndexCustomizationValidationResult.ExceedsEditableSectorsLimit]: ({
    customization,
  }) =>
    `The number of sectors you excluded exceeds the limit of ${customization?.editableNumberOfSectors}.`,
  [DirectIndexCustomizationValidationResult.CannotRemoveSectorsFromSPInfoTech]:
    () =>
      `You cannot exclude sectors from the ${
        DirectIndexingTypeShortText[DirectIndexType.SpInfoTech]
      } index.`,
  [CreateCashTransferValidationResult.InsufficientCash]: () =>
    CreateCashTransferValidationErrorMessage[
      CreateCashTransferValidationResult.InsufficientCash
    ],
  [CreateCashTransferValidationResult.AchDepositAmountShouldBeGreaterThanMin]:
    () =>
      CreateCashTransferValidationErrorMessage[
        CreateCashTransferValidationResult
          .AchDepositAmountShouldBeGreaterThanMin
      ],
  [CreateCashTransferValidationResult.WireWithdrawalAmountShouldBeGreaterThanMin]:
    () =>
      CreateCashTransferValidationErrorMessage[
        CreateCashTransferValidationResult
          .WireWithdrawalAmountShouldBeGreaterThanMin
      ],
  [CreateCashTransferValidationResult.AchDailyLimitBreached]: ({
    achDailyTransferLimitOverride,
    amount,
  }) =>
    `The total amount of ${formatUsd(
      amount
    )} exceeds the daily limit of ${formatUsd(
      achDailyTransferLimitOverride ?? ACH_DAILY_TRANSFER_LIMIT
    )} for ACH transfers. You can wire instead.`,
  [CreateCashTransferValidationResult.NotAllowed]: () =>
    CreateCashTransferValidationErrorMessage[
      CreateCashTransferValidationResult.NotAllowed
    ],
  [CreateCashTransferValidationResult.InvalidWireRoutingNumber]: () =>
    CreateCashTransferValidationErrorMessage[
      CreateCashTransferValidationResult.InvalidWireRoutingNumber
    ],
  [CreateCashTransferValidationResult.UnsupportedCashTransferMethod]: () =>
    CreateCashTransferValidationErrorMessage[
      CreateCashTransferValidationResult.UnsupportedCashTransferMethod
    ],
  [CreateCashTransferValidationResult.UnsupportedCashTransferAccountDirection]:
    () =>
      CreateCashTransferValidationErrorMessage[
        CreateCashTransferValidationResult
          .UnsupportedCashTransferAccountDirection
      ],
  [CreateCashTransferValidationResult.UnsupportedFullLiquidation]: () =>
    CreateCashTransferValidationErrorMessage[
      CreateCashTransferValidationResult.UnsupportedFullLiquidation
    ],
};

export const validateDirectIndexPortfolioLiquidate = (
  args: LiquidateDirectIndexPortfolioArgs
) => {
  const results = new Set<LiquidatePortfolioValidationResult>();

  // one of isFullLiquidation and liquidateAmount needs to be set
  if (
    (args.isFullLiquidation && args.liquidateAmount) ||
    (!args.liquidateAmount && !args.isFullLiquidation)
  ) {
    results.add(LiquidatePortfolioValidationResult.InvalidLiquidationAmount);
  }

  if (args.liquidateAmount?.lessThanOrEqualTo(0)) {
    results.add(
      LiquidatePortfolioValidationResult.AmountShouldBeGreaterThanZero
    );
  }

  if (args.liquidateAmount?.greaterThan(args.portfolioValue)) {
    results.add(LiquidatePortfolioValidationResult.InsufficientPortfolioValue);
  }

  if (
    (args.destinationId && !args.destinationType) ||
    (!args.destinationId && args.destinationType)
  ) {
    results.add(
      LiquidatePortfolioValidationResult.InvalidLiquidationDestination
    );
  }

  if (
    args.destinationType &&
    !DI_SUPPORTED_LIQUIDATION_DESTINATIONS.has(args.destinationType)
  ) {
    results.add(
      LiquidatePortfolioValidationResult.InvalidLiquidationDestination
    );
  }
  return Array.from(results);
};

export const validateDirectIndexCustomization = (
  args: DirectIndexCustomizationArgs
) => {
  const results = new Set<DirectIndexCustomizationValidationResult>();

  // check number of sectors edited
  if (args.removeGICSSectorIds.length > args.editableNumberOfSectors) {
    results.add(
      DirectIndexCustomizationValidationResult.ExceedsEditableSectorsLimit
    );
  }

  // check number of stocks edited
  if (
    args.addSecuritySymbols.length + args.removeSecuritySymbols.length >
    args.editableNumberOfStocks
  ) {
    results.add(
      DirectIndexCustomizationValidationResult.ExceedsEditableStocksLimit
    );
  }

  // cannot remove sectors from info tech
  if (
    args.directIndexType === DirectIndexType.SpInfoTech &&
    args.removeGICSSectorIds.length > 0
  ) {
    results.add(
      DirectIndexCustomizationValidationResult.CannotRemoveSectorsFromSPInfoTech
    );
  }

  return Array.from(results);
};

/**
 * Common validations for creating a direct index sub account.
 *
 * Checks for:
 * - minimum deposit amount
 * - sufficient balance to cover cash transfer
 * - sub account cash transfer validation
 * - external cash transfer validation
 * - valid customizations
 * - overlapping direct indices
 *
 * Does not check for:
 * - direct index sub account already exists
 */
export const validateCreateDirectIndexSubAccount = (
  args: ValidateCreateDirectIndexSubAccountArgs
) => {
  const results = new Set<CreateDirectIndexSubAccountValidationResultType>();

  // Negative checks
  if (args.amount.lt(0) || args.stockValue.lt(0)) {
    results.add(
      CreateDirectIndexSubAccountValidationResult.AmountShouldBeGreaterThanZero
    );
  }

  // If amount is greater than 0, then sourceType must be defined
  if (args.amount.gt(0) && !args.sourceType) {
    results.add(
      CreateDirectIndexSubAccountValidationResult.UndefinedSourceType
    );
  }

  // Balance check
  if (args.balance && args.amount.gt(args.balance)) {
    results.add(
      CreateDirectIndexSubAccountValidationResult.InsufficientBalance
    );
  }

  // Minimum deposit amount check
  if (args.stockValue.isZero() && args.amount.lt(args.minimumAmount)) {
    // cash only case
    results.add(
      CreateDirectIndexSubAccountValidationResult.AmountShouldBeAtLeastMiniumCashOnly
    );
  }
  const totalDeposit = args.amount.add(args.stockValue);
  if (
    args.stockValue.gt(0) &&
    totalDeposit.lt(args.minimumAmount) &&
    totalDeposit
      .minus(args.minimumAmount)
      .abs()
      .gt(args.amountBuffer ?? DI_ONBOARDING_PRICE_FLUCTUATION_BUFFER)
  ) {
    // cash + stocks
    results.add(
      CreateDirectIndexSubAccountValidationResult.AmountShouldBeAtLeastMiniumCashAndStocks
    );
  }

  // Validate external cash transfers
  if (args.amount.gt(0) && args.sourceType === MoneyMovementSourceType.Ach) {
    const externalCashTransferResults = validateExternalCashTransfer({
      amount: args.amount,
      method: CashTransferMethod.Ach,
      amountAvailableToWithdraw: ZERO, // not withdrawing
      direction: CashTransferDirection.Deposit, // deposit only
      achDailyTransferLimitOverride:
        args.achDailyTransferLimitOverride ?? ACH_DAILY_TRANSFER_LIMIT,
      currentDayAchDepositTotal: args.currentDayAchDepositTotal ?? ZERO,
      currentDayAchWithdrawalTotal: ZERO, // not withdrawing
      isForBorrow: false,
      isAllowed: true,
    });
    externalCashTransferResults.forEach((result) => results.add(result));
  }

  // Customization check
  if (args.customization) {
    const customizationResults = validateDirectIndexCustomization(
      args.customization
    );
    customizationResults.forEach((result) => results.add(result));
  }

  // Overlapping direct indices check
  if (args.overlappingDirectIndices.length > 0) {
    results.add(
      CreateDirectIndexSubAccountValidationResult.OverlappingDirectIndicesExist
    );
  }

  return Array.from(results);
};
