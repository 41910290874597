import { FieldPolicy } from "@apollo/client";

export const dateTime: FieldPolicy<
  Date | null | undefined,
  string | Date | null | undefined
> = {
  merge: (_, incoming) => {
    if (incoming === null || incoming === undefined) {
      // It's important for these methods to return null if passed null
      return incoming;
    } else if (incoming instanceof Date) {
      // In tests our mocks already have Date
      return incoming;
    } else {
      return new Date(incoming as string);
    }
  },
};
