import Decimal from "decimal.js";
import { isPlainObject } from "lodash";

import { DateOnly } from "../date_utils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const serializeDecimalsToStrings = (o: any): any => {
  if (!o) return o;
  if (Array.isArray(o)) {
    return o.map(serializeDecimalsToStrings);
  } else if (o instanceof Decimal) {
    return o.toString();
  } else if (isPlainObject(o)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Object.keys(o).reduce<any>((map, key) => {
      map[key] = serializeDecimalsToStrings(o[key]);
      return map;
    }, {});
  }
  return o;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deserializeDecimalsForGQL = (o: any): any => {
  if (!o) return o;
  if (Array.isArray(o)) {
    return o.map(deserializeDecimalsForGQL);
  } else if (typeof o === "object" && o["__typename"] === "Decimal") {
    return new Decimal(o.value);
  } else if (isPlainObject(o)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Object.keys(o).reduce<any>((map, key) => {
      map[key] = deserializeDecimalsForGQL(o[key]);
      return map;
    }, {});
  }
  return o;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const serializeDecimalsForGQL = (o: any): any => {
  if (!o) return o;
  if (Array.isArray(o)) {
    return o.map(serializeDecimalsForGQL);
  } else if (o instanceof Decimal) {
    return { __typename: "Decimal", value: o.toString() };
  } else if (isPlainObject(o)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Object.keys(o).reduce<any>((map, key) => {
      map[key] = serializeDecimalsForGQL(o[key]);
      return map;
    }, {});
  }
  return o;
};

// returns the graphql serialization of a number, useful for tests
export const matchDecimal = (n: number) => {
  return { __typename: "Decimal", value: n.toString() };
};

// returns a matchable partial shape of a decimal, useful for tests
export const matchDecimalShape = (n: number) => {
  const d = new Decimal(n);
  return expect.objectContaining({ d: d.d, e: d.e, s: d.s });
};

export type GQLDecimal = { __typename: "Decimal"; value: string };

// This had to be done this way because Decimal has it's own toJSON method that
// messes everything up.
export function deepCloneWithDecimalReplacer(obj: any): any {
  if (obj instanceof Decimal) {
    return { __typename: "Decimal", value: obj.toString() };
  } else if (obj instanceof DateOnly) {
    return { __typename: "DateOnly", value: obj.toString() };
  } else if (obj instanceof Date) {
    return { __typename: "Date", value: obj.toISOString() };
  } else if (typeof obj === "object" && obj !== null) {
    const result: Record<string, unknown> | unknown[] = Array.isArray(obj)
      ? []
      : {};
    for (const key in obj) {
      (result as Record<string, unknown>)[key] = deepCloneWithDecimalReplacer(
        obj[key]
      );
    }
    return result;
  }
  return obj;
}

export function deepCloneWithDecimalRestorer(obj: any): any {
  if (obj && typeof obj === "object") {
    if (obj.__typename === "Decimal") {
      return new Decimal(obj.value);
    } else if (obj.__typename === "DateOnly") {
      return new DateOnly(obj.value);
    } else if (obj.__typename === "Date") {
      return new Date(obj.value);
    } else {
      const result: Record<string, unknown> | unknown[] = Array.isArray(obj)
        ? []
        : {};
      for (const key in obj) {
        (result as Record<string, unknown>)[key] = deepCloneWithDecimalRestorer(
          obj[key]
        );
      }
      return result;
    }
  } else {
    return obj;
  }
}
