// SPX returns starting from 1960 to 2023 adjusted for dividends
// using: http://www.econ.yale.edu/~shiller/data/ie_data.xls
// for 15, 20, and 30 year periods

export enum SpxReturnDistribution {
  SPX_15_YEAR,
  SPX_20_YEAR,
  SPX_30_YEAR,
}

export const SPX_RETURNS = {
  [SpxReturnDistribution.SPX_15_YEAR]: [
    5.75, 6.01, 6.09, 5.99, 6.04, 5.76, 5.92, 5.82, 6.02, 5.91, 6.04, 5.77,
    6.56, 6.26, 6.02, 5.82, 5.74, 5.82, 5.82, 5.56, 5.6, 5.19, 5.16, 5.34, 5.02,
    4.89, 4.87, 5.08, 5.61, 6.47, 6.28, 6.08, 6.12, 5.84, 5.91, 5.58, 6.03,
    5.92, 5.93, 5.6, 5.44, 5.43, 5.52, 5.31, 5.12, 5.11, 4.71, 4.68, 5.28, 5.18,
    5.04, 4.93, 4.85, 4.87, 4.61, 4.7, 4.56, 4.37, 4.34, 4.74, 6.12, 6.05, 6.03,
    5.92, 5.81, 6.14, 6.13, 5.99, 5.74, 5.5, 5.81, 5.74, 5.07, 5.11, 5.39, 5.16,
    5.52, 5.57, 5.57, 5.99, 6.23, 5.91, 6.12, 6.15, 6.63, 6.38, 6.2, 6.06, 5.92,
    6, 5.86, 5.73, 5.62, 5.83, 6.13, 6.01, 7.54, 7.85, 7.97, 7.44, 7.27, 7.06,
    7.06, 7.21, 6.96, 6.65, 6.57, 6.47, 6.99, 7.01, 7.15, 7, 6.75, 7.12, 7.43,
    7.46, 7.41, 7.26, 7.34, 7.66, 8.8, 9.04, 8.9, 9.11, 9.98, 10, 9.97, 9.74,
    9.3, 9.12, 9.56, 9.44, 10.31, 10.01, 9.81, 9.55, 9.64, 9.76, 9.79, 9.91,
    9.73, 10.21, 10.46, 10.08, 10.78, 10.63, 10.45, 10.36, 10.42, 10.39, 10.43,
    10.16, 10.25, 9.85, 8.89, 8.63, 9.75, 10, 10.1, 10.22, 10.42, 10.57, 10.49,
    10.61, 10.47, 10.69, 10.54, 11.23, 12.97, 13.17, 12.84, 13.21, 13.42, 13.39,
    14.31, 14.61, 15.43, 14.98, 14.83, 15.52, 14.1, 13.33, 12.97, 12.87, 12.38,
    12.17, 12.15, 12.7, 12.77, 12.26, 12.48, 12.9, 13.61, 13.31, 13.25, 13.18,
    13.21, 13.15, 12.95, 13, 12.82, 13.1, 13.09, 12.89, 13.53, 13.72, 13.73,
    13.82, 13.82, 13.76, 13.67, 13.83, 13.93, 14.03, 14.24, 14.49, 15.38, 15.47,
    15.45, 15.1, 14.69, 14.65, 14.66, 14.12, 14.1, 14.75, 14.75, 14.68, 14.48,
    14.56, 14.39, 14.22, 14.37, 14.19, 14.09, 13.73, 13.62, 13.91, 13.88, 13.48,
    15.25, 14.93, 15.64, 15.74, 15.37, 14.86, 14.5, 14.24, 14.04, 13.46, 13.63,
    13.98, 15.11, 15.35, 15.04, 14.95, 15.08, 15.02, 15.18, 15.13, 15.8, 15.45,
    15.83, 15.84, 18.24, 18.4, 18.63, 18.22, 18.18, 18.62, 18.6, 18.55, 17.66,
    16.66, 16.57, 16.67, 17, 16.85, 16.56, 16.24, 15.91, 15.79, 15.74, 15.93,
    15.68, 15.73, 16.54, 16.86, 17.5, 17.92, 17.89, 17.86, 17.89, 18.04, 18.12,
    17.43, 17.33, 17.27, 17.81, 18.09, 17.58, 17.14, 17.18, 17.11, 16.9, 16.71,
    16.55, 16.7, 16.85, 16.26, 16.2, 15.54, 13.94, 13.52, 13.07, 12.88, 12.84,
    12.61, 12.76, 12.59, 12.78, 12.54, 12.9, 12.89, 10.32, 9.86, 9.55, 9.62,
    9.61, 9.29, 9.07, 8.62, 8.85, 10.74, 11.21, 11.23, 12.09, 11.85, 11.62,
    11.69, 11.86, 11.43, 11.45, 11.59, 11.45, 11.33, 11.42, 11.48, 11.55, 11.32,
    11.33, 11.07, 10.78, 10.54, 10.34, 10, 9.97, 10.1, 10.44, 10.44, 10.65,
    10.84, 10.65, 10.64, 10.36, 10.14, 10.13, 10.73, 11.07, 11.03, 11.32, 11.14,
    11.86, 11.05, 10.83, 10.67, 10.69, 10.67, 10.61, 10.42, 10.45, 10.44, 10.59,
    10.68, 10.86, 10.91, 10.99, 10.97, 10.82, 10.93, 10.79, 10.73, 10.7, 10.6,
    10.23, 10.09, 7.12, 7.01, 6.86, 6.96, 6.91, 6.85, 6.85, 6.73, 6.64, 6.55,
    5.91, 5.83, 7.23, 7.24, 7.35, 7.59, 7.52, 7.45, 7.49, 7.27, 7.22, 7.28,
    7.43, 7.66, 7.97, 7.7, 7.53, 7.3, 7.07, 6.85, 6.61, 6.57, 6.32, 6.09, 6.26,
    6.29, 6.17, 5.77, 5.78, 5.77, 5.61, 5.52, 5.78, 5.56, 5.43, 4.8, 4.92, 4.95,
    5.84, 5.54, 5.59, 5.84, 5.22, 4.86, 4.47, 4.45, 4.37, 4.34, 4.13, 4.1, 5.51,
    5.08, 4.72, 4.49, 4.5, 4.5, 4.19, 4.7, 5.05, 4.24, 4.5, 4.32, 4.56, 4.56,
    4.36, 4.07, 4.08, 4.13, 3.82, 4.09, 4.13, 3.75, 4.13, 3.98, 3.98, 4.16,
    3.89, 3.8, 4, 3.78, 3.73, 3.66, 3.74, 4.17, 4.37, 4.52, 4.89, 5.04, 5.71,
    5.68, 5.21, 5.38, 5.58, 5.72, 6.57, 6, 6.08, 6.25, 7.29, 7.53, 7.19, 7.45,
    7.65, 8.1, 8.92, 8.84, 9.2, 8.83, 8.95, 9.23, 9.66, 10.15, 10.06, 9.68,
    9.31, 8.9, 8.86, 8.88, 8.65, 8.43, 8.27, 7.64, 8.46, 8.38, 8.5, 8.43, 8.62,
    8.42, 8.58, 8.69, 8.49, 8.15, 8.46, 8.44, 9.17, 9.05, 9.07, 9.25, 9.15, 9,
    8.87, 8.85, 8.83, 8.75, 9.03, 9.17, 10.52, 10.52, 10.41, 10.36, 10.42,
    10.62, 10.57, 10.41, 10.22, 9.82, 10.16, 10.02, 8.4, 8.29, 8.47, 8.18, 7.94,
    7.92, 7.88, 8.19, 7.97, 8.12, 8.49, 8.4, 9, 9.11, 9.31, 9.01, 8.83, 9.15,
    9.61, 9.46, 9.82, 12.17, 12.17, 12.2,
  ],
  [SpxReturnDistribution.SPX_20_YEAR]: [
    7.31, 7.51, 7.57, 7.49, 7.53, 7.32, 7.44, 7.37, 7.52, 7.43, 7.67, 7.46,
    6.77, 6.55, 6.37, 6.22, 6.16, 6.22, 6.22, 6.03, 6.06, 5.74, 5.9, 5.9, 6.66,
    6.57, 6.55, 6.71, 7.11, 7.77, 7.63, 7.47, 7.5, 7.29, 7.52, 7.35, 8.29, 8.21,
    8.22, 7.96, 7.84, 7.83, 7.9, 7.74, 7.59, 7.58, 7.52, 7.38, 7.39, 7.31, 7.2,
    7.12, 7.05, 7.07, 6.87, 6.94, 6.84, 6.69, 6.75, 6.79, 7.46, 7.41, 7.39,
    7.31, 7.22, 7.47, 7.47, 7.36, 7.17, 6.99, 7.32, 7.61, 8.36, 8.39, 8.6, 8.43,
    8.71, 8.74, 8.74, 9.07, 9.25, 9.01, 9.19, 9.25, 9.79, 9.59, 9.45, 9.35,
    9.24, 9.3, 9.19, 9.09, 9.01, 9.17, 8.45, 8.21, 9.1, 9.34, 9.43, 9.03, 8.9,
    8.74, 8.74, 8.85, 8.67, 8.43, 8.32, 8.37, 9.96, 9.98, 10.09, 9.96, 9.78,
    10.06, 10.3, 10.32, 10.29, 10.16, 10.06, 10.49, 9.96, 10.14, 10.03, 10.19,
    10.85, 10.87, 10.84, 10.67, 10.34, 10.2, 10.35, 10.22, 11.02, 10.8, 10.65,
    10.45, 10.52, 10.61, 10.64, 10.72, 10.59, 10.95, 10.95, 10.61, 10.82, 10.71,
    10.57, 10.5, 10.55, 10.52, 10.55, 10.35, 10.42, 10.12, 10.27, 10.32, 10.71,
    10.9, 10.98, 11.08, 11.22, 11.34, 11.27, 11.37, 11.26, 11.43, 11.43, 11.87,
    11.86, 12, 11.75, 12.03, 12.19, 12.17, 12.85, 13.07, 13.67, 13.34, 13.32,
    13.62, 14.66, 14.07, 13.8, 13.72, 13.36, 13.2, 13.18, 13.59, 13.65, 13.27,
    13.4, 13.65, 13.97, 13.74, 13.7, 13.64, 13.67, 13.62, 13.47, 13.51, 13.38,
    13.58, 13.86, 13.72, 15.23, 15.37, 15.38, 15.45, 15.45, 15.41, 15.34, 15.46,
    15.53, 15.62, 15.55, 15.71, 16.38, 16.44, 16.43, 16.16, 15.85, 15.82, 15.83,
    15.42, 15.4, 15.9, 16.5, 16.63, 16.96, 17.03, 16.89, 16.76, 16.88, 16.74,
    16.66, 16.38, 16.3, 16.53, 16.93, 16.84, 16.54, 16.29, 16.83, 16.91, 16.63,
    16.24, 15.97, 15.77, 15.62, 15.18, 15.13, 15.01, 13.85, 14.03, 13.81, 13.74,
    13.84, 13.79, 13.91, 13.87, 14.37, 14.11, 14.39, 14.41, 13.09, 13.21, 13.37,
    13.08, 13.05, 13.36, 13.35, 13.32, 12.68, 11.96, 12.32, 12.19, 12.85, 12.74,
    12.53, 12.3, 12.06, 11.97, 11.93, 12.07, 11.89, 11.93, 12, 12.18, 12.34,
    12.64, 12.62, 12.59, 12.61, 12.72, 12.78, 12.29, 12.21, 12.17, 12.43, 12.63,
    12.4, 12.09, 12.12, 12.07, 11.92, 11.79, 11.67, 11.77, 11.88, 11.46, 11.68,
    11.51, 11.97, 11.66, 11.33, 11.18, 11.16, 10.99, 11.1, 10.97, 11.11, 10.93,
    11.04, 11.07, 11.24, 10.9, 10.66, 10.71, 10.7, 10.46, 10.3, 9.95, 10.13,
    11.56, 11.28, 11.43, 8.95, 8.78, 8.61, 8.66, 8.78, 8.47, 8.49, 8.59, 8.49,
    8.4, 7.91, 7.77, 8.75, 8.57, 8.59, 8.4, 8.18, 8.01, 7.86, 7.62, 7.59, 7.68,
    7.79, 7.77, 8.25, 8.39, 8.25, 8.24, 8.04, 7.88, 7.87, 8.31, 8.56, 8.53,
    8.66, 8.62, 8.58, 7.99, 7.83, 7.71, 7.72, 7.71, 7.67, 7.53, 7.55, 7.54,
    7.64, 7.67, 8.15, 8.18, 8.24, 8.23, 8.12, 8.19, 8.1, 8.05, 8.03, 7.95, 7.8,
    7.74, 8.84, 8.75, 8.63, 8.71, 8.67, 8.63, 8.63, 8.53, 8.46, 8.4, 8.6, 8.64,
    9, 9, 9.08, 9.27, 9.22, 9.16, 9.19, 9.03, 8.98, 9.03, 9.33, 9.43, 9.29,
    9.09, 8.95, 8.78, 8.61, 8.44, 8.25, 8.23, 8.03, 7.86, 8.02, 7.78, 8.08,
    7.77, 7.78, 7.77, 7.65, 7.58, 7.77, 7.61, 7.51, 7.03, 7.09, 7.24, 7.83, 7.6,
    7.64, 7.83, 7.35, 7.08, 6.78, 6.76, 6.7, 6.68, 6.76, 6.77, 7.07, 6.74, 6.46,
    6.28, 6.3, 6.29, 6.06, 6.45, 6.72, 6.1, 5.98, 5.47, 6.06, 6.07, 5.91, 5.7,
    5.7, 5.74, 5.5, 5.71, 5.74, 5.45, 5.68, 5.66, 6.12, 6.25, 6.05, 5.98, 6.13,
    5.97, 5.92, 5.87, 5.93, 6.26, 6.46, 6.87, 7.9, 8.02, 8.54, 8.51, 8.15, 8.29,
    8.43, 8.55, 9.2, 8.76, 9.01, 8.95, 7.79, 7.98, 7.72, 7.91, 8.07, 8.4, 9.02,
    8.96, 9.23, 8.95, 9.23, 9.29, 9.38, 9.74, 9.67, 9.39, 9.11, 8.81, 8.78,
    8.79, 8.62, 8.45, 8.45, 8.29,
  ],
  [SpxReturnDistribution.SPX_30_YEAR]: [
    9.03, 9.16, 9.21, 9.15, 9.18, 9.04, 9.12, 9.07, 9.17, 9.11, 9.21, 9.28,
    9.76, 9.61, 9.49, 9.38, 9.34, 9.38, 9.38, 9.25, 9.27, 9.06, 9.05, 9.05,
    9.47, 9.4, 9.39, 9.5, 9.77, 10.22, 10.12, 10.02, 10.04, 9.9, 9.99, 9.95,
    10.1, 10.04, 10.05, 9.87, 9.79, 9.78, 9.83, 9.72, 9.62, 9.62, 9.62, 9.56,
    9.5, 9.44, 9.37, 9.31, 9.27, 9.29, 9.15, 9.19, 9.12, 9.02, 9.01, 9.02, 9.89,
    9.85, 9.84, 9.79, 9.73, 9.9, 9.9, 9.82, 9.69, 9.57, 9.65, 9.78, 10.25,
    10.27, 10.41, 10.29, 10.48, 10.51, 10.51, 10.73, 10.85, 10.69, 10.87, 10.89,
    11.7, 11.56, 11.47, 11.4, 11.32, 11.36, 11.29, 11.22, 11.17, 11.27, 11.23,
    11.21, 11.51, 11.68, 11.74, 11.47, 11.37, 11.27, 11.27, 11.34, 11.22, 11.06,
    11.44, 11.54, 12.06, 12.07, 12.14, 12.06, 11.93, 12.13, 12.29, 12.3, 12.28,
    12.2, 12.45, 12.75, 12.7, 12.83, 12.75, 12.86, 13.31, 13.32, 13.31, 13.19,
    12.96, 12.86, 12.83, 12.45, 11.54, 11.39, 11.29, 11.16, 11.2, 11.26, 11.28,
    11.34, 11.25, 11.49, 11.68, 11.47, 10.27, 10.2, 10.11, 10.06, 10.09, 10.08,
    10.1, 9.96, 10.01, 9.81, 10.04, 9.92, 10.46, 10.59, 10.64, 10.7, 10.8,
    10.87, 10.83, 10.89, 10.82, 10.94, 10.98, 11.35, 11.46, 11.55, 11.39, 11.57,
    11.68, 11.66, 12.11, 12.26, 12.66, 12.44, 12.61, 12.95, 12.66, 12.28, 12.1,
    12.05, 11.81, 11.71, 11.69, 11.97, 12, 11.75, 11.89, 12.02, 12.02, 11.86,
    11.84, 11.8, 11.82, 11.79, 11.69, 11.71, 11.63, 11.76, 11.83, 11.78, 12.15,
    12.24, 12.25, 12.3, 12.3, 12.27, 12.22, 12.3, 12.35, 12.4, 12.22, 12.27,
    10.88, 10.92, 10.91, 10.74, 10.55, 10.52, 10.53, 10.27, 10.26, 10.58, 10.24,
    10.16, 10.81, 10.85, 10.77, 10.68, 10.75, 10.67, 10.62, 10.44, 10.39, 10.53,
    10.61, 10.53, 10.66, 10.51, 10.85, 10.89, 10.72, 10.47, 10.3, 10.18, 10.08,
    9.8, 9.89, 10.07, 10.01, 10.13, 9.98, 9.94, 10, 9.97, 10.05, 10.02, 10.35,
    10.18, 10.24, 10.26, 11.03, 11.1, 11.21, 11.02, 11, 11.2, 11.2, 11.17,
    10.75, 10.28, 10.18, 10.21, 10.82, 10.75, 10.61, 10.46, 10.3, 10.24, 10.22,
    10.31, 10.19, 10.22, 10.35, 10.42, 10.66, 10.86, 10.85, 10.83, 10.84, 10.92,
    10.95, 10.63, 10.58, 10.55, 10.76, 10.81, 10.64, 10.43, 10.45, 10.42, 10.32,
    10.23, 10.15, 10.23, 10.3, 10.02, 10.12, 9.9, 10.08, 9.88, 9.66, 9.56, 9.55,
    9.44, 9.5, 9.42, 9.52, 9.4, 9.44, 9.52, 9.81, 9.58, 9.43, 9.46, 9.45, 9.29,
    9.18, 8.96, 9.07, 10.02, 10.07, 10.23, 10.28, 10.17, 10.05, 10.09, 10.17,
    9.96, 9.97, 10.04, 9.97, 9.91, 9.83, 9.54, 10.01, 9.89, 9.9, 9.77, 9.63,
    9.51, 9.41, 9.24, 9.23, 9.29, 9.44, 9.44, 9.83, 9.93, 9.83, 9.83, 9.69,
    9.58, 9.58, 9.88, 10.04, 10.02, 10.17, 10.15, 10.91, 10.51, 10.4, 10.32,
    10.33, 10.32, 10.29, 10.19, 10.21, 10.2, 10.37, 10.35, 9.3, 9.32, 9.36,
    9.36, 9.28, 9.33, 9.27, 9.23, 9.22, 9.17, 9.36, 9.24, 9.29, 9.22, 9.15, 9.2,
    9.18, 9.15, 9.15, 9.08, 9.04, 8.99, 8.99, 8.96,
  ],
};
