import { PlaidVerificationStatus, pluralWords } from "@frec-js/common";

import {
  MoneyMovementSourceType,
  PlaidAccountStatus,
} from "../../generated/graphql";
import { TransferAccount } from "../../types/transferAccount";

enum TransferAccountValidationErrors {
  SystemPending = "Account in review.",
  VerificationPending = "Verify this bank account.",
}
export const transferAccountValidation = (a?: TransferAccount) => {
  if (!a || !a.status || a.type !== MoneyMovementSourceType.Ach) {
    return undefined;
  }
  if (a.status === PlaidAccountStatus.SystemPending) {
    return TransferAccountValidationErrors.SystemPending;
  }
  if (
    a.plaidAccount &&
    [
      PlaidVerificationStatus.PendingManualVerification,
      PlaidVerificationStatus.PendingAutomaticVerification,
      PlaidVerificationStatus.VerificationFailed,
    ].includes(a.plaidAccount.verificationStatus as PlaidVerificationStatus)
  ) {
    return TransferAccountValidationErrors.VerificationPending;
  }
};

// How many business days does this type need to mobilize funds?
const MONEY_MOVEMENT_SOURCE_ESTIMATED_BUSINESS_DAYS: Record<
  MoneyMovementSourceType,
  { lower: number; upper: number }
> = {
  ACH: { lower: 1, upper: 2 }, // "1-2 business days"
  WIRE: { lower: 0, upper: 1 }, // "Same or next business day"
  CHECK: { lower: 0, upper: 0 }, // (not used)
  FREC_CASH: { lower: 0, upper: 0 }, // "Instant",
  TREASURY: { lower: 1, upper: 1 }, // "1 business days",
  DIRECT_INDEX: { lower: 0, upper: 1 }, // "Same or next business day",
  LINE_OF_CREDIT: { lower: 0, upper: 0 }, // "Instant",
};

// How many business days does this type need to show funds?
const MONEY_MOVEMENT_DESTINATION_ESTIMATED_BUSINESS_DAYS: Record<
  MoneyMovementSourceType,
  { lower: number; upper: number }
> = {
  ACH: { lower: 1, upper: 2 }, // "1-2 business days"
  WIRE: { lower: 0, upper: 1 }, // "Same or next business day"
  CHECK: { lower: 0, upper: 0 }, // (not used)
  FREC_CASH: { lower: 0, upper: 0 }, // "Instant",
  TREASURY: { lower: 1, upper: 1 }, // "1 business days",
  DIRECT_INDEX: { lower: 0, upper: 0 }, // "Instant", (since we have no liquidation)
  LINE_OF_CREDIT: { lower: 0, upper: 0 }, // "Instant",
};

export const estimatedTimeFromSourceAndDestination = (
  source: MoneyMovementSourceType,
  destination: MoneyMovementSourceType
) => {
  const src = MONEY_MOVEMENT_SOURCE_ESTIMATED_BUSINESS_DAYS[source];
  const dest = MONEY_MOVEMENT_DESTINATION_ESTIMATED_BUSINESS_DAYS[destination];
  const lower = src.lower + dest.lower;
  const upper = src.upper + dest.upper;
  if (lower === 0 || lower === upper) {
    if (upper === 0) {
      return "Instant";
    } else {
      return pluralWords(upper, "business day");
    }
  } else {
    return `${lower}-${upper} business days`;
  }
};
