import { ApolloLink } from "@apollo/client";
import {
  deserializeDecimalsForGQL,
  serializeDecimalsForGQL,
} from "@frec-js/common";

export const decimalParserLink = new ApolloLink((operation, forward) => {
  if (
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    operation.variables
  ) {
    operation.variables = serializeDecimalsForGQL(operation.variables);
  }
  return forward(operation).map((data) => {
    try {
      return {
        ...data,
        data: deserializeDecimalsForGQL(data.data),
      };
    } catch (e) {
      console.error("Something failed while parsing decimals");
      return data;
    }
  });
});
