import { Percentage } from "../utils";

export const MAX_LOAN_AMOUNT = 5_000_000; // bumped to 5m, see: WEB-626
export const MIN_HOLDINGS_FOR_LOAN_SOFT = 3000; // soft rule, see: BROKSERV-510
export const MIN_EQUITY_FOR_LOAN_SOFT = 2500; // soft rule, see: WEB-527
export const MIN_EQUITY_FOR_LOAN = 2000;
export const MIN_LOAN_AMOUNT = 0.01; // One cent. This was originally $250, but if we want it to be generally useful, eg for payments, we should have no minimum.
export const MIN_LOAN_ADDITION = 1; // One dollar. This was originally $0.01, but we don't allow decimal amounts for loans.
export const DEFAULT_BORROW_AMOUNT = 1_000;
export const MAX_BORROW_PERCENT = 70;
export const FREC_RESERVE_RATE = 4.5;
export const PDT_MIN_EQUITY_FOR_RESTRICTION = 25_000;
export const PDT_MAX_ALLOWED_DAY_TRADES = 3;
export const PDT_PERCENTAGE_OF_TRADES_FOR_RESTRICTION = new Percentage({
  percent: 6,
});

/**
 * When we have to translate between a desired borrow amount and an amount of
 * stock to transfer over we have to make assumptions about the LTV of the
 * stocks that are yet to be specified.
 *
 * At the time of writing this value is below 0.50 because transferred stocks often
 * have a 50% SMA requirement.
 */
export const ASSUMED_LTV_OF_ACAT = 0.46;
