import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import React from "react";

import Error from "../pages/error";

type State = {
  error?: unknown;
};

type Props = any;

export class RootErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: unknown) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    datadogLogs.logger.error("Fatal error encountered", errorInfo, error);
    datadogRum.addError(error, { errorInfo });
  }

  render() {
    if (this.state.error) {
      return <Error />;
    } else {
      return this.props.children;
    }
  }
}

export default RootErrorBoundary;
