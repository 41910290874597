import { windowIfAvailable } from "./windowIfAvailable";

interface FacebookPixelEventOptions {
  [key: string]: string | number | boolean;
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fbq?: (
      eventType: string,
      eventName: string,
      options?: FacebookPixelEventOptions
    ) => void;
  }
}

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

export const pageview = () => {
  windowIfAvailable?.fbq?.("track", "PageView");
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const event = (name: string, options: any = {}) => {
  windowIfAvailable?.fbq?.("track", name, options);
};
