import { useMemo } from "react";

import { useInterestRateQuery } from "../generated/graphql";

export const useFrecRate = () => {
  const { data, loading } = useInterestRateQuery();
  return useMemo(() => {
    const result =
      loading || !data?.marginInterest ? null : data.marginInterest.value;
    return { data: result, loading };
  }, [data?.marginInterest, loading]);
};
