import * as crypto from "crypto";

const snippet = (code: string, baseUrl: string) =>
  `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='${baseUrl}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${code}');`;

const bodyTag = (code: string, baseUrl: string) =>
  `<iframe src="${baseUrl}/ns.html?id=${code}" height="0" width="0" style="display: none; visibility: hidden;" />`;
/**
 * Create a base64 digest formatted SHA of the input and format it
 * as a CSP directive
 * Jan 2024: This is unused at the moment, but I'm leaving it here in case we need it in the future.
 */
const sha256CSPDirective = (data: string) => {
  const digest = crypto.createHash("sha256").update(data).digest("base64");
  return `sha256-${digest}`;
};

export enum GoogleTagManagerID {
  Prod = "GTM-KHCJCLZ3",
  Staging = "GTM-NHLV4LVM",
  Test = "GTM-W5Z5B763",
  Local = "GTM-NZGH5KTP",
}

export enum GoogleTagManagerBaseUrl {
  Prod = "https://frec.com/gtm",
  Staging = "https://staging-9srt4.frec.com/gtm",
  Test = "https://staging-test.frec.com/gtm",
  Local = "http://localhost:3001/gtm",
}

export type GTMInfo = {
  /**
   * JS snippet to be inserted into a <script> tag in the head
   */
  snippet: string;
  /**
   * function returning CSP directive for the script in the head tag
   */
  cspSHA: () => string;
  /**
   * HTML to be set in the body tag
   */
  bodyTag: string;
};

const getSnippets = (
  id: GoogleTagManagerID,
  baseUrl: GoogleTagManagerBaseUrl
): GTMInfo => {
  return {
    snippet: snippet(id, baseUrl),
    cspSHA: () => sha256CSPDirective(snippet(id, baseUrl)),
    bodyTag: bodyTag(id, baseUrl),
  };
};

export const GTMSnippets: Record<GoogleTagManagerID, GTMInfo> = {
  [GoogleTagManagerID.Prod]: getSnippets(
    GoogleTagManagerID.Prod,
    GoogleTagManagerBaseUrl.Prod
  ),
  [GoogleTagManagerID.Staging]: getSnippets(
    GoogleTagManagerID.Staging,
    GoogleTagManagerBaseUrl.Staging
  ),
  [GoogleTagManagerID.Test]: getSnippets(
    GoogleTagManagerID.Test,
    GoogleTagManagerBaseUrl.Test
  ),
  [GoogleTagManagerID.Local]: getSnippets(
    GoogleTagManagerID.Local,
    GoogleTagManagerBaseUrl.Local
  ),
};
