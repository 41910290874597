import { SecuritySubType } from "../generated/graphql";

/**
 * Frec limits for PDT, generally stricter than APEX limits, so go/daytrade.
 */
export const PATTERN_DAY_TRADE_EQUITY_REQ = 25000;
export const PATTERN_DAY_TRADE_LIMIT = 4;

// Minimum notional value for trades
export const MINIMUM_TRADE_VALUE = 5;

// The buy amounts are limited by fund min investments, but sells can't be less than $5.
export const MINIMUM_MMF_SELL_AMOUNT = 5;

export const supportedSecuritySubTypes: SecuritySubType[] = [
  SecuritySubType.CommonStock,
  SecuritySubType.Etf,
  SecuritySubType.MiscStock,
  SecuritySubType.MoneyMarketFund,
  // SecuritySubType.MutualFund,
  SecuritySubType.PreferredStock,
  // SecuritySubType.Warrant,
];
