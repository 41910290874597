export const POBOX_REGEXP = new RegExp(
  "\\b[p]*(ost)*\\.*\\s*[o|0]*(ffice)*\\.*\\s*b[o|0]x\\b",
  "i"
);

export const US_STATES = [
  {
    name: "Alabama",
    isoCode: "AL",
  },
  {
    name: "Alaska",
    isoCode: "AK",
  },
  {
    name: "American Samoa",
    isoCode: "AS",
  },
  {
    name: "Arizona",
    isoCode: "AZ",
  },
  {
    name: "Arkansas",
    isoCode: "AR",
  },
  {
    name: "Baker Island",
    isoCode: "UM-81",
  },
  {
    name: "California",
    isoCode: "CA",
  },
  {
    name: "Colorado",
    isoCode: "CO",
  },
  {
    name: "Connecticut",
    isoCode: "CT",
  },
  {
    name: "Delaware",
    isoCode: "DE",
  },
  {
    name: "District of Columbia",
    isoCode: "DC",
  },
  {
    name: "Florida",
    isoCode: "FL",
  },
  {
    name: "Georgia",
    isoCode: "GA",
  },
  {
    name: "Guam",
    isoCode: "GU",
  },
  {
    name: "Hawaii",
    isoCode: "HI",
  },
  {
    name: "Howland Island",
    isoCode: "UM-84",
  },
  {
    name: "Idaho",
    isoCode: "ID",
  },
  {
    name: "Illinois",
    isoCode: "IL",
  },
  {
    name: "Indiana",
    isoCode: "IN",
  },
  {
    name: "Iowa",
    isoCode: "IA",
  },
  {
    name: "Jarvis Island",
    isoCode: "UM-86",
  },
  {
    name: "Johnston Atoll",
    isoCode: "UM-67",
  },
  {
    name: "Kansas",
    isoCode: "KS",
  },
  {
    name: "Kentucky",
    isoCode: "KY",
  },
  {
    name: "Kingman Reef",
    isoCode: "UM-89",
  },
  {
    name: "Louisiana",
    isoCode: "LA",
  },
  {
    name: "Maine",
    isoCode: "ME",
  },
  {
    name: "Maryland",
    isoCode: "MD",
  },
  {
    name: "Massachusetts",
    isoCode: "MA",
  },
  {
    name: "Michigan",
    isoCode: "MI",
  },
  {
    name: "Midway Atoll",
    isoCode: "UM-71",
  },
  {
    name: "Minnesota",
    isoCode: "MN",
  },
  {
    name: "Mississippi",
    isoCode: "MS",
  },
  {
    name: "Missouri",
    isoCode: "MO",
  },
  {
    name: "Montana",
    isoCode: "MT",
  },
  {
    name: "Navassa Island",
    isoCode: "UM-76",
  },
  {
    name: "Nebraska",
    isoCode: "NE",
  },
  {
    name: "Nevada",
    isoCode: "NV",
  },
  {
    name: "New Hampshire",
    isoCode: "NH",
  },
  {
    name: "New Jersey",
    isoCode: "NJ",
  },
  {
    name: "New Mexico",
    isoCode: "NM",
  },
  {
    name: "New York",
    isoCode: "NY",
  },
  {
    name: "North Carolina",
    isoCode: "NC",
  },
  {
    name: "North Dakota",
    isoCode: "ND",
  },
  {
    name: "Northern Mariana Islands",
    isoCode: "MP",
  },
  {
    name: "Ohio",
    isoCode: "OH",
  },
  {
    name: "Oklahoma",
    isoCode: "OK",
  },
  {
    name: "Oregon",
    isoCode: "OR",
  },
  {
    name: "Palmyra Atoll",
    isoCode: "UM-95",
  },
  {
    name: "Pennsylvania",
    isoCode: "PA",
  },
  {
    name: "Puerto Rico",
    isoCode: "PR",
  },
  {
    name: "Rhode Island",
    isoCode: "RI",
  },
  {
    name: "South Carolina",
    isoCode: "SC",
  },
  {
    name: "South Dakota",
    isoCode: "SD",
  },
  {
    name: "Tennessee",
    isoCode: "TN",
  },
  {
    name: "Texas",
    isoCode: "TX",
  },
  {
    name: "United States Minor Outlying Islands",
    isoCode: "UM",
  },
  {
    name: "United States Virgin Islands",
    isoCode: "VI",
  },
  {
    name: "Utah",
    isoCode: "UT",
  },
  {
    name: "Vermont",
    isoCode: "VT",
  },
  {
    name: "Virginia",
    isoCode: "VA",
  },
  {
    name: "Wake Island",
    isoCode: "UM-79",
  },
  {
    name: "Washington",
    isoCode: "WA",
  },
  {
    name: "West Virginia",
    isoCode: "WV",
  },
  {
    name: "Wisconsin",
    isoCode: "WI",
  },
  {
    name: "Wyoming",
    isoCode: "WY",
  },
];

export const IGNORED_US_STATES = [
  "GU",
  "AS",
  "MP",
  "UM",
  "UM-67",
  "UM-71",
  "UM-76",
  "UM-79",
  "UM-81",
  "UM-84",
  "UM-86",
  "UM-89",
  "UM-95",
];

export const ALLOWED_US_STATES = US_STATES.map(({ name, isoCode }) => ({
  label: name,
  value: isoCode,
})).filter(({ value }) => !IGNORED_US_STATES.includes(value));
