import Decimal from "decimal.js";

import {
  Portfolio,
  SecuritySubType,
  SecurityTradeStatus,
  SecurityType,
  SubAccountType,
} from "../generated/graphql";
import { makeDecimal, maybeDecimal } from "../utils/number";
import { getFrecCash, getLoanBalance } from "./cashUtils";
import { getSecurityHoldingsValue, Holding, RichHolding } from "./marginUtils";

/**
/**
 * Extract more useable objects from the graphql-defined `Portfolio` object, which is a fine
 * object structure for serializing this information, but cumbersome to work with.
 *
 * @param portfolio
 * @param subAccountType if provided, only extract for this subAccountType. Otherwise,
 * extract for all subAccounts.
 */
export const extractUsables = (
  portfolio: Pick<Portfolio, "cash" | "securities">,
  subAccountType?: SubAccountType
): {
  holdings: RichHolding[];
  cash: Decimal;
  loanAmount: Decimal;
} => {
  let { cash, securities } = portfolio;

  if (subAccountType) {
    cash = cash?.filter((c) => c.subAccountType === subAccountType);
    securities = securities?.filter((s) => s.subAccountType === subAccountType);
  }

  const cashValue = getFrecCash(cash ?? []);
  const loanAmount = getLoanBalance(cash ?? []);
  const md = (x: Decimal | number | undefined | null) =>
    uLambda(x, makeDecimal);

  const richHoldings: RichHolding[] =
    securities?.map((sh) => ({
      securityId: sh.security?.id,
      avgCostBasis: maybeDecimal(sh.avgCostBasis),
      symbol: sh.security?.symbol || "",
      quantity: new Decimal(sh.quantity),
      price:
        sh.security?.subType === SecuritySubType.MoneyMarketFund
          ? new Decimal(sh.security.mmfMetadata?.nav || 0)
          : new Decimal(sh.security?.stockQuote?.price || 0),
      name: uLambda(
        sh.security?.stockQuote?.name ?? sh.security?.name,
        (x) => x
      ),
      change: md(sh.security?.stockQuote?.change),
      changesPercentage: md(sh.security?.stockQuote?.changesPercentage),
      open: md(sh.security?.stockQuote?.open),
      previousClose: md(sh.security?.stockQuote?.previousClose),
      volume: md(sh.security?.stockQuote?.volume),
      marginRequirement: new Decimal(
        sh.security?.stockQuote?.marginRequirement?.maintenanceRequirement ||
          1.0
      ),
      buyStatus: sh.security?.buyStatus ?? SecurityTradeStatus.None,
      sellStatus: sh.security?.sellStatus ?? SecurityTradeStatus.None,
      type: sh.security?.type ?? SecurityType.Equity,
      subType: sh.security?.subType ?? SecuritySubType.CommonStock,
      mmfMetadata: sh.security?.mmfMetadata
        ? {
            ...sh.security?.mmfMetadata,
            inceptionDate: new Date(
              sh.security.mmfMetadata.inceptionDate.toString()
            ),
            lastUpdated: !!sh.security.mmfMetadata.lastUpdated
              ? new Date(sh.security.mmfMetadata.lastUpdated.toString())
              : undefined,
          }
        : undefined,
      dividendYtd: sh.dividendYtd ?? undefined,
      subAccountId: sh.subAccountId,
      subAccountType: sh.subAccountType,
    })) ?? [];

  return { holdings: richHoldings, cash: cashValue, loanAmount };
};

export type DetailedUsable = {
  allHoldings: RichHolding[]; // All holdings in the portfolio.
  directIndexHoldings: RichHolding[]; // The holdings in the direct index subAccount.
  selfManagedHoldings: RichHolding[]; // The holdings in the primary subAccount.
  treasuryHoldings: RichHolding[]; // The holdings in the treasury subAccount.
  allCash: Decimal;
  frecCash: Decimal; // The cash sitting in the primary subAccount.
  directIndexCash: Decimal; // The cash sitting in the direct index subAccount waiting to be invested.
  treasuryCash: Decimal; // The cash sitting in the treasury subAccount waiting to be invested.
  loanAmount: Decimal; // Cash from all subAccounts (incl. direct indexing, naturally)
};
export const extractDetailedUsables = (
  portfolio: Pick<Portfolio, "cash" | "securities">
): DetailedUsable => {
  const { cash: allCash } = portfolio;

  const {
    holdings: allHoldings,
    loanAmount,
    cash: totalCash,
  } = extractUsables(portfolio);
  // TODO(FREC-3302): Separate out DI holdings + cash
  const selfManagedHoldings = allHoldings.filter(
    (h) => h.subAccountType === SubAccountType.Primary
  );
  const directIndexHoldings = allHoldings.filter(
    (h) => h.subAccountType === SubAccountType.DirectIndex
  );
  const treasuryHoldings = allHoldings.filter(
    (h) => h.subAccountType === SubAccountType.Treasury
  );

  const usableCashArr = allCash?.filter(
    (c) => c.subAccountType === SubAccountType.Primary
  );
  const diCashArr = allCash?.filter(
    (c) => c.subAccountType === SubAccountType.DirectIndex
  );
  const treasuryCashArr = allCash?.filter(
    (c) => c.subAccountType === SubAccountType.Treasury
  );

  const frecCash = getFrecCash(usableCashArr ?? []);
  const directIndexCash = getFrecCash(diCashArr ?? []);
  const treasuryCash = getFrecCash(treasuryCashArr ?? []);

  return {
    allHoldings,
    selfManagedHoldings,
    directIndexHoldings,
    treasuryHoldings,
    allCash: totalCash,
    frecCash,
    directIndexCash,
    treasuryCash,
    loanAmount,
  };
};

export const getPortfolioSubAccountTypes = (portfolio: Portfolio) => {
  const { cash, securities } = portfolio;
  const subAccountTypes = new Set<SubAccountType>();
  cash?.forEach((c) => subAccountTypes.add(c.subAccountType));
  securities?.forEach((s) => subAccountTypes.add(s.subAccountType));
  return Array.from(subAccountTypes);
};

type SubAccountUseable = {
  subAccountId: string;
  subAccountType: SubAccountType;
  holdings: RichHolding[];
  cash: Decimal;
  loanAmount: Decimal;
};
/**
 * Extract more useable objects from the graphql-defined `Portfolio` object with different
 * subAccountIds
 */
export const extractSubAccountUsables = (
  portfolio: Portfolio
): SubAccountUseable[] => {
  const subAccountTypes = getPortfolioSubAccountTypes(portfolio);

  const subAccountUsables: SubAccountUseable[] = subAccountTypes.map(
    (subAccountType) => {
      const { holdings, cash, loanAmount } = extractUsables(
        portfolio,
        subAccountType
      );
      return {
        subAccountId: holdings[0].subAccountId,
        subAccountType,
        holdings,
        cash,
        loanAmount,
      };
    }
  );

  return subAccountUsables;
};

/**
 * Takes a "Holding"[][] and combines into a "Holding"[] (flatMap) by adding the
 * quantities of the same symbol together and averaging the averageCostBasis
 * (if present) using a value-weighted average.
 *
 * NOTE: price is assumed to be the same for all holdings the same symbol.
 */
export function combineHoldings<
  T extends {
    symbol: string;
    price: Decimal;
    quantity: Decimal;
    averageCostBasis?: Decimal;
  }
>(holdingsArr: T[][]): T[] {
  const dataMap: Map<
    string, // symbol
    {
      data: T;
      price: Decimal;
      quantity: Decimal;
      avgCostBasisSum?: Decimal;
      valueSum: Decimal;
    }
  > = new Map();

  holdingsArr.forEach((hArr) => {
    hArr.forEach((h) => {
      // get data if it exists (symbol has already been seen) or create new
      const holdingData = dataMap.get(h.symbol) || {
        data: h,
        price: h.price, // all the prices should be the same, so we grab the first one...
        quantity: new Decimal(0), // we add quantity next line
        avgCostBasisSum: undefined,
        valueSum: new Decimal(0),
      };

      // Throwing an exception here is not ideal, because a price mismatch is not a critical
      // failure. But it probably means the developer got something wrong. But this code is
      // not in the critical path, so it's not a big deal.
      // TODO: tests fail if you uncomment
      // if (h.price !== holdingData.price) {
      //   // eslint-disable-next-line no-console
      //   console.warn("Error code: PU-00301");
      // }

      // avgCostBasis may not be set for the holding at all, so we want to return
      // a holding with, avgCostBasis: undefined (not, say, avgCostBasisSum: 0). But, we
      // also need to compute a running sum, because one but not all of the holdings for
      // a symbol may have a defined value. So the following code does all that.
      //
      // Note: if some, but not all, of the avgCostBasis are defined for a single symbol,
      // then we assume all of the undefined ones are 0.
      const value = h.price.mul(h.quantity);
      let avgCostBasisSum: Decimal | undefined;
      if (h.averageCostBasis) {
        const valueWeighted = h.averageCostBasis.mul(value);
        avgCostBasisSum = holdingData.avgCostBasisSum
          ? holdingData.avgCostBasisSum.add(valueWeighted)
          : valueWeighted;
      }

      // add em up
      dataMap.set(h.symbol, {
        ...holdingData,
        quantity: holdingData.quantity.add(h.quantity),
        avgCostBasisSum,
        valueSum: holdingData.valueSum.add(value),
      });
    });
  });

  // one final pass to calculate the averageCostBasis
  const result: T[] = [];
  dataMap.forEach((h) => {
    result.push({
      ...h.data,
      quantity: h.quantity,
      averageCostBasis: h.avgCostBasisSum
        ? h.avgCostBasisSum.div(h.valueSum)
        : undefined,
    });
  });

  return result;
}

export const holdingBeta = (
  holdings: {
    symbol: string;
    price: Decimal | number;
    quantity: Decimal | number;
  }[],
  betas: { symbol: string; beta: number | Decimal }[]
): Decimal => {
  const betaSum = holdings.reduce((acc, h) => {
    const beta = betas.find((b) => b.symbol === h.symbol)?.beta ?? 1.0;
    return acc.add(makeDecimal(h.quantity).mul(h.price).mul(beta));
  }, new Decimal(0));
  const value = getSecurityHoldingsValue(holdings);
  return betaSum.div(value);
};

/**
 * Sorts an array of holdings by `price * quantity` in descending order, in place.
 *
 * No return value, so it's clear the array is mutated.
 */
export const sortHoldingsByValue = (holdings: Holding[]): void => {
  // Sort in desc. order of value
  holdings.sort((a, b) =>
    b.quantity.mul(b.price).sub(a.quantity.mul(a.price)).toNumber()
  );
};

/**
 * Adds holdings arrays.
 */
export const addHoldings = (h1: Holding[], h2: Holding[]): Holding[] => {
  if (h1.length == 0) return [...h2];
  if (h2.length == 0) return [...h1];
  return combineHoldings([h1, h2]);
};

/**
 * Subtracts holdings arrays.
 *
 * This will return negative holding if h1[i].quantity < h2[i].quantity.
 */
export const subtractHoldings = (h1: Holding[], h2: Holding[]): Holding[] => {
  const h3 = h2.map((h) => ({ ...h, quantity: h.quantity.neg() }));
  return addHoldings(h1, h3);
};

/**
 * Kind of like Option.map(x => f(x)) but for undefined/null
 */
export function uLambda<T, U>(
  x: T | undefined | null,
  f: (x: T) => U
): U | undefined {
  if (x === null || x === undefined) return undefined;
  return f(x);
}
