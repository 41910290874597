export const polyfillArrayAt = () => {
  if (!Array.prototype.at) {
    Array.prototype.at = function (position) {
      // Normalize position
      position = Math.trunc(position) || 0;
      if (position < 0) position += this.length;
      if (position < 0 || position >= this.length) return undefined;
      return this[position];
    };
  }
};
