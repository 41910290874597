import Decimal from "decimal.js";

import { ValidationResultLevel } from "../common/enums";
import { TREASURY_SUPPORTED_MMF_SYMBOLS } from "../constants";
import { MINIMUM_TRADE_VALUE } from "../constants/tradingConstants";
import { DateOnly, dateOnlyEquals } from "../date_utils";
import {
  BatchOrderSource,
  MarginAccountState,
  OrderSide,
  OrderSubmissionMode,
  OrderType,
  QuantityType,
  SecuritySubType,
  SecurityTradeStatus,
  SecurityType,
  SubAccountType,
} from "../generated/graphql";
import { makeDecimal, Percentage, ZERO } from "../utils";
import {
  existsConcentratedHolding,
  getAccountState,
  getEquityValuesWithCash,
  getMaxBorrowAmount,
  Holding,
} from "./marginUtils";

export enum TradeValidationResult {
  InsufficientCash = "InsufficientCash",
  InsufficientQuantity = "InsufficientQuantity",
  NoLimitPrice = "NoLimitPrice",
  TradeValueTooLow = "TradeValueTooLow",
  FractionalBuyValueTooLow = "FractionalBuyValueTooLow",
  InvalidSymbolPrice = "InvalidSymbolPrice",
  InvalidQuantity = "InvalidQuantity",
  InvalidQuantityPrecision = "InvalidQuantityPrecision",
  LimitPriceRequiredForFractionalBuyQueued = "LimitPriceRequiredForFractionalBuyQueued",
  LimitPriceRequiredForNotionalSellQueued = "LimitPriceRequiredForNotionalSellQueued",
  LimitTooHigh = "LimitTooHigh",
  LimitTooLow = "LimitTooLow",
  LimitInvalidPrecision = "LimitInvalidPrecision",
  MarketOpenInvalidQueue = "MarketOpenInvalidQueue",
  MarketClosed = "MarketClosed",
  PatternDayTrade = "PatternDayTrade",
  SymbolNotTradeableFractionally = "SymbolNotTradeableFractionally",
  SymbolNotTradeable = "SymbolNotTradeable",
  SymbolTradingHalted = "SymbolTradingHalted",
  WillTriggerReBalance = "WillTriggerReBalance",
  WillConcentratePosition = "WillConcentratePosition",
  BuyNotAllowed = "BuyNotAllowed",
  SellNotAllowed = "SellNotAllowed",
  NotionalSellNotAllowed = "NotionalSellNotAllowed",
  MaxSellQtyAllowedForNotionalSellOnly = "MaxSellQtyAllowedForNotionalSellOnly",
  UserSymbolNotAllowed = "UserSymbolNotAllowed",
  RebalanceCallBuyNotAllowed = "RebalanceCallBuyNotAllowed",
  RegTBuyNotAllowed = "RegTBuyNotAllowed",
  MMFQuantityFractionalNotAllowed = "MMFQuantityFractionalNotAllowed",
  MMFDollarFractionalNotAllowed = "MMFDollarFractionalNotAllowed",
  MMFInvestmentTooLow = "MMFInvestmentTooLow",
  MMFLimitNotAllowed = "MMFLimitNotAllowed",
  MMFBuyNotAllowed = "MMFBuyNotAllowed",
  LotMatchingInvalidQuantity = "LotMatchingInvalidQuantity",
  LotMatchingInvalidSide = "LotMatchingInvalidSide",
  LotMatchingInvalidTaxLotEntry = "LotMatchingInvalidTaxLotEntry",
  PennyStockBuyNotAllowed = "PennyStockBuyNotAllowed",
}

export const TradeValidationResultLevelMap: Record<
  TradeValidationResult,
  ValidationResultLevel
> = {
  NoLimitPrice: ValidationResultLevel.Error,
  TradeValueTooLow: ValidationResultLevel.Error,
  InvalidSymbolPrice: ValidationResultLevel.Error,
  SymbolNotTradeableFractionally: ValidationResultLevel.Error,
  SymbolNotTradeable: ValidationResultLevel.Error,
  InsufficientCash: ValidationResultLevel.Error,
  LimitTooLow: ValidationResultLevel.Error,
  LimitTooHigh: ValidationResultLevel.Error,
  LimitInvalidPrecision: ValidationResultLevel.Error,
  InsufficientQuantity: ValidationResultLevel.Error,
  MarketOpenInvalidQueue: ValidationResultLevel.Error,
  MarketClosed: ValidationResultLevel.Error,
  SymbolTradingHalted: ValidationResultLevel.Error,
  BuyNotAllowed: ValidationResultLevel.Error,
  SellNotAllowed: ValidationResultLevel.Error,
  UserSymbolNotAllowed: ValidationResultLevel.Error,
  FractionalBuyValueTooLow: ValidationResultLevel.Error,
  LotMatchingInvalidQuantity: ValidationResultLevel.Error,
  LotMatchingInvalidSide: ValidationResultLevel.Error,
  LotMatchingInvalidTaxLotEntry: ValidationResultLevel.Error,
  MaxSellQtyAllowedForNotionalSellOnly: ValidationResultLevel.Error,
  LimitPriceRequiredForFractionalBuyQueued: ValidationResultLevel.Error,
  LimitPriceRequiredForNotionalSellQueued: ValidationResultLevel.Error,
  InvalidQuantity: ValidationResultLevel.Error,
  InvalidQuantityPrecision: ValidationResultLevel.Error,
  NotionalSellNotAllowed: ValidationResultLevel.Error,
  RebalanceCallBuyNotAllowed: ValidationResultLevel.Error,
  RegTBuyNotAllowed: ValidationResultLevel.Error,
  MMFQuantityFractionalNotAllowed: ValidationResultLevel.Error,
  MMFDollarFractionalNotAllowed: ValidationResultLevel.Error,
  MMFLimitNotAllowed: ValidationResultLevel.Error,
  MMFInvestmentTooLow: ValidationResultLevel.Error,
  MMFBuyNotAllowed: ValidationResultLevel.Error,
  PennyStockBuyNotAllowed: ValidationResultLevel.Error,

  PatternDayTrade: ValidationResultLevel.Warning,
  WillTriggerReBalance: ValidationResultLevel.Warning,
  WillConcentratePosition: ValidationResultLevel.Warning,
};

// Ideally this should just be a BatchOrderSingleInput, but it's not,
// we duplicate some the fields here instead.
export type ValidateOrderArgsInput = {
  clearingAccountId: string;
  subAccountId: string;
  limitPrice?: Decimal;
  orderType: OrderType;
  quantity: Decimal;
  maxSellQuantity?: Decimal;
  quantityType: QuantityType;
  side: OrderSide;
  symbol: string;
  securityId: string;
  userId?: string;
  type: SecurityType;
  subType: SecuritySubType;
  lotMatchingInstructions: {
    taxLotEntryId: string;
    tradeDate?: DateOnly;
    quantity: Decimal;
  }[];
  skipFrecCashCheck?: boolean; // to be only set if it's a dependent order (eg. while queuing)
  subAccountType: SubAccountType; // So far this is only used for MMF trade validations between self-directed and Treasury.
};

export type TaxLotValidationInfo = {
  taxLotEntryId: string;
  quantity: Decimal | number;
  securityId: string;
  realizedIndicator: string;
  taxLotOpenBuyDate?: DateOnly; // Should always be present, unless an ACAT fail occurs
};

export type ValidateOrderArgs = {
  input: ValidateOrderArgsInput;
  batchOrderSource: BatchOrderSource;
  orderSubmissionMode: OrderSubmissionMode;
  frecCash: Decimal;
  currentLoanAmount: Decimal;
  holdings: Holding[]; // holdings of the subAccount mentioned in the input
  otherSubAccountHoldings: Holding[]; // holdings of the other subAccounts (excluding one in the input)
  buyStatus: SecurityTradeStatus;
  sellStatus: SecurityTradeStatus;
  currentSymbolPrice: Decimal;
  symbolMarginRequirement: Decimal;
  isMarketOpen: boolean;
  isBuyAllowed: boolean;
  isSellAllowed: boolean;
  isUserSymbolAllowed: boolean;
  isSecurityTradingHalted: boolean;
  accountState?: MarginAccountState;
  callAmount?: Decimal | null;
  minMMFAmount?: number;
  taxLotInfo: TaxLotValidationInfo[]; // For lotMatching verification or wash sale warnings
  skipFrecCashCheck?: boolean; // to be only set if it's a dependent order (eg. while queuing)
  isClientAccount: boolean; // set when the request is for a client account (API)
};

// TODO: warn for wash sales FREC-1711
export function validateOrder(args: ValidateOrderArgs) {
  const { input, currentSymbolPrice } = args;

  const results = new Set<TradeValidationResult>();

  // We check both at time of queue and execution to ensure manually updated entries don't bypass this validation
  if (
    args.orderSubmissionMode === OrderSubmissionMode.Queue ||
    args.orderSubmissionMode === OrderSubmissionMode.ExecuteQueued
  ) {
    validateOrderToQueue(args).forEach((e) => results.add(e));
    if (
      args.orderSubmissionMode === OrderSubmissionMode.Queue &&
      args.batchOrderSource === BatchOrderSource.UserInitiated &&
      args.isMarketOpen
    ) {
      results.add(TradeValidationResult.MarketOpenInvalidQueue);
    }
    // executing regular or queues order
  } else if (!args.isMarketOpen) {
    results.add(TradeValidationResult.MarketClosed);
  }

  if (currentSymbolPrice.lessThanOrEqualTo(0))
    results.add(TradeValidationResult.InvalidSymbolPrice);

  if (
    (args.input.side === OrderSide.Buy &&
      args.buyStatus === SecurityTradeStatus.None) ||
    (args.input.side === OrderSide.Sell &&
      args.sellStatus === SecurityTradeStatus.None)
  ) {
    results.add(TradeValidationResult.SymbolNotTradeable);
  }

  if (
    args.input.quantityType === QuantityType.Fractional &&
    args.input.quantity.lte(0)
  ) {
    results.add(TradeValidationResult.InvalidQuantity);
  }

  const qtyDecimal = args.input.quantity.decimalPlaces();
  // Apex expects notional with 2 decimals, and quantity with upto 5.
  const isInvalidQty =
    args.input.quantityType === QuantityType.Fractional
      ? qtyDecimal > 5
      : qtyDecimal > 2;
  if (isInvalidQty) {
    results.add(TradeValidationResult.InvalidQuantityPrecision);
  }
  if (
    args.input.orderType === OrderType.Limit &&
    args.input.limitPrice &&
    args.input.limitPrice.decimalPlaces() > 2
  ) {
    results.add(TradeValidationResult.LimitInvalidPrecision);
  }

  if (
    (args.input.side === OrderSide.Buy &&
      args.buyStatus === SecurityTradeStatus.WholeShares) ||
    (args.input.side === OrderSide.Sell &&
      args.sellStatus === SecurityTradeStatus.WholeShares)
  ) {
    if (
      args.input.quantityType === QuantityType.Fractional &&
      !args.input.quantity.floor().eq(args.input.quantity)
    ) {
      results.add(TradeValidationResult.SymbolNotTradeableFractionally);
    }
    // quantityType is notional => we can't guarantee whole share execution
    if (args.input.quantityType === QuantityType.Notional) {
      results.add(TradeValidationResult.SymbolNotTradeableFractionally);
    }
  }

  if (
    args.input.subType === SecuritySubType.MoneyMarketFund &&
    input.orderType === OrderType.Limit
  ) {
    results.add(TradeValidationResult.MMFLimitNotAllowed);
  }

  // Don't allow buys for MMFs in primary sub account except for API accounts
  if (
    args.input.subType === SecuritySubType.MoneyMarketFund &&
    args.input.side === OrderSide.Buy &&
    input.subAccountType === SubAccountType.Primary &&
    !args.isClientAccount
  ) {
    results.add(TradeValidationResult.MMFBuyNotAllowed);
  }

  // If the security is in the treasury-supported set and the subAccount type is
  // not treasury then don't allow buys.
  if (
    args.input.subType === SecuritySubType.MoneyMarketFund &&
    args.input.side === OrderSide.Buy &&
    TREASURY_SUPPORTED_MMF_SYMBOLS.includes(args.input.symbol) &&
    input.subAccountType !== SubAccountType.Treasury
  ) {
    results.add(TradeValidationResult.MMFBuyNotAllowed);
  }

  if (input.orderType === OrderType.Limit) {
    if (!input.limitPrice) results.add(TradeValidationResult.NoLimitPrice);
    else if (input.limitPrice.greaterThanOrEqualTo(currentSymbolPrice.mul(1.5)))
      results.add(TradeValidationResult.LimitTooHigh);
    else if (input.limitPrice.lessThanOrEqualTo(currentSymbolPrice.mul(0.5)))
      results.add(TradeValidationResult.LimitTooLow);
  }

  if (!args.isUserSymbolAllowed)
    results.add(TradeValidationResult.UserSymbolNotAllowed);

  if (args.isSecurityTradingHalted)
    results.add(TradeValidationResult.SymbolTradingHalted);

  if (
    args.input.quantityType === QuantityType.Notional &&
    args.input.quantity.lessThan(MINIMUM_TRADE_VALUE)
  ) {
    results.add(TradeValidationResult.TradeValueTooLow);
  }

  if (args.input.lotMatchingInstructions.length > 0) {
    // A function is used here in order to use `return` instead of several nested else blocks
    const checkLotMatching = () => {
      if (args.input.side === OrderSide.Buy) {
        results.add(TradeValidationResult.LotMatchingInvalidSide);
        return;
      }
      // Lot matching is only allowed for fractional orders
      if (args.input.quantityType === QuantityType.Notional) {
        results.add(TradeValidationResult.LotMatchingInvalidQuantity);
        return;
      }

      // check that the summed lot matching quantities equal the order quantity
      const lotMatchingQuantity = args.input.lotMatchingInstructions.reduce(
        (acc, cur) => acc.add(cur.quantity),
        new Decimal(0)
      );
      if (!lotMatchingQuantity.eq(args.input.quantity)) {
        results.add(TradeValidationResult.LotMatchingInvalidQuantity);
        return;
      }

      // check that the lot matching instructions can be met by the current tax lots
      args.input.lotMatchingInstructions.forEach((instruction) => {
        let lotInfo = args.taxLotInfo.find(
          (lot) => lot.taxLotEntryId === instruction.taxLotEntryId
        );

        // Special handling for orders that are queued but executed next day after tax lots are refreshed
        // ideally we should compare via transactionId + openLotId, but even that doesn't work for intra day orders
        // so this is best effort
        if (
          !lotInfo &&
          args.batchOrderSource === BatchOrderSource.UserInitiated &&
          args.orderSubmissionMode === OrderSubmissionMode.ExecuteQueued
        ) {
          lotInfo = args.taxLotInfo.find(
            (lot) =>
              lot.securityId === input.securityId &&
              dateOnlyEquals(instruction.tradeDate, lot.taxLotOpenBuyDate) &&
              instruction.quantity.lte(makeDecimal(lot.quantity))
          );
        }

        // Check that the tax lot entry id exists && date is set
        if (!lotInfo || !lotInfo.taxLotOpenBuyDate) {
          results.add(TradeValidationResult.LotMatchingInvalidTaxLotEntry);
          return;
        }
        // Check that the tax lot securityId matches the order symbol
        if (lotInfo.securityId !== args.input.securityId) {
          results.add(TradeValidationResult.LotMatchingInvalidTaxLotEntry);
          return;
        }
        // Check that the tax lot is not realized (i.e. not sold)
        if (lotInfo.realizedIndicator !== "U") {
          results.add(TradeValidationResult.LotMatchingInvalidTaxLotEntry);
          return;
        }
        // Check that the tax lot entry id has sufficient quantity to satisfy the instruction
        if (makeDecimal(lotInfo.quantity).lessThan(instruction.quantity)) {
          results.add(TradeValidationResult.LotMatchingInvalidQuantity);
          return;
        }
      });
    };

    checkLotMatching();
  }

  (input.side === OrderSide.Buy
    ? validateBuyOrder(args)
    : validateSellOrder(args)
  ).forEach((o) => results.add(o));

  return Array.from(results);
}

/**
 * For an input order to be queued, we need additional restrictions to ensure
 * queued and executed order are identical
 */
export function validateOrderToQueue(args: ValidateOrderArgs) {
  const queueResults = new Set<TradeValidationResult>();
  // Current validations for MMF continue to work since we only allow fractional with nav 1.0
  // For other securities, it has to be market notional order
  if (args.input.subType !== SecuritySubType.MoneyMarketFund) {
    if (args.input.side === OrderSide.Buy) {
      if (
        args.input.quantityType === QuantityType.Fractional &&
        args.input.orderType !== OrderType.Limit
      ) {
        queueResults.add(
          TradeValidationResult.LimitPriceRequiredForFractionalBuyQueued
        );
      }
    } else {
      // For sell orders we need the actual qty or notional with limit price
      if (
        args.input.quantityType === QuantityType.Notional &&
        args.input.orderType !== OrderType.Limit
      ) {
        queueResults.add(
          TradeValidationResult.LimitPriceRequiredForNotionalSellQueued
        );
      }
    }
  }
  return [...queueResults];
}

function getNotionalValue(args: ValidateOrderArgs) {
  const { input } = args;
  if (input.quantityType === QuantityType.Notional) return input.quantity;
  const symbolPrice =
    input.orderType === OrderType.Limit
      ? input.limitPrice ?? new Decimal(0)
      : args.currentSymbolPrice;
  return input.quantity.mul(symbolPrice);
}

function validateBuyOrder(args: ValidateOrderArgs) {
  const { frecCash: computedFrecCash } = args;
  const allHoldings = [...args.holdings, ...args.otherSubAccountHoldings];

  const results = new Set<TradeValidationResult>();

  if (!args.isBuyAllowed) results.add(TradeValidationResult.BuyNotAllowed);

  // go/j/FREC-1791
  if (
    args.currentSymbolPrice.greaterThan(0) &&
    args.currentSymbolPrice.lessThan(1) &&
    args.input.subType !== SecuritySubType.MoneyMarketFund &&
    args.batchOrderSource === BatchOrderSource.UserInitiated
  )
    results.add(TradeValidationResult.PennyStockBuyNotAllowed);

  if (
    args.buyStatus === SecurityTradeStatus.WholeShares &&
    !args.input.quantity.equals(args.input.quantity.toDecimalPlaces(0))
  ) {
    results.add(TradeValidationResult.SymbolNotTradeableFractionally);
  }

  const notionalValue = getNotionalValue(args);
  // For queued order, notional value is already deducted from frec cash
  const frecCash =
    args.orderSubmissionMode === OrderSubmissionMode.ExecuteQueued
      ? computedFrecCash.add(notionalValue)
      : computedFrecCash;

  if (
    args.input.subType === SecuritySubType.MoneyMarketFund &&
    !!args.minMMFAmount
  ) {
    const heldValue = args.holdings
      .filter((h) => h.symbol === args.input.symbol)
      .map((h) => h.quantity.mul(h.price))
      .reduce((prev, cur) => cur.add(prev), ZERO);
    if (heldValue.eq(ZERO) && notionalValue.lessThan(args.minMMFAmount)) {
      results.add(TradeValidationResult.MMFInvestmentTooLow);
    }
  }

  // bypass check during queuing since previous order executions may impact availability of cash
  if (!args.skipFrecCashCheck && frecCash.lessThan(notionalValue)) {
    results.add(TradeValidationResult.InsufficientCash);
  }

  // Minimum trade value of $5 for all notional orders regardless of side, and
  // fractional buy side orders that are not for a whole share quantity
  if (
    args.input.quantityType === QuantityType.Fractional &&
    notionalValue.lessThan(MINIMUM_TRADE_VALUE) &&
    !args.input.quantity.equals(args.input.quantity.toDecimalPlaces(0))
  ) {
    results.add(TradeValidationResult.FractionalBuyValueTooLow);
  }

  if (
    willConcentratePosition({
      prevHoldings: allHoldings,
      newHoldings: getAllHoldingsAfterBuy(args),
    })
  ) {
    results.add(TradeValidationResult.WillConcentratePosition);
  }

  if (
    args.accountState === MarginAccountState.RebalanceCall &&
    getAccountState({
      holdings: getAllHoldingsAfterBuy(args),
      loanAmount: args.currentLoanAmount,
      cash: frecCash.minus(notionalValue),
    }).accountState === MarginAccountState.MarginCall
  ) {
    results.add(TradeValidationResult.RebalanceCallBuyNotAllowed);
  }

  // RegT requirement: max(0.5, marginRequirement) * buyAmount <= equity
  if (
    Decimal.max(args.symbolMarginRequirement, 0.5)
      .mul(notionalValue)
      .greaterThan(
        getEquityValuesWithCash({
          holdings: allHoldings,
          frecCash: frecCash,
          loanAmount: args.currentLoanAmount,
        }).equity
      )
  ) {
    results.add(TradeValidationResult.RegTBuyNotAllowed);
  }

  return results;
}

function willConcentratePosition({
  prevHoldings,
  newHoldings,
}: {
  prevHoldings: Holding[];
  newHoldings: Holding[];
}) {
  if (existsConcentratedHolding(prevHoldings)) return false;
  if (existsConcentratedHolding(newHoldings)) return true;
  return false;
}

function validateSellOrder(args: ValidateOrderArgs) {
  const { input, holdings, currentLoanAmount } = args;
  const results = new Set<TradeValidationResult>();

  if (!args.isSellAllowed) results.add(TradeValidationResult.SellNotAllowed);

  if (input.maxSellQuantity && input.quantityType !== QuantityType.Notional) {
    results.add(TradeValidationResult.MaxSellQtyAllowedForNotionalSellOnly);
  }

  /**
   * Note: If it is executing queued order, we've already removed it from holdings. So the below checks don't matter.
   */
  if (args.orderSubmissionMode === OrderSubmissionMode.ExecuteQueued) {
    return results;
  }

  const currentQuantity = holdings
    .filter((h) => h.symbol === input.symbol)
    .reduce((n, h) => h.quantity.plus(n), new Decimal(0));

  const requiredQuantity = getRequiredQuantity(args);

  // Return early if missing data (e.g. required prices not supplied)
  // Note this does not check for if requiredQuantity eq 0.
  if (!requiredQuantity) {
    results.add(TradeValidationResult.InvalidQuantity);
    return results;
  }

  if (currentQuantity.lessThan(requiredQuantity)) {
    results.add(TradeValidationResult.InsufficientQuantity);
  }

  // Don't allow notional sell if the symbol is also present in DI holdings
  if (
    args.input.quantityType === QuantityType.Notional &&
    args.otherSubAccountHoldings.find(
      (holding) => holding.symbol === input.symbol
    )
  ) {
    results.add(TradeValidationResult.NotionalSellNotAllowed);
  }

  const newHoldings = getHoldingsAfterSale({
    holdings,
    quantityToSell: requiredQuantity,
    symbol: input.symbol,
  });

  const maxBorrowAmount = getMaxBorrowAmount({
    holdings: newHoldings,
  });
  if (maxBorrowAmount.lessThan(currentLoanAmount))
    results.add(TradeValidationResult.WillTriggerReBalance); // or margin call

  if (willConcentratePosition({ prevHoldings: args.holdings, newHoldings }))
    results.add(TradeValidationResult.WillConcentratePosition);

  return results;
}

function getRequiredQuantity({
  input,
  currentSymbolPrice,
}: ValidateOrderArgs): Decimal | undefined {
  if (input.quantityType === QuantityType.Fractional) {
    return input.quantity;
    // Notional Limit order
  } else if (input.orderType === OrderType.Limit) {
    return input.limitPrice && input.limitPrice.greaterThan(0)
      ? input.quantity.div(input.limitPrice)
      : undefined;
  }
  // Notional (input.orderType === OrderType.Market)
  if (!currentSymbolPrice.toNumber()) return undefined;
  return input.quantity.div(currentSymbolPrice);
}

function getHoldingsAfterSale(input: {
  holdings: Holding[];
  symbol: string;
  quantityToSell: Decimal;
}) {
  const newHoldings: Holding[] = input.holdings.filter(
    (h) => h.symbol !== input.symbol
  );
  let remainingQuantity = input.quantityToSell;
  for (const h of input.holdings.filter((h) => h.symbol === input.symbol)) {
    const newHoldingQuantity = h.quantity.minus(
      Decimal.min(remainingQuantity, h.quantity)
    );
    if (newHoldingQuantity.equals(0)) {
      remainingQuantity = remainingQuantity.minus(h.quantity);
    } else {
      remainingQuantity = new Decimal(0);
      newHoldings.push({ ...h, quantity: newHoldingQuantity });
    }
  }
  return newHoldings;
}

function getAllHoldingsAfterBuy(args: ValidateOrderArgs) {
  const quantity =
    args.input.quantityType === QuantityType.Fractional
      ? args.input.quantity
      : args.input.quantity.div(args.currentSymbolPrice);
  const holdings = [...args.holdings, ...args.otherSubAccountHoldings];

  const i = holdings.findIndex((h) => h.symbol === args.input.symbol);
  if (i === -1)
    holdings.push({
      marginRequirement: args.symbolMarginRequirement,
      price: args.currentSymbolPrice,
      quantity,
      symbol: args.input.symbol,
      type: args.input.type,
      subType: args.input.subType,
    });
  else {
    holdings[i] = {
      ...holdings[i],
      quantity: holdings[i].quantity.plus(quantity),
    };
  }
  return holdings;
}

type AllocateSecurityConfigPercentage = {
  symbol: string;
  fractionalAllowed: boolean;
  percentage: Percentage;
};

type AllocateSecuritiesArgs = {
  totalAmount: Decimal;
  securities: AllocateSecurityConfigPercentage[];
};

type AllocateSecurityConfigAmount = {
  symbol: string;
  fractionalAllowed: boolean;
  amount: Decimal;
  quantityType: QuantityType;
};

type AllocationSecuritiesResult = {
  pendingAmount: Decimal;
  securities: AllocateSecurityConfigAmount[];
};

/**
 * Given an input amount and purchase allocation config (symbol, percentage, fractionalSharesAllowed),
 * returns the amount allocated to each security.
 */
export function allocateSecurities(
  args: AllocateSecuritiesArgs
): AllocationSecuritiesResult | undefined {
  const percentTotal = args.securities.reduce(
    (prev, cur) => cur.percentage.getPct().add(prev),
    new Decimal(0)
  );

  // if percentage don't sum up to 100, don't allocate anything
  if (!percentTotal.eq(100)) {
    return;
  }

  const securities: AllocateSecurityConfigAmount[] = args.securities.map(
    (s) => {
      const purchaseAmount = args.totalAmount.mul(s.percentage.getRate());
      const purchaseAmountAllowed = s.fractionalAllowed
        ? purchaseAmount.toDP(2, Decimal.ROUND_DOWN)
        : purchaseAmount.floor();

      return {
        symbol: s.symbol,
        fractionalAllowed: s.fractionalAllowed,
        amount: purchaseAmountAllowed,
        quantityType: s.fractionalAllowed
          ? QuantityType.Notional
          : QuantityType.Fractional,
      };
    }
  );

  const currentTotal = securities.reduce(
    (prev, cur) => cur.amount.add(prev),
    new Decimal(0)
  );
  let pendingAmount = args.totalAmount.sub(currentTotal);
  // Allocate the pending amount to the first securty that allows fractional values
  //  (might skew results but fine for now)
  if (pendingAmount.greaterThan(0)) {
    const updateQtyIdx = securities.findIndex((o) => o.fractionalAllowed);
    if (updateQtyIdx > -1) {
      securities[updateQtyIdx].amount = securities[updateQtyIdx].amount
        .add(pendingAmount)
        .toDP(2, Decimal.ROUND_DOWN);
      pendingAmount = new Decimal(0);
    }
  }
  return {
    pendingAmount: pendingAmount,
    securities: securities,
  };
}
