import { FC } from "react";

import { windowIfAvailable } from "../utils/windowIfAvailable";

type Props = {
  refresh?: () => void;
  title?: string;
};

export const Error: FC<Props> = ({ refresh, title = "500" }: Props) => {
  return (
    <div className="flex flex-col w-full min-h-full">
      <div className="w-full px-6 pb-8 mx-auto mt-6 md:mt-14 lg:px-12 max-w-7xl grow">
        <p className="mb-2 font-normal text-frecDarkGray">{title}</p>
        <h1 className="mb-2">Something went wrong</h1>
        <p className="mb-6 text-frecDarkGray max-w-[426px]">
          Sorry, we’re experiencing an internal server issue. Try refreshing the
          page or come back later.
        </p>
        <button
          className="mantine-UnstyledButton-root disabled:opacity-40 text-frecBlack underline-offset-4 decoration-2 no-underline disabled:!no-underline disabled:cursor-not-allowed hover:underline"
          type="button"
          onClick={() => refresh?.() ?? windowIfAvailable?.location.reload()}
        >
          <div className="flex">Refresh page</div>
        </button>
      </div>
    </div>
  );
};

export default Error;
