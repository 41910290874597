import Decimal from "decimal.js";

export const ACH_DEPOSIT_MIN = 100;
export const ACH_TRANSFER_MAX = 100_000;
export const ACH_DAILY_TRANSFER_LIMIT = 100_000;
export const CASH_TRANSFER_LIMIT_FOR_REVIEW = new Decimal(5000); // 5k
export const WIRE_WITHDRAWAL_MIN = new Decimal(100);
export const WIRE_IN_FEES = new Decimal(8);
export const WIRE_OUT_FEES = new Decimal(25);
export const ACH_RETURN_FEES = new Decimal(30);
export const WIRE_REVERSAL_FEES = new Decimal(30);
export const DIRECT_INDEXING_MIN = 2000;
export const PUBLIC_API_MMF_TRADE_FEE = new Decimal(20);
