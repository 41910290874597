import cookie from "cookie";

export const setIsKnownUserCookie = () => {
  // We set the isKnownUser cookie to true to prevent flash of logged-out content
  document.cookie = cookie.serialize("isKnownUser", "true", {
    path: "/",
    sameSite: "lax",
    maxAge: 60 * 60 * 24,
    secure: true,
  });
};

export const getIsKnownUserCookie = (): boolean => {
  return !!cookie.parse(document.cookie).isKnownUser;
};

export const deleteIsKnownUserCookie = () => {
  document.cookie = cookie.serialize("isKnownUser", "", {
    path: "/",
    sameSite: "lax",
    expires: new Date(0),
    maxAge: 0,
    secure: true,
  });
};
