import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { calculateSHA256 } from "@frec-js/common-web";
import { onAuthStateChanged } from "firebase/auth";
import { FC, PropsWithChildren, useEffect } from "react";

import { useFirebaseAuthCheck } from "../hooks/useFirebaseAuth";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

declare const window: Window & { ire?: (fn: string, options: unknown) => void };

export const UserTrackingProvider: FC<PropsWithChildren> = (props) => {
  const { auth } = useFirebaseAuthCheck();
  const { setIdentity } = useTrackingCode(); // Hubspot tracking

  useEffect(() => {
    if (typeof window === "undefined") return;
    const unsubscribe =
      auth &&
      onAuthStateChanged(auth, async (u) => {
        // set datadog context
        datadogLogs.setGlobalContext({ email: u?.email });
        datadogRum.setUser({
          email: u?.email ?? undefined,
          uid: u?.uid,
        });
        // Impact tracking, FREC-3007
        try {
          window.ire?.("identify", {
            customerId: u?.uid ?? "",
            customerEmail: u?.email ? await calculateSHA256(u.email) : "",
          });
        } catch (e) {}
        // Set Hubspot identity
        u?.email && setIdentity(u.email, u?.uid ? { id: u.uid } : undefined);
      });
    return () => unsubscribe?.();
  }, [auth]);

  return <>{props.children}</>;
};
