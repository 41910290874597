import * as crypto from "crypto";

const impactSnippet = (code: string) =>
  `(function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;g.parentNode.insertBefore(f,g);})('https://utt.impactcdn.com/${code}.js','script','ire',document,window);`;

/**
 * Create a base64 digest formatted SHA of the input and format it
 * as a CSP directive
 */
const sha256CSPDirective = (data: string) => {
  const digest = crypto.createHash("sha256").update(data).digest("base64");
  return `sha256-${digest}`;
};

// Product	Radius (Professional)
// Account	Frec (5034707)
// Program	Frec (22690)
// This ID is the JS filename they gave us
export enum ImpactID {
  Prod = "A5034707-f35e-4dd2-86d4-18a9edd02ca71",
}

export type ImpactInfo = {
  /**
   * JS snippet to be inserted into a <script> tag in the head
   */
  snippet: string;
  /**
   * function returning CSP directive for the script in the head tag
   */
  cspSHA: () => string;
};

export const ImpactSnippets: Record<ImpactID, ImpactInfo> = {
  [ImpactID.Prod]: {
    snippet: impactSnippet(ImpactID.Prod),
    cspSHA: () => sha256CSPDirective(impactSnippet(ImpactID.Prod)),
  },
};
