export const QUALIFIED_CREATE_ACCOUNT_NET_WORTH_MIN = 250_000;

export enum TagCustomEvent {
  CalculateTaxSavings = "calculate_tax_savings",
  EmailCapture = "email_capture",
  Loan = "loan",
  DirectIndexDeposit = "direct_index_deposit",
  DirectIndexWithdrawal = "direct_index_withdrawal",
  DirectIndexSetup = "direct_index_setup",
  Signup = "signup",
  CreateAccount = "create_account",
  QualifiedCreateAccount = "qualified_create_account", // create account for users with NW >250k
  PaymentsAutopay = "payments_autopay",
  PaymentsPay = "payments_pay",
  Repayment = "repayment",
  Trade = "trade",
  Deposit = "deposit",
  Withdrawal = "withdrawal",
  AcatsFull = "acats_full",
  AcatsPartial = "acats_partial",
  LoanPending = "loan_pending",
  TreasuryDeposit = "treasury_deposit",
  TreasurySetup = "treasury_setup",
  TreasuryWithdrawal = "treasury_withdrawal",
  Test = "test",
}
