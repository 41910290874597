/**
 * Sample a number from a normal distribution with the given mean and standard
 * deviation using the Box-Muller transform.
 */
export const sampleNormal = (mean: number, std: number) => {
  const u = 1 - Math.random(); // Subtraction to flip [0, 1) to (0, 1].
  const v = 1 - Math.random();
  const stdNormal = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
  return mean + std * stdNormal;
};

export const sampleNormalN = (mean: number, std: number, n: number) => {
  const samples = [];
  for (let i = 0; i < n; i++) {
    samples.push(sampleNormal(mean, std));
  }
  return samples;
};
