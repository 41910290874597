import {
  BuyBackIndicatorEnumGql,
  DirectIndexTaxLot,
  DirectIndexTaxLotInput,
  LongTermShortTermIndicatorEnumGql,
  RealizedIndicatorEnumGql,
  WashSaleIndicatorEnumGql,
} from "../generated/graphql";
import { TaxLotWithId } from "../portfolio_utils";

export enum ValidationResultLevel {
  Error,
  Warning,
}

// Tax Lot Entry
export enum LongTermShortTermIndicatorEnum {
  Long = "L",
  Short = "S",
}
// TODO: make these graphql enums so they can be used there as well
export enum RealizedIndicatorEnum {
  Realized = "R",
  Unrealized = "U",
}

export enum WashSaleIndicatorEnum {
  None = "",
  WashSale = "W",
}

export enum BuyBackIndicatorEnum {
  None = "",
  ReplacementShares = "R",
}

export enum GiftIndicatorEnum {
  None = "",
  Gift = "G",
}

export enum InheritanceIndicatorEnum {
  None = "",
  Inheritance = "I",
}

// For converting TaxLot to and from Gql
export const TaxLotToGQL = (taxLot: TaxLotWithId): DirectIndexTaxLotInput => {
  return {
    ...taxLot,
    longTermShortTermIndicator:
      taxLot.longTermShortTermIndicator === LongTermShortTermIndicatorEnum.Long
        ? LongTermShortTermIndicatorEnumGql.Long
        : LongTermShortTermIndicatorEnumGql.Short,
    realizedIndicator:
      taxLot.realizedIndicator === RealizedIndicatorEnum.Realized
        ? RealizedIndicatorEnumGql.Realized
        : RealizedIndicatorEnumGql.Unrealized,
    washSalesIndicator:
      taxLot.washSalesIndicator === WashSaleIndicatorEnum.WashSale
        ? WashSaleIndicatorEnumGql.WashSale
        : WashSaleIndicatorEnumGql.None,
    buyBackIndicator:
      taxLot.buyBackIndicator === BuyBackIndicatorEnum.ReplacementShares
        ? BuyBackIndicatorEnumGql.ReplacementShares
        : BuyBackIndicatorEnumGql.None,
  };
};
export const TaxLotFromGQL = (
  taxLot: DirectIndexTaxLot | DirectIndexTaxLotInput
): TaxLotWithId => {
  let taxLotStripped = taxLot;
  if ("__typename" in taxLot) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename, ...rest } = taxLot;
    taxLotStripped = rest;
  }
  return {
    ...taxLotStripped,
    taxLotOpenBuyDate: taxLot.taxLotOpenBuyDate ?? undefined,
    taxLotCloseSellDate: taxLot.taxLotCloseSellDate ?? undefined,
    settlementDate: taxLot.settlementDate ?? undefined,
    closedLotId: taxLot.closedLotId ?? undefined,
    closedTransactionId: taxLot.closedTransactionId ?? undefined,
    longTermShortTermIndicator:
      taxLot.longTermShortTermIndicator ===
      LongTermShortTermIndicatorEnumGql.Long
        ? LongTermShortTermIndicatorEnum.Long
        : LongTermShortTermIndicatorEnum.Short,
    realizedIndicator:
      taxLot.realizedIndicator === RealizedIndicatorEnumGql.Realized
        ? RealizedIndicatorEnum.Realized
        : RealizedIndicatorEnum.Unrealized,
    washSalesIndicator:
      taxLot.washSalesIndicator === WashSaleIndicatorEnumGql.WashSale
        ? WashSaleIndicatorEnum.WashSale
        : WashSaleIndicatorEnum.None,
    buyBackIndicator:
      taxLot.buyBackIndicator === BuyBackIndicatorEnumGql.ReplacementShares
        ? BuyBackIndicatorEnum.ReplacementShares
        : BuyBackIndicatorEnum.None,
  };
};
