import { ScheduledDepositPeriodType } from "../generated/graphql";

export const RECURRING_DEPOSIT_FREQUENCY_OPTIONS = [
  {
    label: "Monthly",
    value: ScheduledDepositPeriodType.Monthly,
  },
  {
    label: "Weekly",
    value: ScheduledDepositPeriodType.Weekly,
  },
  {
    label: "On the 1st and 15th",
    value: ScheduledDepositPeriodType.BiWeekly,
  },
];

export const RECURRING_DEPOSIT_FREQUENCY_LABEL: Record<
  ScheduledDepositPeriodType,
  string
> = {
  MONTHLY: "Monthly",
  WEEKLY: "Weekly",
  BI_WEEKLY: "On the 1st and 15th",
  QUARTERLY: "Quarterly",
};
