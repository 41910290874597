import Decimal from "decimal.js";

import { DateOnly } from "../date_utils";
import { Holding } from "../portfolio_utils";
import { Percentage } from "../utils";
import { SymbolPriceTimeSeries } from "./SymbolPriceTimeSeriesFactory";

/**
 * Core types for simulations and tools
 */

export type DatePoint = {
  date: DateOnly;
  value: number;
};

// Used primarily for value deltas, where important to retain the dates that
// the delta value is computed for.
export type DatePairPoint = {
  startDate: DateOnly;
  endDate: DateOnly;
  value: number;
};

export type SymbolBeta = {
  symbol: string;
  beta: number;
};

export type SecurityTimeSeries = {
  symbol: string;
  timeSeries: DatePoint[];
};

/**
 * Types for API input / output
 */

export type HistoricalDelta = {
  symbol: string;
  deltas: DatePoint[];
  prices: DatePoint[];
};

// used in HistoricalSimulation
export type SimulationRun = {
  maxDrawDown: DatePoint; // gives the date and price at low point
  startDate: DateOnly;
  endDate: DateOnly;
};

export enum SBActionType {
  MARGIN_CALL_SELL,
  ADJUSTED_LOAN_AMOUNT,
}

export type SBSimulationAction = {
  amount: Decimal;
  action: SBActionType;
};

export type SBSimulationHolding = Holding & {
  averageCostBasis: Decimal;
  dividendYield?: (date: DateOnly) => Percentage; // %-yield per share
};

export type SBSimulationScenario = {
  startDate: DateOnly; // typically set to today
  endDate: DateOnly;
  cash: Decimal;
  /**
   * Represents the *cumulative* amount borrowed on a given day
   */
  loanAmount: (date: DateOnly) => Decimal;
  interestRate: (date: DateOnly) => Decimal;
  sell: (date: DateOnly) => Decimal;
  symbolPriceTimeSeries: SymbolPriceTimeSeries;
  initialHoldings: SBSimulationHolding[];
  taxRate?: Decimal; // capital gains tax rate
  dividendTaxRate?: Decimal; // dividend tax rate
};

export type SBSimulationState = {
  date: DateOnly;
  holdings: SBSimulationHolding[];
  cash: Decimal;
  loan: Decimal;
  interestPaid: Decimal; // not used to calculate portfolio value, logging only
  taxPaid: Decimal; // logging only -- for user-sales we sell extra and pay tax
  taxUnpaid: Decimal; // logging only -- for margin calls, we do not sell tax
  actions: SBSimulationAction[]; // logging only
};

/**
 * Types for plotting, aim to deprecate in favor of core types
 */
export type TimeSeriesData = {
  startDate: DateOnly;
  endDate: DateOnly;
  maxDrawDown: DatePoint;
  symbols: string[];
  data: TimeSeriesPoint[];
};

export type SymbolValue = {
  symbol: string;
  value: number;
};

export type TimeSeriesPoint = {
  date: DateOnly;
  values: SymbolValue[];
  normalizedValues: SymbolValue[];
};

export class InsufficientBorrowingPowerError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "InsufficientBorrowingPowerError";
  }
}

export class DataLoadError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "DataLoadError";
  }
}

export class InsufficientPortfolioValueForSale extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "InsufficientPortfolioValueForSale";
  }
}

export enum SimulatorErrors {
  InvalidInput = "InvalidInput",
}

// centralized error object that derives from Node’s Error
export class SimulatorError extends Error {
  public readonly name: string;

  constructor(name: string, description: string) {
    super(description);
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    this.name = name;
    Error.captureStackTrace(this);
  }
}
