/**
 * Hard-coded list of ETFs, used as an allowlist to avoid applying
 * concentration requirements. See: BROKSERV-857
 *
 * Top 500 ETFs by AUM fetched from https://etfdb.com/screener/
 */
export const DIVERSIFIED_ETF_SYMBOLS = [
  "SPY",
  "IVV",
  "VOO",
  "VTI",
  "QQQ",
  "VEA",
  "VTV",
  "IEFA",
  "BND",
  "AGG",
  "VUG",
  "VWO",
  "IJR",
  "VIG",
  "IJH",
  "IEMG",
  "IWF",
  "IWM",
  "IWD",
  "VO",
  "VYM",
  "VXUS",
  "GLD",
  "EFA",
  "BNDX",
  "SCHD",
  "VB",
  "XLV",
  "VGT",
  "XLE",
  "VCSH",
  "XLK",
  "ITOT",
  "VCIT",
  "BSV",
  "LQD",
  "VNQ",
  "RSP",
  "XLF",
  "VEU",
  "MUB",
  "USMV",
  "DIA",
  "SCHX",
  "IVW",
  "IXUS",
  "IWB",
  "IWR",
  "SCHF",
  "SHY",
  "TLT",
  "BIL",
  "VV",
  "IVE",
  "IAU",
  "VBR",
  "DGRO",
  "VT",
  "TIP",
  "SDY",
  "VTEB",
  "EEM",
  "MBB",
  "DVY",
  "IGSB",
  "JPST",
  "GOVT",
  "IEF",
  "SCHB",
  "ESGU",
  "SHV",
  "MDY",
  "IUSB",
  "QUAL",
  "ACWI",
  "HYG",
  "XLP",
  "VHT",
  "VGSH",
  "VTIP",
  "XLU",
  "VOE",
  "DFAC",
  "JEPI",
  "SPLG",
  "VGK",
  "EFV",
  "SPYV",
  "SCHG",
  "VMBS",
  "EMB",
  "SPYG",
  "SCHP",
  "SCHA",
  "VXF",
  "XLY",
  "PFF",
  "XLI",
  "IWS",
  "VGIT",
  "SPDW",
  "STIP",
  "FVD",
  "IUSV",
  "BIV",
  "VBK",
  "HDV",
  "IWN",
  "MTUM",
  "USFR",
  "IWP",
  "GDX",
  "IEI",
  "IUSG",
  "NOBL",
  "TQQQ",
  "GSLC",
  "IWV",
  "SCZ",
  "SPLV",
  "FNDX",
  "COWZ",
  "MGK",
  "SCHV",
  "SLV",
  "EFG",
  "VOT",
  "SCHO",
  "SUB",
  "JNK",
  "SCHM",
  "IWO",
  "MINT",
  "IGIB",
  "VONG",
  "VFH",
  "EWJ",
  "FTCS",
  "VDE",
  "USHY",
  "IBB",
  "IDEV",
  "FLOT",
  "SCHE",
  "XLC",
  "RDVY",
  "IYW",
  "FNDF",
  "DFUV",
  "XBI",
  "VLUE",
  "EEMV",
  "IJJ",
  "SPYD",
  "SCHR",
  "VSS",
  "OEF",
  "DGRW",
  "DFAT",
  "EFAV",
  "ARKK",
  "SMH",
  "GUNR",
  "SPSB",
  "PDBC",
  "IJK",
  "MCHI",
  "SHYG",
  "USIG",
  "IJS",
  "FTSM",
  "ICSH",
  "BBJP",
  "VDC",
  "ESGD",
  "VOOG",
  "SCHZ",
  "VONV",
  "MOAT",
  "AMLP",
  "QYLD",
  "SOXX",
  "SPAB",
  "IHI",
  "SRLN",
  "FPE",
  "EZU",
  "PRF",
  "SPEM",
  "MGV",
  "BBCA",
  "FNDA",
  "QQQM",
  "ESGV",
  "VPL",
  "SPIB",
  "TLH",
  "SCHH",
  "SPTM",
  "DFUS",
  "KWEB",
  "SPTL",
  "SGOV",
  "VPU",
  "VTWO",
  "SPMD",
  "XLB",
  "JIRE",
  "EWZ",
  "ICLN",
  "IJT",
  "FTEC",
  "ISTB",
  "XLRE",
  "FXI",
  "DFAS",
  "SOXL",
  "PGX",
  "GLDM",
  "CIBR",
  "DFAX",
  "XOP",
  "IGV",
  "VYMI",
  "AVUV",
  "LMBS",
  "SPSM",
  "IWY",
  "INDA",
  "ACWV",
  "VCLT",
  "SHM",
  "FDL",
  "FNDE",
  "IDV",
  "ITA",
  "IXJ",
  "CWB",
  "DBEF",
  "DFIV",
  "VCR",
  "BLV",
  "GBIL",
  "IQLT",
  "SLYV",
  "EWC",
  "FTGC",
  "NEAR",
  "SQQQ",
  "SPTS",
  "ACWX",
  "FDN",
  "HYLB",
  "VIGI",
  "IAGG",
  "EWT",
  "BKLN",
  "ONEQ",
  "BBAX",
  "ESGE",
  "SPHD",
  "LIT",
  "SPMB",
  "IGF",
  "TFLO",
  "DLN",
  "TFI",
  "VIS",
  "DSI",
  "VNQI",
  "MGC",
  "SPHQ",
  "IEUR",
  "PAVE",
  "RPV",
  "VGLT",
  "GDXJ",
  "SJNK",
  "IOO",
  "SPTI",
  "GNR",
  "BBEU",
  "BSCO",
  "VONE",
  "SUSA",
  "HEFA",
  "IYH",
  "SCHC",
  "EWY",
  "DON",
  "EWU",
  "FV",
  "AVUS",
  "IYR",
  "PULS",
  "HYD",
  "FHLC",
  "KRE",
  "USSG",
  "SLQD",
  "VOOV",
  "BOND",
  "XT",
  "SSO",
  "DBC",
  "VSGX",
  "BBIN",
  "FIXD",
  "VAW",
  "FLRN",
  "REET",
  "SUSL",
  "IXN",
  "EMXC",
  "VWOB",
  "ANGL",
  "VUSB",
  "QLD",
  "SKYY",
  "FTSL",
  "SPXL",
  "TAN",
  "JHMM",
  "GSIE",
  "DFAU",
  "AAXJ",
  "EMLC",
  "AMJ",
  "EMLP",
  "OIH",
  "NFRA",
  "MDYV",
  "BSCN",
  "DFAI",
  "FNDC",
  "IGM",
  "JMST",
  "VOX",
  "FBND",
  "SH",
  "GVI",
  "SCHK",
  "DGS",
  "RPG",
  "IYE",
  "VNLA",
  "URTH",
  "SPIP",
  "DIVO",
  "USO",
  "COMT",
  "PBUS",
  "UPRO",
  "SLYG",
  "ICF",
  "OMFL",
  "SGOL",
  "AOR",
  "XSOE",
  "AVEM",
  "VIOO",
  "JETS",
  "IXC",
  "ARKG",
  "GSY",
  "FAS",
  "EPP",
  "PFFD",
  "HYMB",
  "NUGO",
  "XME",
  "NRGU",
  "AVDV",
  "KBWB",
  "IYF",
  "EAGG",
  "TOTL",
  "KBE",
  "AVDE",
  "RYT",
  "CDC",
  "TDTT",
  "FUTY",
  "FXN",
  "EBND",
  "MDYG",
  "PTLC",
  "PRFZ",
  "DXJ",
  "IBDP",
  "IBDO",
  "XYLD",
  "QCLN",
  "XLG",
  "UUP",
  "PZA",
  "USRT",
  "ASHR",
  "DES",
  "CMF",
  "FMB",
  "DFAE",
  "PKW",
  "DFCF",
  "ICVT",
  "IFRA",
  "DFIC",
  "BSCP",
  "IBTD",
  "IYK",
  "FEZ",
  "ITM",
  "VRP",
  "JAAA",
  "SLY",
  "PHO",
  "DEM",
  "SPGP",
  "PPA",
  "FENY",
  "KOMP",
  "EWA",
  "IEV",
  "IPAC",
  "RWL",
  "IHF",
  "TDIV",
  "COPX",
  "FNCL",
  "NULV",
  "URA",
  "QDF",
  "PCY",
  "HYLS",
  "AOA",
  "RWR",
  "IVLU",
  "IYY",
  "TECL",
  "FXH",
  "FXR",
  "FALN",
  "IBDQ",
  "ILCG",
  "ESML",
  "CWI",
  "FREL",
  "REGL",
  "AOM",
  "PEY",
  "QTEC",
  "HYS",
  "BSCM",
  "IVOO",
  "IYG",
  "KXI",
  "FXZ",
  "BAB",
  "MOO",
  "FLCB",
  "GSG",
  "IWX",
  "HACK",
  "FBT",
  "TILT",
  "LCTU",
  "PICK",
  "VTHR",
  "DHS",
  "PSQ",
  "RODM",
  "IGLB",
  "CALF",
  "BSCQ",
  "LDUR",
  "BBUS",
  "RYLD",
  "BBMC",
  "FDVV",
  "EDV",
  "MLPA",
  "TIPX",
  "AIA",
  "EWG",
  "IBTE",
  "INFL",
  "BOTZ",
  "XAR",
  "DUHP",
  "EUFN",
  "UITB",
  "IHDG",
  "UCON",
  "PXH",
  "FTA",
  "FIW",
  "DBA",
  "SDOG",
  "VIOV",
  "HEDJ",
];
