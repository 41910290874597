import Decimal from "decimal.js";

// TODO FREC-2402: Move this to RemoteConfig so that we don't need to deploy to support new MMFs.
export const TREASURY_SUPPORTED_MMF_SYMBOLS = ["SNSXX", "FUGXX"];

export const TREASURY_ANNUAL_FEE_PERCENTAGE = new Decimal(0.2);
export const TREASURY_ANNUAL_FEE_BASIS_POINTS = new Decimal(20);

export const UNSUPPORTED_INSTITUTION_IDS_FOR_AUTODEPOSIT = [
  "ins_128026", // Capital One
];
