import Decimal from "decimal.js";

/**
 * This class exists to prevent the ambiguity that one is faced with when using/receiving
 * a number like `taxRate` and not knowing if 20% is represented as 20.0 or 0.2.
 */
export class Percentage {
  private readonly percent: Decimal;

  /**
   * Required input as a named-parameter `percent` to prevent ambiguity,
   * if you want 20% use: `new Percentage({ percent: 20.0 })`.
   */
  constructor({ percent }: { percent: Decimal | number | string }) {
    this.percent = makeDecimal(percent);
  }

  /**
   * Percentage as a decimal, e.g. 20% is 20.0
   */
  getPct(): Decimal {
    return this.percent;
  }

  /**
   * Percentage as rate , e.g. 20% is 0.2
   */
  getRate(): Decimal {
    return this.percent.div(100);
  }

  /**
   * Percentage from a rate, 0.2 is 20%
   */
  static fromRate(rate: Decimal | number): Percentage {
    const percent = makeDecimal(rate).mul(100);

    return new Percentage({ percent });
  }

  pretty(dp?: number): string {
    return `${this.percent.toFixed(dp ?? 2)}%`;
  }

  // overwrite toString() to return the percentage as a string
  toString(): string {
    return JSON.stringify(this);
  }

  toJSON() {
    return {
      percent: this.percent.toString(),
      rate: this.getRate().toString(),
    };
  }
}

const NUMBER_FORMATTER = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 2,
});

const INTEGER_FORMATTER = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 0,
});

const PERCENT_FORMATTER = (
  minimumFractionDigits: number,
  maximumFractionDigits: number
) =>
  new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits,
    maximumFractionDigits,
  });

const USD_COMPACT_FORMATTER = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumSignificantDigits: 3,
  notation: "compact",
});

const USD_FORMATTER = (minNumDecimals: number, maxNumDecimals?: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    minimumFractionDigits: minNumDecimals,
    maximumFractionDigits: maxNumDecimals ?? minNumDecimals,
    currency: "USD",
  });

export const MAX_STOCK_QUANTITY_DECIMALS = 8;
const STOCK_QUANTITY_FORMATTER = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: MAX_STOCK_QUANTITY_DECIMALS,
});

export const formatUsd = (
  n: number | Decimal,
  minNumDecimals = 0,
  maxNumDecimals?: number
) =>
  USD_FORMATTER(minNumDecimals, maxNumDecimals).format(
    n instanceof Decimal ? n.toNumber() : n
  );
export const formatUsdCompressed = (n: number) => {
  if (n < 1000 && n % 1 > 0) {
    // Don't show $102.6
    return formatUsd(n, 2);
  }
  return USD_COMPACT_FORMATTER.format(n).toUpperCase();
};
export const formatPercent = (
  n: number | Decimal,
  minNumDecimals = 0,
  maxNumDecimals = 2
) => {
  return PERCENT_FORMATTER(minNumDecimals, maxNumDecimals).format(
    makeDecimal(n).dividedBy(100).toNumber()
  );
};
export const formatPercentToDecimalPlaces = (
  n: number | Decimal,
  numDecimals = 2
) => {
  return `${makeDecimal(n).toFixed(numDecimals)}%`;
};
export const formatStockQuantity = (n: number | Decimal) => {
  return STOCK_QUANTITY_FORMATTER.format(
    n instanceof Decimal ? n.toNumber() : n
  );
};
export const formatInteger = (n: number) => INTEGER_FORMATTER.format(n);
export const formatNumber = (n: number) => NUMBER_FORMATTER.format(n);

export const numDecimals = (value: number) => {
  return Math.max(
    0,
    new Decimal(value).minus(Math.floor(value)).toString().length - 2
  );
};

export const makeDecimal = (x: Decimal | number | string): Decimal => {
  return x instanceof Decimal ? x : new Decimal(x);
};

export const makeNumber = (x: Decimal | number): number => {
  return x instanceof Decimal ? x.toNumber() : x;
};

export const maybeDecimal = (
  x: Decimal | number | string | null | undefined
): Decimal | undefined => {
  if (x === null || x === undefined || x === "") {
    return undefined;
  } else if (x instanceof Decimal) {
    return x;
  } else {
    return new Decimal(x);
  }
};

export const makeDecimalOrZero = (
  x: Decimal | number | string | null | undefined
) => {
  return maybeDecimal(x) ?? ZERO;
};

export const seededRandom = (seed: number) => {
  return function () {
    let t = (seed += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
};

/**
 * bound a number between a lower and upper bound
 */
export const bound = (
  input: number,
  lowerBound: number,
  upperBound: number
): number => {
  return Math.min(upperBound, Math.max(lowerBound, input));
};

/**
 * Lower bound a number by a minimum value, default is 0.
 */
export const lb = (input: number, lowerBound = 0): number => {
  return Math.max(lowerBound, input);
};

/**
 * Compounded Annual Growth Rate, aka "average annual growth rate"
 */
export const cagr = (
  start: number | Decimal | string,
  end: number | Decimal | string,
  years: number
): Percentage => {
  start = makeDecimalOrZero(start).toNumber();
  end = makeDecimalOrZero(end).toNumber();
  return Percentage.fromRate(Math.pow(end / start, 1 / years) - 1);
};

export const round = (n: number, decimals = 0) => {
  return Math.round(n * 10 ** decimals) / 10 ** decimals;
};

export const roundUpToMultiple = (n: number, multiple: number) => {
  return Math.ceil(n / multiple) * multiple;
};

export const roundDownToMultiple = (n: number, multiple: number) => {
  return Math.floor(n / multiple) * multiple;
};

export const ZERO = new Decimal(0);
export const ONE = new Decimal(1);

export const d = (n: number | Decimal | string) => new Decimal(n);

export const bpsToPercent = (n: number | Decimal) => {
  return makeDecimal(n).div(100);
};

// Technically this is using 1024 as the base, which should yield
// KiB, MiB, GiB etc. but since KB, MB, GB etc. are more commonly
// used, we return those instead.
// https://stackoverflow.com/a/18650828
export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0B";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
