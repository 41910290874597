export enum FrecProductName {
  Treasury = "Treasury",
  DirectIndexing = "Direct Indexing",
}

export const FrecProduct = {
  [FrecProductName.Treasury]: {
    name: "Treasury",
    shortName: "Treasury",
    fullName: "Treasury",
  },
  [FrecProductName.DirectIndexing]: {
    name: "Direct Indexing",
    shortName: "DI",
    fullName: "Direct Indexing",
  },
};
