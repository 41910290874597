import { useValidateInviteCodeQuery } from "@frec-js/common-client";
import { useMemo } from "react";

export const useValidateInviteCode = (inviteCode: string | null) => {
  const { data, loading, error } = useValidateInviteCodeQuery({
    variables: { inviteCode: inviteCode ?? "" },
    skip: !inviteCode,
  });
  const response = useMemo(() => {
    return {
      isValid: data?.validateInviteCode ?? false,
      loading,
      error,
    };
  }, [data, loading, error]);
  return response;
};
