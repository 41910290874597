import Decimal from "decimal.js";
import { groupBy, partition } from "lodash";

import {
  BuyBackIndicatorEnum,
  LongTermShortTermIndicatorEnum,
  RealizedIndicatorEnum,
  WashSaleIndicatorEnum,
} from "../common";
import { dateCompare, DateOnly, EXCHANGE_TIMEZONE } from "../date_utils";
import {
  OrderPositionType,
  OrderSide,
  RealizedLotsSummary,
} from "../generated/graphql";
import { get, ONE, safeStringify } from "../utils";

export enum TaxLotErrorResult {
  InsufficientQtyToCompleteOrder = "InsufficientQtyToCompleteOrder",
  LotMatchingInstructionInputTaxLotNotFound = "LotMatchingInstructionInputTaxLotNotFound",
  LotMatchingInstructionsInvalid = "LotMatchingInstructionsInvalid",
  LotMatchingInstructionsInvalidQuantity = "LotMatchingInstructionsInvalidQuantity",
  LotMatchingInstructionsNotEligibleForOrder = "LotMatchingInstructionsNotEligibleForOrder",
  InvalidZeroQuantityForTrade = "InvalidZeroQuantityForTrade",
  InvalidBoxPositionForTrade = "InvalidBoxPositionForTrade",
}

export type TaxLotResult = {
  createLots: TaxLot[];
  deleteLotIds: string[];
  errors?: Set<TaxLotErrorResult>;
};

export type TaxLotResultWithMetadata = TaxLotResult & {
  // Keeps track of updated unrealized lots
  updatedUnrealizedLots: TaxLotWithId[];
};

export type LotIdToBeSold = {
  taxLotEntryId: string;
  sellQuantity: Decimal;
};

export type LotToBeSold = {
  taxLot: TaxLotWithId;
  sellQuantity: Decimal;
};

export type LotMatchingInstructionsInputArgs = {
  tradeDate: DateOnly;
  quantity: Decimal;
  price?: Decimal;
  taxLotEntryId: string;
};

export type OrderLotInputArgs = {
  subAccountId: string;
  securityId: string;
  side: OrderSide;
  positionType: OrderPositionType;
  quantity: Decimal; // qty transacted
  sharePrice: Decimal; // price per share
  notional: Decimal; // notional value
  eventTime: Date;
  lotMatchingInstructions: LotMatchingInstructionsInputArgs[];
  orderFees?: Decimal;
};

export type TaxLot = {
  subAccountId: string;
  securityId: string;
  eventTime: Date;
  settlementDate?: DateOnly;
  taxLotOpenBuyDate?: DateOnly;
  taxLotCloseSellDate?: DateOnly;
  openBuyPrice: Decimal;
  openBuyCostAmount: Decimal;
  openTransactionId: string;
  openLotId: string;
  closedLotId?: string;
  closedTransactionId?: string;
  quantity: Decimal;
  cost: Decimal;
  netProceed: Decimal;
  realizedGainLoss: Decimal;
  longTermShortTermIndicator: LongTermShortTermIndicatorEnum;
  realizedIndicator: RealizedIndicatorEnum;
  // washSalesIndicator and washSalesDisAllowed are set for realized wash sale lots
  // buyBackIndicator and washSaleAdjustmentAmount are set for unrealized (replacement lots)
  washSalesIndicator: WashSaleIndicatorEnum; // set as W for realized lots to indicate wash sale
  washSalesDisallowed: Decimal; // realized lots only (Represents the lost amount disallowed due to wash sale)
  washSaleAdjustmentAmount: Decimal; // unrealized lots only (disallowed loss amount added to cost basis for replacement shares)
  buyBackIndicator: BuyBackIndicatorEnum; // set as R for unrealized lots only to indicate replacement share
};

export type TaxLotWithId = TaxLot & {
  id: string;
};

type SellLotResult = {
  realizedLot: TaxLot;
  // Present if there is any unrealized lot leftOver
  remainingUnrealizedLot?: TaxLotWithId;
};

const ZERO = new Decimal(0);

/**
 * Generate a unique id of specified length (with max length 21).
 * Note: This is not in uuid format and can possibly have collisions,
 * but should be non-existent for our use case
 */
export const uniqueId = (len = 21) => {
  return (Date.now().toString(36) + Math.random().toString(36)).slice(-len);
};

/**
 * Given an input order and current tax lots, run order validations for tax lots
 */
export function runTaxLotValidations(
  orderLotInput: OrderLotInputArgs,
  currentTaxLots: TaxLotWithId[]
): Set<TaxLotErrorResult> {
  const errorResults: Set<TaxLotErrorResult> = new Set();

  const validTaxLots = currentTaxLots.filter(
    (l) => l.subAccountId === orderLotInput.subAccountId
  );

  if (orderLotInput.quantity.lte(0)) {
    errorResults.add(TaxLotErrorResult.InvalidZeroQuantityForTrade);
  }

  const lotMatchingEligibleSide =
    orderLotInput.positionType === OrderPositionType.Short
      ? OrderSide.Buy
      : OrderSide.Sell;

  if (orderLotInput.lotMatchingInstructions.length > 0) {
    if (orderLotInput.side !== lotMatchingEligibleSide) {
      errorResults.add(
        TaxLotErrorResult.LotMatchingInstructionsNotEligibleForOrder
      );
    } else {
      const taxLotLookUp = new Map(
        validTaxLots.map((taxLot) => [taxLot.id, taxLot])
      );

      // Check for invalid lot ids
      const invalidOrderLots = orderLotInput.lotMatchingInstructions.filter(
        (lot) => !taxLotLookUp.has(lot.taxLotEntryId)
      );
      if (invalidOrderLots.length > 0) {
        errorResults.add(
          TaxLotErrorResult.LotMatchingInstructionInputTaxLotNotFound
        );
      } else {
        // Check for invalid lot instructions
        const invalidLotInstructions =
          orderLotInput.lotMatchingInstructions.filter((lot) => {
            const taxLot = taxLotLookUp.get(lot.taxLotEntryId) as TaxLotWithId;

            return (
              lot.quantity.lte(0) ||
              lot.quantity.greaterThan(taxLot.quantity.abs()) ||
              lot.tradeDate.valueOf() !== taxLot.taxLotOpenBuyDate?.valueOf()
            );
          });

        if (invalidLotInstructions.length > 0) {
          errorResults.add(TaxLotErrorResult.LotMatchingInstructionsInvalid);
        }
      }

      // Check if lot qty sum up to input qty
      const lotMatchingQuantity = orderLotInput.lotMatchingInstructions.reduce(
        (acc, cur) => acc.add(cur.quantity.abs()),
        new Decimal(0)
      );
      if (!lotMatchingQuantity.eq(orderLotInput.quantity)) {
        errorResults.add(
          TaxLotErrorResult.LotMatchingInstructionsInvalidQuantity
        );
      }
    }
  }
  if (orderLotInput.side === lotMatchingEligibleSide) {
    const currentHeldLots = validTaxLots.filter(
      (taxLot) =>
        taxLot.securityId === orderLotInput.securityId &&
        taxLot.realizedIndicator == RealizedIndicatorEnum.Unrealized
    );

    const currentHeldQty = currentHeldLots.reduce(
      (acc, cur) => acc.add(cur.quantity.abs()),
      new Decimal(0)
    );

    if (currentHeldQty.lessThan(orderLotInput.quantity)) {
      errorResults.add(TaxLotErrorResult.InsufficientQtyToCompleteOrder);
    }

    /**
     * Box position check should work against all accounts
     */
    const unrealizedLotsInDifferentPosition = currentTaxLots.filter(
      (l) =>
        l.securityId === orderLotInput.securityId &&
        l.realizedIndicator == RealizedIndicatorEnum.Unrealized &&
        (orderLotInput.positionType === OrderPositionType.Short
          ? l.quantity.greaterThan(0)
          : l.quantity.lessThan(0))
    );
    if (unrealizedLotsInDifferentPosition.length > 0) {
      errorResults.add(TaxLotErrorResult.InvalidBoxPositionForTrade);
    }
  }

  return errorResults;
}

/**
 * Given existing TaxLot(WithIds), and an input order lot,
 * recommends the changes required to update the tax lot
 */
export function computeTaxLotEntry(
  orderLotInput: OrderLotInputArgs,
  currentTaxLots: TaxLotWithId[]
): TaxLotResult {
  const createLots: TaxLot[] = [];
  const deleteLotIds: string[] = [];
  const errorResult = runTaxLotValidations(orderLotInput, currentTaxLots);

  if (errorResult.size > 0) {
    return {
      errors: errorResult,
      createLots,
      deleteLotIds,
    };
  }
  const { side, positionType } = orderLotInput;
  if (
    (positionType === OrderPositionType.Long && side === OrderSide.Buy) ||
    (positionType === OrderPositionType.Short && side === OrderSide.Sell)
  ) {
    return processTaxLotEntryForUnrealizedLot(orderLotInput, currentTaxLots);
  } else {
    return processTaxLotEntryForRealizedLot(orderLotInput, currentTaxLots);
  }
}

/**
 * For a given unrealized order @orderLotInput, find candidates that can be marked as wash sales from @currentTaxLots
 *
 * @VisibleForTesting
 */
export function _findWashSaleCandidates<
  O extends {
    securityId: string;
    side: OrderSide;
    positionType: OrderPositionType;
    quantity: Decimal;
    eventTime: Date;
  },
  T extends {
    securityId: string;
    quantity: Decimal;
    realizedIndicator: RealizedIndicatorEnum;
    washSalesIndicator: WashSaleIndicatorEnum;
    realizedGainLoss: Decimal;
    taxLotOpenBuyDate?: DateOnly;
    taxLotCloseSellDate?: DateOnly;
    eventTime: Date;
  }
>(orderLotInput: O, currentTaxLots: T[]): T[] {
  const skipWashSaleForOrderSide =
    orderLotInput.positionType === OrderPositionType.Short
      ? OrderSide.Buy
      : OrderSide.Sell;

  if (orderLotInput.side === skipWashSaleForOrderSide) {
    return [];
  }

  return washSaleOrReplacementLot(
    orderLotInput,
    currentTaxLots,
    _washSaleCandidateFilter,
    _sellDateBuyDateEventTimeCompareTo,
    false
  );
}

/**
 * For a given sell order @orderLotInput, find candidates that can be considered as replacement shares
 *
 * @VisibleForTesting
 */
export function _findReplacementLots<
  O extends {
    securityId: string;
    side: OrderSide;
    quantity: Decimal;
    eventTime: Date;
  },
  T extends {
    securityId: string;
    quantity: Decimal;
    realizedIndicator: RealizedIndicatorEnum;
    buyBackIndicator: BuyBackIndicatorEnum;
    taxLotOpenBuyDate?: DateOnly;
    taxLotCloseSellDate?: DateOnly;
    eventTime: Date;
  }
>(orderLotInput: O, currentTaxLots: T[]): T[] {
  if (orderLotInput.side === OrderSide.Buy) {
    return [];
  }

  // Verify: 26 CFR 1.1091-1(e) lot can be considered for replacement only once
  const replacementLotFilter = (taxLot: T) =>
    taxLot.realizedIndicator === RealizedIndicatorEnum.Unrealized &&
    taxLot.buyBackIndicator !== BuyBackIndicatorEnum.ReplacementShares;

  return washSaleOrReplacementLot(
    orderLotInput,
    currentTaxLots,
    replacementLotFilter,
    buyDateEventTimeCompareTo,
    true
  );
}

/**
 * Given a @newQty returns the new @taxLot with the updated values based on the new qty.
 */
export function _splitLot<
  T extends {
    quantity: Decimal;
    openBuyCostAmount: Decimal;
    cost: Decimal;
    netProceed: Decimal;
    realizedGainLoss: Decimal;
    washSalesDisallowed: Decimal;
    washSaleAdjustmentAmount: Decimal;
  }
>(taxLot: T, newQty: Decimal): T {
  const splitFactor = newQty.div(taxLot.quantity).abs();

  return {
    ...taxLot,
    quantity: taxLot.quantity.mul(splitFactor).toDecimalPlaces(5),
    openBuyCostAmount: taxLot.openBuyCostAmount
      .mul(splitFactor)
      .toDecimalPlaces(2),
    cost: taxLot.cost.mul(splitFactor).toDecimalPlaces(2),
    netProceed: taxLot.netProceed.mul(splitFactor).toDecimalPlaces(2),
    realizedGainLoss: taxLot.realizedGainLoss
      .mul(splitFactor)
      .toDecimalPlaces(2),
    // TODO: FREC-1759: verify if this is needed
    washSalesDisallowed: taxLot.washSalesDisallowed
      .mul(splitFactor)
      .toDecimalPlaces(2),
    washSaleAdjustmentAmount: taxLot.washSaleAdjustmentAmount
      .mul(splitFactor)
      .toDecimalPlaces(2),
  };
}

/**
 * Given an order, get unrealized candidates that can be sold to satisfy the quantity
 */
export function getUnrealizedCandidates<
  O extends { subAccountId: string; securityId: string; quantity: Decimal },
  T extends {
    id: string;
    securityId: string;
    subAccountId: string;
    quantity: Decimal;
    cost: Decimal;
    realizedIndicator: RealizedIndicatorEnum;
    taxLotOpenBuyDate?: DateOnly;
    eventTime: Date;
  }
>(
  orderLotInput: O,
  currentTaxLots: T[],
  sortFn: (lot1: T, lot2: T) => number
): LotIdToBeSold[] {
  const result: LotIdToBeSold[] = [];
  // Figure out lots sold for this order using FIFO.
  const sortedUnrealizedLots = currentTaxLots
    .filter(
      (taxLot) =>
        taxLot.subAccountId === orderLotInput.subAccountId &&
        taxLot.securityId === orderLotInput.securityId &&
        taxLot.realizedIndicator == RealizedIndicatorEnum.Unrealized
    )
    .sort(sortFn);

  // Since order can be notional, we use shareQty to subtract proper qty value
  let remainingQty = orderLotInput.quantity;
  while (!remainingQty.equals(0) && sortedUnrealizedLots.length !== 0) {
    const unrealizedTaxLot = sortedUnrealizedLots.shift() as T;
    // deduct the maximum we can deduct from the lot
    const qtySelected = Decimal.min(
      unrealizedTaxLot.quantity.abs(),
      remainingQty
    );
    result.push({
      taxLotEntryId: unrealizedTaxLot.id,
      sellQuantity: qtySelected,
    });
    remainingQty = remainingQty.minus(qtySelected);
  }

  return result;
}

/**
 * Given existing tax lots, and a list of lots to be sold (id, qty), generates the tax lots (pre wash sale)
 * @VisibleForTesting
 */
export function _processCandidatesToRealize(
  orderLotInput: {
    sellPrice: Decimal;
    eventTime: Date;
  },
  lotToBeSold: LotToBeSold[]
): TaxLotResultWithMetadata {
  const createLots: TaxLot[] = [];
  const deleteLotIds: string[] = [];
  const updatedUnrealizedLots: TaxLotWithId[] = [];

  lotToBeSold.forEach((lot) => {
    const sellLotResult = sellPartOfUnrealizedLot(
      {
        quantityToBeSold: lot.sellQuantity,
        sellPrice: orderLotInput.sellPrice,
        eventTime: orderLotInput.eventTime,
      },
      lot.taxLot
    );

    if (sellLotResult.remainingUnrealizedLot) {
      createLots.push(sellLotResult.remainingUnrealizedLot);
      updatedUnrealizedLots.push(sellLotResult.remainingUnrealizedLot);
    }

    createLots.push(sellLotResult.realizedLot);
    // mark lot to be deleted
    deleteLotIds.push(lot.taxLot.id);
  });

  return {
    createLots,
    deleteLotIds,
    updatedUnrealizedLots,
  };
}

/**
 * There are multiple scenarios possible. For each wash sale lot, there are three possible scenarios:
 *  - Wash sale qty < Buy Qty: Split the buy lot into two lots marking one of them as replacement lot
 *  - Wash sale qty > Buy qty: Split wash sale lot into two lots marking one as wash sale and updating qty for the rest
 *  - Wash sale qty = buy Qty: No split required, mark the sell lot as wash sale and buy lot as replacement lot.
 */
function processWashSaleCandidatesForUnrealizedLots(
  buyCandidate: TaxLot,
  washSaleCandidates: TaxLotWithId[]
): TaxLotResult {
  const createLots: TaxLot[] = [];
  const deleteLotIds: string[] = [];

  const signMultiplier = buyCandidate.quantity.lessThan(0) ? -1 : 1;
  let remainingQty = buyCandidate.quantity.abs();
  for (const washSaleCandidate of washSaleCandidates) {
    const qtySelected = Decimal.min(
      washSaleCandidate.quantity.abs(),
      remainingQty
    );
    // remove original realized lot
    deleteLotIds.push(washSaleCandidate.id);

    if (washSaleCandidate.quantity.abs().equals(qtySelected)) {
      // update entire original realized lot as wash sale
      const washSaleLot = {
        ...washSaleCandidate,
        washSalesIndicator: WashSaleIndicatorEnum.WashSale,
        washSalesDisallowed: washSaleCandidate.realizedGainLoss.abs(),
      };
      createLots.push(washSaleLot);

      // Now add the replacement lot
      const adjustedBuyCandidate = _splitLot(
        buyCandidate,
        qtySelected.mul(signMultiplier)
      );
      const washSaleAdjustment = washSaleLot.washSalesDisallowed;
      const costBasis = adjustedBuyCandidate.cost.add(washSaleAdjustment);

      const replacementLot = {
        ...adjustedBuyCandidate,
        openLotId: uniqueId(16),
        netProceed: costBasis, // we use original cost basis (this is recomputed for realized lots)
        cost: costBasis,
        buyBackIndicator: BuyBackIndicatorEnum.ReplacementShares,
        washSaleAdjustmentAmount: washSaleAdjustment,
      };

      createLots.push(replacementLot);
    } else if (washSaleCandidate.quantity.abs().greaterThan(qtySelected)) {
      // Split the wash sale candidate
      const adjustedWashSaleCandidate = _splitLot(
        washSaleCandidate,
        qtySelected.mul(signMultiplier)
      );
      const nonWashSaleLot = _splitLot(
        washSaleCandidate,
        washSaleCandidate.quantity.abs().sub(qtySelected).mul(signMultiplier)
      );

      // mark the lot as wash sale
      const washSaleLot: TaxLot = {
        ...adjustedWashSaleCandidate,
        closedLotId: uniqueId(16), // generate new id for wash sale lot (remains same for non-wash sale)
        washSalesIndicator: WashSaleIndicatorEnum.WashSale,
        washSalesDisallowed: adjustedWashSaleCandidate.realizedGainLoss.abs(),
      };
      createLots.push(washSaleLot);
      createLots.push(nonWashSaleLot);

      // Now add the replacement lot
      const adjustedBuyCandidate = _splitLot(
        buyCandidate,
        qtySelected.mul(signMultiplier)
      );
      const washSaleAdjustment = washSaleLot.washSalesDisallowed;
      const costBasis = adjustedBuyCandidate.cost.add(washSaleAdjustment);

      const replacementLot = {
        ...adjustedBuyCandidate,
        openLotId: uniqueId(16),
        netProceed: costBasis, // tricky since this should use actual share price
        cost: costBasis,
        buyBackIndicator: BuyBackIndicatorEnum.ReplacementShares,
        washSaleAdjustmentAmount: washSaleAdjustment,
      };

      createLots.push(replacementLot);
    }

    remainingQty = remainingQty.minus(qtySelected);
  }

  // add the original unrealized lot
  if (remainingQty.greaterThan(0)) {
    const adjustedBuyCandidate = _splitLot(
      buyCandidate,
      remainingQty.mul(signMultiplier)
    );
    createLots.push(adjustedBuyCandidate);
  }

  // update eventTime for all created lots
  const createLotsWithUpdatedEventTime = createLots.map((lot) => ({
    ...lot,
    eventTime: buyCandidate.eventTime,
  }));

  return {
    createLots: createLotsWithUpdatedEventTime,
    deleteLotIds,
  };
}

function processWashSaleCandidates(
  washSaleCandidatesToProcess: TaxLot[],
  replacementCandidatesToProcess: TaxLotWithId[]
): TaxLotResult {
  const createLots: TaxLot[] = [];
  const deleteLotIds: string[] = [];
  let globalReplacementCandidates = [...replacementCandidatesToProcess];
  const updatedUnrealizedMap = new Map<string, TaxLotWithId>();

  // Allocate replacement lots for each wash sale candidate
  washSaleCandidatesToProcess.forEach((washSaleLot) => {
    // filter out sibling lots
    const eligibleReplacements = globalReplacementCandidates
      .filter(
        (candidate) =>
          candidate.openTransactionId !== washSaleLot.openTransactionId
      )
      .sort(buyDateEventTimeCompareTo);

    const filteredCandidates = globalReplacementCandidates.filter(
      (candidate) =>
        candidate.openTransactionId === washSaleLot.openTransactionId
    );

    const allocatedResult = _allocateReplacementCandidateToWashSales(
      [washSaleLot],
      eligibleReplacements
    );

    // Remove the lots that have been used as part of the current run
    allocatedResult.deleteLotIds.forEach((usedLotIds) => {
      updatedUnrealizedMap.delete(usedLotIds);
    });
    // Since the unrealized lot can be considered replacement candidate multiple times, we only
    // care about maintaining information about the final lot that's left over
    allocatedResult.updatedUnrealizedLots.forEach((usedUnrealizedLot) => {
      updatedUnrealizedMap.set(usedUnrealizedLot.id, usedUnrealizedLot);
    });

    createLots.push(...allocatedResult.createLots);
    deleteLotIds.push(...allocatedResult.deleteLotIds);
    // Updated replacement lots
    globalReplacementCandidates = [
      ...allocatedResult.updatedUnrealizedLots,
      // add back the filtered lot for next wash sale candidate
      ...filteredCandidates,
    ];
  });

  // Add back any updated unrealized lots
  updatedUnrealizedMap.forEach((lot, lotId) => {
    createLots.push(lot);
    // also update the deletes
    deleteLotIds.push(lotId);
  });

  return {
    createLots,
    deleteLotIds,
  };
}

function _groupLots(lots: TaxLot[]): TaxLot[] {
  const groupedCreateLots = Object.entries(
    groupBy(lots, (lot) =>
      safeStringify({
        openTransactionId: lot.openTransactionId,
        closedTransactionId: lot.closedTransactionId,
        bDate: lot.taxLotOpenBuyDate,
        sDate: lot.taxLotCloseSellDate,
        price: lot.openBuyPrice,
        r: lot.realizedIndicator,
        b: lot.buyBackIndicator,
      })
    )
  );

  return groupedCreateLots.map(([, lots]) =>
    lots.reduce((prev, cur) => ({
      ...prev,
      openBuyCostAmount: prev.openBuyCostAmount.add(cur.openBuyCostAmount),
      quantity: prev.quantity.add(cur.quantity),
      cost: prev.cost.add(cur.cost),
      netProceed: prev.netProceed.add(cur.netProceed),
      realizedGainLoss: prev.realizedGainLoss.add(cur.realizedGainLoss),
      washSalesDisallowed: prev.washSalesDisallowed.add(
        cur.washSalesDisallowed
      ),
      washSaleAdjustmentAmount: prev.washSaleAdjustmentAmount.add(
        cur.washSaleAdjustmentAmount
      ),
    }))
  );
}

/**
 * Three cases possible:
 * - WashSale qty > replacement qty: split the wash sale lot, and perform wash sale on replacement qty lot.
 *  The pending lot will be processed next.
 * - replacement qty > wash sale qty: Split the replacement lot, and perform wash sale on wash sale qty.
 * The pending lot can be considered for subsequent wash sales
 *  - replacement qty =  wash sale qty: process the wash sale.
 */
function _allocateReplacementCandidateToWashSales(
  washSaleCandidatesToProcess: TaxLot[],
  replacementCandidatesToProcess: TaxLotWithId[]
): TaxLotResultWithMetadata {
  const createLots: TaxLot[] = [];
  const unrealizedLots: TaxLotWithId[] = [];
  const replacementCandidatesUsed = new Set<string>();
  const washSaleCandidates = [...washSaleCandidatesToProcess];
  const replacementCandidates = [...replacementCandidatesToProcess];

  while (
    washSaleCandidates.length !== 0 &&
    replacementCandidates.length !== 0
  ) {
    let washSaleCandidate = washSaleCandidates.shift() as TaxLot;
    let replacementCandidate = replacementCandidates.shift() as TaxLotWithId;
    replacementCandidatesUsed.add(replacementCandidate.id);

    const qtySelected = Decimal.min(
      washSaleCandidate.quantity,
      replacementCandidate.quantity
    );

    if (washSaleCandidate.quantity.greaterThan(replacementCandidate.quantity)) {
      // split the wash sale candidate
      const adjustedWashSaleCandidate = _splitLot(
        washSaleCandidate,
        qtySelected
      );
      // get the remaining candidate
      const remainingWashSaleCandidate = _splitLot(
        washSaleCandidate,
        washSaleCandidate.quantity.sub(qtySelected)
      );

      // add the unprocessed wash sale candidate
      washSaleCandidates.unshift(remainingWashSaleCandidate);
      // update the current wash sale candidate
      washSaleCandidate = {
        ...adjustedWashSaleCandidate,
      };
    } else if (
      washSaleCandidate.quantity.lessThan(replacementCandidate.quantity)
    ) {
      // split the replacement candidate
      const adjustedReplacementCandidate: TaxLotWithId = {
        ..._splitLot(replacementCandidate, qtySelected),
      };
      // get the remaining candidate
      const remainingReplacementCandidate = _splitLot(
        replacementCandidate,
        replacementCandidate.quantity.sub(qtySelected)
      );

      // add the unprocessed replacement candidate
      replacementCandidates.unshift({
        ...remainingReplacementCandidate,
      });
      // update the current replacement candidate
      replacementCandidate = {
        ...adjustedReplacementCandidate,
      };
    }
    // Now the wash sale candidate and replace candidate are of equal qty
    const washSaleLot: TaxLot = {
      ...washSaleCandidate,
      washSalesIndicator: WashSaleIndicatorEnum.WashSale,
      washSalesDisallowed: washSaleCandidate.realizedGainLoss.abs(),
    };

    const replacementLot: TaxLot = {
      ...replacementCandidate,
      openLotId: uniqueId(16), // update the lot id as soon as it's marked as replacement
      buyBackIndicator: BuyBackIndicatorEnum.ReplacementShares,
      washSaleAdjustmentAmount: washSaleLot.washSalesDisallowed,
      cost: replacementCandidate.cost.add(washSaleLot.washSalesDisallowed),
    };

    createLots.push(washSaleLot);
    createLots.push(replacementLot);
  }
  // Add remaining wash sale candidates
  washSaleCandidates.forEach((lot) => createLots.push(lot));

  // replacement candidate might have a final pending unadjusted candidate that needs to be updated in case it was split
  replacementCandidates
    // Only retain unrealized candidates have been used as replacements
    .filter((lot) =>
      replacementCandidatesUsed.has(lot.id) ? lot.quantity.gt(0) : false
    )
    .forEach((lot) => {
      unrealizedLots.push(lot);
    });

  const groupedResults = _groupLots(createLots);

  return {
    createLots: groupedResults,
    deleteLotIds: [...replacementCandidatesUsed],
    updatedUnrealizedLots: unrealizedLots,
  };
}

/**
 * A lot is considered a wash sale if it was a realized loss and not already marked as wash sale
 * @VisibleForTesting
 */
export function _washSaleCandidateFilter<
  T extends {
    realizedIndicator: RealizedIndicatorEnum;
    washSalesIndicator: WashSaleIndicatorEnum;
    realizedGainLoss: Decimal;
  }
>(taxLot: T): boolean {
  return (
    taxLot.realizedIndicator === RealizedIndicatorEnum.Realized &&
    taxLot.washSalesIndicator !== WashSaleIndicatorEnum.WashSale &&
    taxLot.realizedGainLoss.lessThan(0)
  );
}

/**
 * Helper function to sort tax lots based on highest avg cost basis
 */
export function avgCostBasisCompareTo<
  T extends { cost: Decimal; quantity: Decimal }
>(lot1: T, lot2: T): number {
  const avgCostBasis1 = lot1.cost.div(lot1.quantity);
  const avgCostBasis2 = lot2.cost.div(lot2.quantity);
  // Reverse order
  return avgCostBasis2.cmp(avgCostBasis1);
}

/**
 * Alternative to avgCostBasisCompareTo but used to weight ST and LT capital
 * gains differently.
 */
export function getMinTaxCompareTo<
  T extends { cost: Decimal; quantity: Decimal; taxLotOpenBuyDate?: DateOnly }
>(
  price: Decimal,
  asOfDate: DateOnly,
  shortTermPenalty: Decimal
): (lot1: T, lot2: T) => number {
  return (lot1: T, lot2: T) =>
    _minTaxCompareTo(lot1, lot2, price, asOfDate, shortTermPenalty);
}

// For direct indexing, keep in sync with forecaster/services/direct_indexing/tlh_optimizer.py#penalty_function
function _minTaxCompareTo<
  T extends { cost: Decimal; quantity: Decimal; taxLotOpenBuyDate?: DateOnly }
>(
  lot1: T,
  lot2: T,
  price: Decimal,
  asOfDate: DateOnly,
  shortTermPenalty: Decimal
): number {
  const penaltyFunction = (lot: T) => {
    let penalty = ONE;
    if (
      lot.taxLotOpenBuyDate &&
      // lot is at a gain
      lot.cost.div(lot.quantity).lessThan(price) &&
      // and is short term (+1 day)
      lot.taxLotOpenBuyDate.isWithin(asOfDate, 366, "days")
    ) {
      // then use the ST penalty (e.g. 2.0)
      penalty = shortTermPenalty;
    }
    return lot.cost.div(lot.quantity).sub(price).mul(penalty);
  };

  const penalty1 = penaltyFunction(lot1);
  const penalty2 = penaltyFunction(lot2);

  // sort by penalty DESCENDING
  return penalty2.cmp(penalty1);
}

/**
 * Helper function to sort tax lots based on sell date, then buy date and finally sequence number
 * @VisibleForTesting
 */
export function _sellDateBuyDateEventTimeCompareTo<
  T extends {
    taxLotCloseSellDate?: DateOnly;
    taxLotOpenBuyDate?: DateOnly;
    eventTime: Date;
  }
>(lot1: T, lot2: T): number {
  if (
    lot1.taxLotCloseSellDate &&
    lot2.taxLotCloseSellDate &&
    lot1.taxLotCloseSellDate.valueOf() !== lot2.taxLotCloseSellDate.valueOf()
  ) {
    return DateOnly.compare(lot1.taxLotCloseSellDate, lot2.taxLotCloseSellDate);
  }

  return buyDateEventTimeCompareTo(lot1, lot2);
}

/**
 * Extracts lot id and quantity from lot matching instructions
 */
function getLotMatchingCandidatesToRealize(
  lotMatchingArgs: LotMatchingInstructionsInputArgs[]
): LotIdToBeSold[] {
  return lotMatchingArgs.map((lot) => {
    return {
      taxLotEntryId: lot.taxLotEntryId,
      sellQuantity: lot.quantity,
    };
  });
}

/**
 * Helper method to identify wash sale lots or replacement lots.
 * We first look for similar security in last 30 days from @orderLotInput.eventTime.
 * After applying @filterFn and @sortFn, we look for lots till we satisfy the quantity
 * @orderLotInput.quantity
 */
function washSaleOrReplacementLot<
  O extends { securityId: string; quantity: Decimal; eventTime: Date },
  T extends {
    securityId: string;
    quantity: Decimal;
    realizedIndicator: RealizedIndicatorEnum;
    taxLotOpenBuyDate?: DateOnly;
    taxLotCloseSellDate?: DateOnly;
  }
>(
  orderLotInput: O,
  currentTaxLots: T[],
  filterFn: (lot: T) => boolean,
  sortFn: (lot1: T, lot2: T) => number,
  isReplacementCheck: boolean
): T[] {
  const orderDateMinus30Days = DateOnly.fromDateTz(
    orderLotInput.eventTime,
    EXCHANGE_TIMEZONE
  ).addDays(-30);

  // Find all lots from last 30 days
  const lotsFromLast30Days = currentTaxLots
    .filter(
      (taxLot) =>
        isSimilarSecurity({ securityId: orderLotInput.securityId }, taxLot) &&
        isTransactionAfterThreshold(orderDateMinus30Days, taxLot) &&
        filterFn(taxLot)
    )
    .sort(sortFn);

  // We return all possible candidates for replacement, since some might get filtered and be ineligible for replacement
  if (isReplacementCheck) {
    return lotsFromLast30Days;
  }
  const result: T[] = [];

  let remainingQty = orderLotInput.quantity;
  while (remainingQty.greaterThan(0) && lotsFromLast30Days.length !== 0) {
    const replacementLot = lotsFromLast30Days.shift() as T;
    // deduct the maximum we can deduct from the lot
    const qtySelected = Decimal.min(
      replacementLot.quantity.abs(),
      remainingQty
    );
    result.push(replacementLot);
    remainingQty = remainingQty.minus(qtySelected);
  }
  return result;
}

/**
 * Helper method to find if transactions occurred post a threshold date as a best effort
 * (false if date is unknown)
 */
function isTransactionAfterThreshold<
  T extends {
    realizedIndicator: RealizedIndicatorEnum;
    taxLotOpenBuyDate?: DateOnly;
    taxLotCloseSellDate?: DateOnly;
  }
>(thresholdDate: DateOnly, taxLot: T) {
  const transactionDate =
    taxLot.realizedIndicator === RealizedIndicatorEnum.Unrealized
      ? taxLot.taxLotOpenBuyDate
      : taxLot.taxLotCloseSellDate;

  return transactionDate
    ? transactionDate.valueOf() >= thresholdDate.valueOf()
    : false;
}

/**
 * Helper function to sort tax lots based on buy date and then sequence number
 */
function buyDateEventTimeCompareTo<
  T extends { taxLotOpenBuyDate?: DateOnly; eventTime: Date }
>(lot1: T, lot2: T): number {
  if (
    lot1.taxLotOpenBuyDate &&
    lot2.taxLotOpenBuyDate &&
    lot1.taxLotOpenBuyDate.valueOf() !== lot2.taxLotOpenBuyDate.valueOf()
  ) {
    return DateOnly.compare(lot1.taxLotOpenBuyDate, lot2.taxLotOpenBuyDate);
  } else {
    return dateCompare(lot1.eventTime, lot2.eventTime);
  }
}

/**
 * Helper function to find similar securities for wash sale. For now we do a strict check on security id.
 */
function isSimilarSecurity<T extends { securityId: string }>(
  orderInput: { securityId: string },
  taxLot: T
) {
  return (
    substantiallyIdenticalId(orderInput.securityId) ===
    substantiallyIdenticalId(taxLot.securityId)
  );
}

/**
 * Helper function to generate new tax lots for a long buy or short sell event.
 */
function processTaxLotEntryForUnrealizedLot(
  orderLotInput: OrderLotInputArgs,
  currentTaxLots: TaxLotWithId[]
): TaxLotResult {
  // We assume this will not trigger wash sale initially
  const buyCandidate: TaxLot = {
    subAccountId: orderLotInput.subAccountId,
    securityId: orderLotInput.securityId,
    eventTime: orderLotInput.eventTime,
    // Note: Changing how this id is generated affects which lots can be moved
    // to DI
    openTransactionId: `${DateOnly.fromDateUTC(
      orderLotInput.eventTime
    ).toYYYYMMDDFormat()}_${uniqueId(16)}`,
    openLotId: uniqueId(16), // generating a temporary id to help with intra day sells
    quantity:
      orderLotInput.positionType === OrderPositionType.Short
        ? orderLotInput.quantity.neg()
        : orderLotInput.quantity,
    cost: orderLotInput.notional.minus(orderLotInput.orderFees ?? 0),
    openBuyPrice: orderLotInput.sharePrice,
    openBuyCostAmount: orderLotInput.notional.minus(
      orderLotInput.orderFees ?? 0
    ),
    netProceed: orderLotInput.notional,
    realizedGainLoss: ZERO,
    longTermShortTermIndicator: LongTermShortTermIndicatorEnum.Short,
    realizedIndicator: RealizedIndicatorEnum.Unrealized,
    washSalesIndicator: WashSaleIndicatorEnum.None,
    washSalesDisallowed: ZERO,
    washSaleAdjustmentAmount: ZERO,
    buyBackIndicator: BuyBackIndicatorEnum.None,
    taxLotOpenBuyDate: DateOnly.fromDateUTC(orderLotInput.eventTime),
    // no need to pass settlementDate or taxLotCloseDate for unrealized
  };

  // Get washSale candidates.
  const washSaleCandidates: TaxLotWithId[] = _findWashSaleCandidates(
    orderLotInput,
    currentTaxLots
  );

  return processWashSaleCandidatesForUnrealizedLots(
    buyCandidate,
    washSaleCandidates
  );
}

export function getFifoCandidatesToRealize<
  O extends { subAccountId: string; securityId: string; quantity: Decimal },
  T extends {
    id: string;
    securityId: string;
    subAccountId: string;
    quantity: Decimal;
    cost: Decimal;
    realizedIndicator: RealizedIndicatorEnum;
    taxLotOpenBuyDate?: DateOnly;
    eventTime: Date;
  }
>(orderLotInput: O, currentTaxLots: T[]): LotIdToBeSold[] {
  return getUnrealizedCandidates(
    orderLotInput,
    currentTaxLots,
    buyDateEventTimeCompareTo
  );
}

/**
 * Helper function to generate tax lots for a sell long or buy short event.
 */
function processTaxLotEntryForRealizedLot(
  orderLotInput: OrderLotInputArgs,
  currentTaxLots: TaxLotWithId[]
): TaxLotResult {
  const candidateToBeSold =
    orderLotInput.lotMatchingInstructions.length > 0
      ? getLotMatchingCandidatesToRealize(orderLotInput.lotMatchingInstructions)
      : // FIFO: sort in ascending order based on (taxLotOpenBuyDate, eventTime)
        getFifoCandidatesToRealize(orderLotInput, currentTaxLots);

  const taxLotLookUp = new Map(
    currentTaxLots.map((taxLot) => [taxLot.id, taxLot])
  );

  const lotsToBeSold: LotToBeSold[] = candidateToBeSold.map((lot) => {
    return {
      taxLot: taxLotLookUp.get(lot.taxLotEntryId) as TaxLotWithId,
      sellQuantity: lot.sellQuantity,
    };
  });

  // For sell events, we have to take fees into consideration
  const sharePrice = orderLotInput.notional
    .minus(orderLotInput.orderFees ?? ZERO)
    .div(orderLotInput.quantity)
    .toDecimalPlaces(5);

  const preWashSaleResult = _processCandidatesToRealize(
    {
      sellPrice: sharePrice,
      eventTime: orderLotInput.eventTime,
    },
    lotsToBeSold
  );

  // Apply the pre wash sale result on the current lots
  preWashSaleResult.deleteLotIds.forEach((lotId) => taxLotLookUp.delete(lotId));
  preWashSaleResult.updatedUnrealizedLots.forEach((lot) => {
    taxLotLookUp.set(lot.id, lot);
  });

  // Get wash sale candidates
  const washSaleCandidates = preWashSaleResult.createLots.filter(
    _washSaleCandidateFilter
  );

  const updatedLots = Array.from(taxLotLookUp.values());

  // find replacement lot for each wash sale candidate
  const globalReplacementCandidates = _findReplacementLots(
    orderLotInput,
    updatedLots
  );

  // If no wash sale candidate or there are no replacement candidates, no wash sale update required
  if (
    washSaleCandidates.length === 0 ||
    globalReplacementCandidates.length === 0
  ) {
    return {
      createLots: preWashSaleResult.createLots,
      deleteLotIds: preWashSaleResult.deleteLotIds,
    };
  }

  const washSaleResult = processWashSaleCandidates(
    washSaleCandidates,
    globalReplacementCandidates
  );

  const nonWashSaleCandidates = preWashSaleResult.createLots.filter(
    (lot) =>
      lot.realizedIndicator === RealizedIndicatorEnum.Realized &&
      lot.realizedGainLoss.gte(0)
  );

  const unrealizedPreWashSaleLots =
    preWashSaleResult.updatedUnrealizedLots.filter(
      (lot) => !washSaleResult.deleteLotIds.includes(lot.id)
    );

  // Combine the result
  const deleteLotIds: string[] = Array.from(
    new Set([...preWashSaleResult.deleteLotIds, ...washSaleResult.deleteLotIds])
  );

  const createLots = [
    ...unrealizedPreWashSaleLots,
    ...nonWashSaleCandidates,
    ...washSaleResult.createLots,
  ];

  // update eventTime for all created lots
  const createLotsWithUpdatedEventTime = createLots.map((lot) => ({
    ...lot,
    eventTime: orderLotInput.eventTime,
  }));

  return {
    createLots: createLotsWithUpdatedEventTime,
    deleteLotIds,
  };
}

/**
 * Helper function to generate new tax lots for a sell event from an existing unrealized lot.
 * We first add the updated unrealized lot (if any qty remaining), and then add
 * the realized sold tax lot
 */
function sellPartOfUnrealizedLot(
  orderLot: {
    quantityToBeSold: Decimal;
    sellPrice: Decimal;
    eventTime: Date;
  },
  unrealizedLotToBeSold: TaxLotWithId
): SellLotResult {
  const remainingUnrealizedLotQty = unrealizedLotToBeSold.quantity
    .abs()
    .sub(orderLot.quantityToBeSold);

  let remainingUnrealizedLot;
  // We add the updated unrealized lot, if the updatedQty > 0
  if (remainingUnrealizedLotQty.greaterThan(0)) {
    remainingUnrealizedLot = _splitLot(
      unrealizedLotToBeSold,
      remainingUnrealizedLotQty
    );
  }

  const adjustedUnrealizedLot = _splitLot(
    unrealizedLotToBeSold,
    orderLot.quantityToBeSold
  );

  // add the realized lot
  const originalProceed = adjustedUnrealizedLot.quantity.lessThan(0)
    ? adjustedUnrealizedLot.cost
    : orderLot.quantityToBeSold.mul(orderLot.sellPrice);
  const originalCost = adjustedUnrealizedLot.quantity.lessThan(0)
    ? orderLot.quantityToBeSold.mul(orderLot.sellPrice)
    : adjustedUnrealizedLot.cost;

  const realizedGainLoss = originalProceed
    .minus(originalCost)
    .toDecimalPlaces(2);
  const heldDays = unrealizedLotToBeSold.taxLotOpenBuyDate
    ? DateOnly.daysInBetween(
        DateOnly.fromDateUTC(orderLot.eventTime),
        unrealizedLotToBeSold.taxLotOpenBuyDate
      )
    : -1; // Should not happen

  const realizedLot = {
    ...adjustedUnrealizedLot,
    id: undefined,
    realizedIndicator: RealizedIndicatorEnum.Realized,
    taxLotCloseSellDate: DateOnly.fromDateUTC(orderLot.eventTime),
    settlementDate: DateOnly.fromDateUTC(orderLot.eventTime),
    closedTransactionId: `${DateOnly.fromDateUTC(
      orderLot.eventTime
    ).toYYYYMMDDFormat()}_${uniqueId(16)}`,
    closedLotId: uniqueId(16),
    eventTime: orderLot.eventTime,
    cost: originalCost,
    // realized lots for short orders have negative proceeds for some reason
    netProceed: adjustedUnrealizedLot.quantity.lessThan(0)
      ? originalProceed.toDecimalPlaces(2).neg()
      : originalProceed.toDecimalPlaces(2),
    realizedGainLoss: realizedGainLoss,
    longTermShortTermIndicator:
      heldDays > 365
        ? LongTermShortTermIndicatorEnum.Long
        : LongTermShortTermIndicatorEnum.Short, // Note: possible edge cases handling
    washSaleAdjustmentAmount: ZERO, // reset unrealized values
    washSalesIndicator: WashSaleIndicatorEnum.None, // Pre-wash sale check
    washSalesDisallowed: ZERO, // Pre-wash sale check
  };

  return {
    remainingUnrealizedLot,
    realizedLot,
  };
}

export const aggregateLots = (
  lots: TaxLot[]
): {
  securityId: string;
  quantity: Decimal;
  cost: Decimal;
  lastActivityDate: Date;
}[] => {
  const holdingMap = new Map<
    string,
    {
      quantity: Decimal;
      cost: Decimal;
      lastActivityDate: Date;
    }
  >();

  lots.forEach((lot) => {
    if (lot.realizedIndicator === RealizedIndicatorEnum.Realized) {
      return;
    }
    const holding = holdingMap.get(lot.securityId);
    if (holding) {
      holding.quantity = holding.quantity.plus(lot.quantity);
      holding.cost = holding.cost.plus(lot.cost);
      // most recent date
      holding.lastActivityDate =
        dateCompare(lot.eventTime, holding.lastActivityDate) > 0
          ? lot.eventTime
          : holding.lastActivityDate;
      holdingMap.set(lot.securityId, holding);
    } else {
      holdingMap.set(lot.securityId, {
        quantity: lot.quantity,
        cost: lot.cost,
        lastActivityDate: lot.eventTime,
      });
    }
  });

  const holdings: {
    securityId: string;
    quantity: Decimal;
    cost: Decimal;
    lastActivityDate: Date;
  }[] = [];
  holdingMap.forEach((k, v) => {
    holdings.push({
      securityId: v,
      quantity: k.quantity,
      cost: k.cost,
      lastActivityDate: k.lastActivityDate,
    });
  });

  return holdings;
};

export const getTaxLotDate = (taxLot: TaxLot): DateOnly => {
  return get(
    taxLot.realizedIndicator === RealizedIndicatorEnum.Unrealized
      ? taxLot.taxLotOpenBuyDate
      : taxLot.taxLotCloseSellDate
  );
};

export const getLotAvgCost = (taxLot: TaxLot): Decimal => {
  return taxLot.quantity.gt(0) ? taxLot.cost.div(taxLot.quantity) : ZERO;
};

// Utility method for summing up realized lot info, like gains, losses, etc for
// long and short lots
export const computeTotalsForLots = (
  taxLots: TaxLot[]
): RealizedLotsSummary => {
  const realizedLots = taxLots.filter(
    (t) => t.realizedIndicator === RealizedIndicatorEnum.Realized
  );
  const [shortTermLots, longTermLots] = partition(
    realizedLots,
    (t) => t.longTermShortTermIndicator === LongTermShortTermIndicatorEnum.Short
  );
  const reduceFn = (
    acc: {
      cost: Decimal;
      netProceed: Decimal;
      gains: Decimal;
      losses: Decimal;
      washSalesDisallowed: Decimal;
    },
    l: TaxLot
  ) => ({
    cost: acc.cost.add(l.cost),
    netProceed: acc.netProceed.add(l.netProceed),
    gains: acc.gains.add(l.realizedGainLoss.gt(0) ? l.realizedGainLoss : ZERO),
    losses: acc.losses.add(
      l.realizedGainLoss.lt(0) ? l.realizedGainLoss.abs() : ZERO
    ),
    washSalesDisallowed: acc.washSalesDisallowed.add(l.washSalesDisallowed),
  });
  const blankTotals = {
    cost: ZERO,
    netProceed: ZERO,
    gains: ZERO,
    losses: ZERO,
    washSalesDisallowed: ZERO,
  };
  const shortTerm = shortTermLots.reduce(reduceFn, blankTotals);
  const longTerm = longTermLots.reduce(reduceFn, blankTotals);

  return {
    shortTermGains: shortTerm.gains,
    longTermGains: longTerm.gains,
    shortTermLosses: shortTerm.losses,
    longTermLosses: longTerm.losses,
    shortNetProceeds: shortTerm.netProceed,
    longNetProceeds: longTerm.netProceed,
    shortCostBasis: shortTerm.cost,
    longCostBasis: longTerm.cost,
    shortWashSalesDisallowed: shortTerm.washSalesDisallowed,
    longWashSalesDisallowed: longTerm.washSalesDisallowed,
  };
};

/**
 * Calculates short term and long term gains for the given tax lots.
 * NOTE: Gains include wash sales.
 */
export const computeGainsForLots = (taxLots: TaxLotWithId[]) => {
  const totals = computeTotalsForLots(taxLots);
  const shortTermGains = totals.shortTermGains
    .minus(totals.shortTermLosses)
    .plus(totals.shortWashSalesDisallowed);
  const longTermGains = totals.longTermGains
    .minus(totals.longTermLosses)
    .plus(totals.longWashSalesDisallowed);
  return {
    shortTermGains,
    longTermGains,
    shortTermWashSales: totals.shortWashSalesDisallowed,
    longTermWashSales: totals.longWashSalesDisallowed,
  };
};

// https://stockmarketmba.com/companieswithmultipleclassesofstocks.php
// TODO: EFTs like VOO/SPY
const SUBSTANTIALLY_IDENTICAL_MAPPING: { [key: string]: string } = {
  "2b4be058-3e88-4696-af57-1d6079bb62a5":
    "ae8f76ff-731e-4ffd-bbb0-330503312e88", // GOOGL -> GOOG
  "df295c9e-0605-4f16-a68a-b84d8e5b8c27":
    "055ac2f2-ed40-4a3b-8e02-f8963b48e6f3", // BF.B -> BF.A
  "89120e0e-a140-4431-8a97-88a85fb86548":
    "f4cf76d5-5a4c-4258-ae2a-d1d003227e9d", // BATRK -> BATRA
  "7c4c17f6-411c-4097-8dab-fe1fd2cf4859":
    "d3390389-3a2b-4dfb-b4dc-f8fa3a69dbe3", // CENTA -> CENT
  "830f5fe5-1dca-4291-a200-6fe8b85056bf":
    "cb878e21-24ed-4f70-b7b7-b39e4518b177", // CWEN.A -> CWEN
  "6a165568-f48f-477e-854f-00a77a08c93b":
    "39f122bc-5b89-4bf2-9c77-e66dbb61f714", // FOX.A -> FOX
  "06e3ef15-7f99-404c-9be2-d5a16a6e11a8":
    "5f786114-e8cc-4b0f-b8ba-aaebbf576586", // FWONA -> FWONK
  "716d0575-f688-423d-b06f-24d477414d1e":
    "a5f61934-cbfc-4afa-b2c0-4e0a9acf8bdb", // HEI.A -> HEI
  "56c9dc71-0494-4af0-9f40-327e82552991":
    "10a4fe94-ef29-4f88-994a-e9e929ec01f4", // LBRDA -> LBRDK
  "0b5bdeb6-0cd8-4bcb-aada-f8eb316d6d9a":
    "5bda7055-f213-4f8e-903b-26089f209257", // LBTYA -> LBTYK
  "2b636f81-9f28-462a-84fb-b08f45c4fc6c":
    "a58ab508-e1cf-43d5-8e5f-946080add8bf", // LGF.A -> LGF.B
  "4e84a4ad-7acc-4ab5-852e-49c354df1ab9":
    "2017fdc2-9ccf-41d7-8552-7f359b2db401", // LILA -> LILAK
  "06761c96-59fa-4bb3-aef0-0478a0acd981":
    "9fa11a6d-01fd-483f-a659-4272c02e473d", // LSXMA -> LSXMK
  "00740d3c-d21f-4d6a-a4e2-61113a9ebe92":
    "6fd598ee-7a9a-4b60-9de5-e07f381aec7a", // MOG.A -> MOG.B
  "45f71828-1b09-4a38-aa16-5c82e931c86e":
    "5e3da58c-1c18-4af8-b7c2-af645c2229b7", // NWSA -> NWS
  "2b9bd4da-a7c9-4354-a72e-c56738ca35ae":
    "83ed9741-8c5f-4e77-afc6-d0a7bac5ccc8", // UAA -> UA
};

export const substantiallyIdenticalId = (securityId: string) => {
  return SUBSTANTIALLY_IDENTICAL_MAPPING[securityId] ?? securityId;
};
