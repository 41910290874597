import { DateTime } from "luxon";

import { DateOnly } from "./DateOnly";

export * from "./constants";
export * from "./DateOnly";
export * from "./utils";

export enum DateFormatPreset {
  TIME, // e.g. 9:30 AM
  TIME_TZ, // e.g. 9:30 AM EDT
  DATE_SHORT, // e.g. 01/01/2023
  DATE, // e.g. Jan 1, 2023
  DATE_MONTH, // e.g. Jan 1
  DATE_FULL_MONTH, // e.g. January 1,
  DATE_FULL_MONTH_YEAR, // e.g. January 1, 2023
  DATE_FULL, // e.g. January 1st, 2023
  DATE_TIME, // e.g. Jan 1, 2023, 12:00 PM
  MONTH_YEAR, // e.g. 1/1
  YEAR, // e.g. 2023
  WEEK_GRAPH_TICKER, // e.g. Wed 09:30
  WEEK_MONTH, // Friday, August 16
}

export const FORMATS: Record<DateFormatPreset, Intl.DateTimeFormatOptions> = {
  [DateFormatPreset.TIME]: DateTime.TIME_SIMPLE,
  [DateFormatPreset.TIME_TZ]: {
    ...DateTime.TIME_SIMPLE,
    timeZoneName: "short",
  },
  [DateFormatPreset.DATE_SHORT]: DateTime.DATE_SHORT,
  [DateFormatPreset.DATE]: DateTime.DATE_MED,
  [DateFormatPreset.DATE_MONTH]: {
    month: "short",
    day: "numeric",
  },
  [DateFormatPreset.DATE_FULL]: DateTime.DATE_FULL,
  [DateFormatPreset.DATE_FULL_MONTH]: {
    month: "long",
    day: "numeric",
  },
  [DateFormatPreset.DATE_FULL_MONTH_YEAR]: {
    month: "long",
    day: "numeric",
    year: "numeric",
  },
  [DateFormatPreset.DATE_TIME]: DateTime.DATETIME_MED,
  [DateFormatPreset.MONTH_YEAR]: {
    month: "long",
    year: "numeric",
  },
  [DateFormatPreset.YEAR]: {
    year: "numeric",
  },
  [DateFormatPreset.WEEK_GRAPH_TICKER]: {
    weekday: "short",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  },
  [DateFormatPreset.WEEK_MONTH]: {
    weekday: "long",
    month: "long",
    day: "numeric",
  },
};

export const formatDateWithPreset = (
  date: string | Date | DateOnly,
  format: DateFormatPreset,
  options: { timeZone?: string } = {}
) => {
  const luxonDate =
    typeof date === "string"
      ? DateTime.fromISO(date, { zone: options.timeZone })
      : DateTime.fromJSDate(
          date instanceof DateOnly ? date.toDateStartOfDay("local") : date
        ).setZone(options.timeZone);
  return luxonDate.toLocaleString(FORMATS[format]);
};

export const formatDate = (
  date: string | Date,
  format = "yyyy-MM-dd",
  options: { timeZone?: string } = {}
) => {
  // Expects a date string in the format YYYY-MM-DD
  const luxonDate =
    typeof date === "string"
      ? DateTime.fromISO(date, { zone: options.timeZone })
      : DateTime.fromJSDate(date).setZone(options.timeZone);
  return luxonDate.toFormat(format);
};

export const parseDate = (dateStr: string, format: string): Date => {
  return DateTime.fromFormat(dateStr, format).toJSDate();
};
