import { mergeClassNames } from "./classNames";

export enum AlertPreset {
  Default,
  Warning,
  Error,
  InlineError,
}

// Mantine CSS overrides
const CommonStyles = {
  icon: "h-5 w-5 mr-2",
  root: "p-3 rounded-lg border-0",
  title: "text-regular16",
  message: "text-book14 w-full",
  body: "flex items-center",
};

const DefaultStyles = mergeClassNames(CommonStyles, {
  icon: "text-frecDarkGray",
  root: "bg-frecXLightGray",
  title: "text-frecBlack",
  message: "text-frecBlack",
  body: "",
});

const WarningStyles = mergeClassNames(CommonStyles, {
  icon: "text-frecYellow dark:text-frecNearBlack",
  root: "bg-frecLightYellow dark:bg-frecDarkYellow",
  title: "text-frecNearBlack",
  message: "text-frecNearBlack",
  body: "",
});

const ErrorStyles = mergeClassNames(CommonStyles, {
  icon: "text-frecDarkRed dark:text-frecNearBlack",
  root: "bg-frecLightRed dark:bg-frecDarkRed",
  title: "text-frecNearBlack",
  message: "text-frecNearBlack",
  body: "",
});

const InlineErrorStyles = mergeClassNames(CommonStyles, {
  icon: "text-frecDarkRed",
  root: "!p-0 bg-transparent",
  title: "text-frecDarkRed",
  message: "text-frecDarkRed",
  body: "",
});

export const AlertStyle = {
  CommonStyles,
  DefaultStyles,
  WarningStyles,
  ErrorStyles,
  InlineErrorStyles,
};
