import { useRouter } from "next/router";
import { useEffect } from "react";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

export const HubspotTracker = () => {
  const router = useRouter();
  const { setPathPageView } = useTrackingCode(); // Hubspot tracking

  useEffect(() => {
    // Initial page view
    setPathPageView(router.asPath);

    const handleRouteChange = (url: string) => {
      setPathPageView(url);
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return null;
};
