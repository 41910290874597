import { windowIfAvailable } from "./windowIfAvailable";

// save utm params to localstorage
export const recordUtmParams = () => {
  if (!windowIfAvailable) return;
  const urlParams = new URLSearchParams(windowIfAvailable.location.search);
  const utmParams = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
  ];
  utmParams.forEach((param) => {
    const value = urlParams.get(param);
    if (value) {
      localStorage.setItem(param, value);
    }
  });
};
