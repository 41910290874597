import { DividendPreferenceType, SubAccountType } from "../generated/graphql";

export const DIVIDEND_PREFERENCE_DEFAULT_SETTINGS_MAP: Record<
  SubAccountType,
  DividendPreferenceType
> = {
  PRIMARY: DividendPreferenceType.MoveToFrecCash,
  DIRECT_INDEX: DividendPreferenceType.Reinvest,
  TREASURY: DividendPreferenceType.Reinvest,
};
