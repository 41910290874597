import { DateOnly } from "../date_utils";
import { PerformanceDataPeriod } from "../generated/graphql";

// This is used as the start date when querying in the "ALL" timeperiod mode
export const DI_START_DATE_ALL_TIME_PERIOD = new DateOnly("2023-01-01");

export const PERFORMANCE_PERIODS_USED = [
  PerformanceDataPeriod.M1,
  PerformanceDataPeriod.M3,
  PerformanceDataPeriod.M6,
  PerformanceDataPeriod.Y1,
  PerformanceDataPeriod.Ytd,
  PerformanceDataPeriod.All,
];

export const PerformanceDataPeriodToLabels = {
  [PerformanceDataPeriod.M1]: "1M",
  [PerformanceDataPeriod.M3]: "3M",
  [PerformanceDataPeriod.M6]: "6M",
  [PerformanceDataPeriod.Y1]: "1Y",
  [PerformanceDataPeriod.Y3]: "3Y",
  [PerformanceDataPeriod.Y5]: "5Y",
  [PerformanceDataPeriod.Y10]: "10Y",
  [PerformanceDataPeriod.Ytd]: "YTD",
  [PerformanceDataPeriod.All]: "ALL",
};

export const DirectIndexPerformanceDataPeriodToMonths = {
  [PerformanceDataPeriod.M1]: 1,
  [PerformanceDataPeriod.M3]: 3,
  [PerformanceDataPeriod.M6]: 6,
  [PerformanceDataPeriod.Y1]: 12,
  [PerformanceDataPeriod.Y3]: 36,
  [PerformanceDataPeriod.Y5]: 60,
  [PerformanceDataPeriod.Y10]: 120,
  [PerformanceDataPeriod.Ytd]: undefined,
  [PerformanceDataPeriod.All]: 0,
};

export const PerformanceDataPeriodToLabelText = {
  [PerformanceDataPeriod.M1]: "1 month",
  [PerformanceDataPeriod.M3]: "3 month",
  [PerformanceDataPeriod.M6]: "6 month",
  [PerformanceDataPeriod.Y1]: "1 year",
  [PerformanceDataPeriod.Y3]: "3 year",
  [PerformanceDataPeriod.Y5]: "5 year",
  [PerformanceDataPeriod.Y10]: "10 year",
  [PerformanceDataPeriod.Ytd]: "YTD",
  [PerformanceDataPeriod.All]: "all time",
};

/**
 * Calculates the start date to use for queries based on the given time periods
 */
export const startDateFromPeriod = (
  period: PerformanceDataPeriod
): DateOnly => {
  if (period === PerformanceDataPeriod.All) {
    return DI_START_DATE_ALL_TIME_PERIOD;
  } else if (period === PerformanceDataPeriod.Ytd) {
    const currentYear = DateOnly.now("local").getFullYear();
    return new DateOnly(`${currentYear}-01-01`);
  } else {
    return DateOnly.now("local").addMonths(
      -(DirectIndexPerformanceDataPeriodToMonths[period] ?? 0)
    );
  }
};
