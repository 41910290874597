export class PromiseExtensions {
  public static sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  /**
   * Given a list of inputs, execute `fn` on each input ensuring that only
   * one execution of `fn` is running at a time.
   */
  public static async traverseSequential<I, O>(
    input: I[],
    fn: (item: I) => Promise<O>
  ): Promise<O[]> {
    const results: O[] = [];
    for (const i in input) {
      if (input[i] !== undefined && input[i] !== null) {
        const x = await fn(input[i]);
        results.push(x);
      }
    }
    return results;
  }

  /**
   * Given a list of inputs & a function returning a promise, concurrently apply
   * the function to each input, ensuring that no more than `maxConcurrency`
   * promises are running at a time.
   */
  public static async traverseConcurrent<I, O>(
    input: I[],
    fn: (item: I) => Promise<O>,
    batchSize?: number
  ): Promise<O[]> {
    if (batchSize === undefined || input.length <= batchSize) {
      return Promise.all(input.map(fn));
    } else {
      const batch = input.slice(0, batchSize);
      const rest = input.slice(batchSize);
      const batchRes = await Promise.all(batch.map(fn));
      return [
        ...batchRes,
        ...(await this.traverseConcurrent(rest, fn, batchSize)),
      ];
    }
  }
  /**
   * Given a boolean condition (defaulting to true) and a promise or a function returning
   * a promise, execute the promise if the condition is true, otherwise return a
   * resolved promise. Useful for gating execution of side effects.
   *
   * Return cases:
   *   PromiseExtensions.when(() => f(), true) => f()
   *   PromiseExtensions.when(() => f())       => f()
   *   PromiseExtensions.when(f)               => f
   *
   * Skip cases:
   *   PromiseExtensions.when(() => f(), false) => Promise.resolve()
   *   PromiseExtensions.when(f, false)         => Promise.resolve()
   *   PromiseExtensions.when(undefined)        => Promise.resolve()
   */

  public static async when(
    promise?: Promise<unknown> | (() => Promise<unknown>) | undefined,
    condition = true
  ): Promise<void> {
    if (promise === undefined || !condition) {
      return;
    } else if (promise instanceof Function) {
      await promise();
    } else {
      await promise;
    }
  }

  /**
   * Given a promise, return a promise that is guaranteed to resolve successfully
   * with either a value or an error
   */
  public static async lifted<I>(
    promise: Promise<I>
  ): Promise<
    { success: true; result: I } | { success: false; error: unknown }
  > {
    try {
      return { success: true, result: await promise };
    } catch (error) {
      return { success: false, error };
    }
  }
}

export class CompleteablePromise<T = void> {
  get reject(): (reason?: any) => void {
    return this._reject;
  }
  get resolve(): (value: T) => void {
    return this._resolve;
  }
  promise: Promise<T>;
  private _resolve: (value: T) => void;
  private _reject: (reason?: any) => void;
  constructor() {
    this._resolve = () => {
      throw new Error("Promise not yet initialized");
    };
    this._reject = () => {
      throw new Error("Promise not yet initialized");
    };
    this.promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }
}
