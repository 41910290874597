/**
 * Type safe way to extract an optional value or throw
 */
export const get: <T>(t?: T | null) => Exclude<T, undefined | null> = <T>(
  t?: T | null
) => {
  if (t !== undefined && t !== null) {
    return t as Exclude<T, undefined | null>;
  } else {
    throw new Error("Value is undefined");
  }
};

/**
 * A typesafe filter:
 * const onlyDefined: number[] = [1, null, undefined, 1].filter(isDefined)
 */
export const isDefined = <T>(e: T): e is NonNullable<T> => {
  return e !== null && e !== undefined;
};

/**
 * Fetches the most recent item in an array of objects with a `eventTime` property.
 */
export const getMostRecent = <T extends { eventTime: Date }>(
  x: T[]
): T | undefined => {
  return x.reduce((a, b) =>
    a.eventTime.valueOf() > b.eventTime.valueOf() ? a : b
  );
};
