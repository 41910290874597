import Decimal from "decimal.js";

import { ValidationResultLevel } from "../common/enums";
import {
  ALL_CREDIT_CARD_INSTITUTIONS,
  MINIMUM_FIXED_PAYMENT_AMOUNT,
  SUPPORTED_CREDIT_CARD_INSTITUTIONS,
} from "../constants";
import {
  BUSINESS_TIMEZONE,
  businessDayDiff,
  DateOnly,
  toISO8601Format,
} from "../date_utils";
import {
  CashTransferMethod,
  PaymentAmountType,
  PaymentDestinationType,
  PaymentPeriodType,
  PaymentSourceType,
  PaymentType,
} from "../generated/graphql";

// business day buffer needed for various cash transfer methods
export const CHECK_OVERNIGHT_BUFFER_IN_BUSINESS_DAYS = 5;
export const CHECK_STANDARD_BUFFER_IN_BUSINESS_DAYS = 9;
export const WIRE_BUFFER_IN_BUSINESS_DAYS = 1;
export const ACH_BUFFER_IN_BUSINESS_DAYS = 2;

// business day buffer needed for MMF sales
export const SOURCE_MMF_BUFFER_IN_BUSINESS_DAYS = 1;

// Check as a way to push payment to CC
export const DEFAULT_CC_PAYMENT_METHOD = CashTransferMethod.Check;

export enum ValidatePaymentConfigValidationResult {
  FixedAmountPaymentAmountUndefined = "FixedAmountPaymentAmountUndefined",
  PaymentAmountTooLow = "PaymentAmountTooLow",
  PeriodicPaymentDayOfMonthUndefined = "PeriodicPaymentDayOfMonthUndefined",
  MMFSourceMissingSymbol = "MMFSourceMissingSymbol",
  CCPaymentInstitutionNotSupported = "CCPaymentInstitutionNotSupported",
  TreasurySourceHasSymbol = "TreasurySourceHasSymbol",
}

export const ValidatePaymentConfigValidationResultLevelMap: Record<
  ValidatePaymentConfigValidationResult,
  ValidationResultLevel
> = {
  FixedAmountPaymentAmountUndefined: ValidationResultLevel.Error,
  PaymentAmountTooLow: ValidationResultLevel.Error,
  PeriodicPaymentDayOfMonthUndefined: ValidationResultLevel.Error,
  MMFSourceMissingSymbol: ValidationResultLevel.Error,
  CCPaymentInstitutionNotSupported: ValidationResultLevel.Error,
  TreasurySourceHasSymbol: ValidationResultLevel.Error,
};

export type ValidatePaymentConfigArgs = {
  type: PaymentType;
  sourceType: PaymentSourceType;
  sourceSecurityId?: string;
  destinationType: PaymentDestinationType;
  destinationAccountId: string;
  plaidInstitutionId: string;
  amountType: PaymentAmountType;
  amount?: Decimal;
  dayOfMonth?: number;
  periodType?: PaymentPeriodType;
  startAt: Date;
  deleted: boolean;
  bypassSupportedInstitutionsCheck: boolean;
};

export function validatePaymentConfig(
  args: ValidatePaymentConfigArgs
): ValidatePaymentConfigValidationResult[] {
  const results = new Set<ValidatePaymentConfigValidationResult>();

  // Check that amount is present for fixed amount payments
  if (
    args.amountType === PaymentAmountType.FixedAmount &&
    args.amount === undefined
  ) {
    results.add(
      ValidatePaymentConfigValidationResult.FixedAmountPaymentAmountUndefined
    );
  }

  // Check that amount is reasonable for fixed-amount payments
  if (
    args.amountType === PaymentAmountType.FixedAmount &&
    args.amount?.lt(MINIMUM_FIXED_PAYMENT_AMOUNT)
  ) {
    results.add(ValidatePaymentConfigValidationResult.PaymentAmountTooLow);
  }
  // Check that amount is reasonable for one-off statement-balance payments
  if (
    args.type === PaymentType.SinglePayment &&
    args.amountType === PaymentAmountType.StatementBalance &&
    args.amount?.lt(MINIMUM_FIXED_PAYMENT_AMOUNT)
  ) {
    results.add(ValidatePaymentConfigValidationResult.PaymentAmountTooLow);
  }

  // Check that dayOfMonth is present for periodic payments
  if (
    args.type === PaymentType.PeriodicPayment &&
    args.dayOfMonth === undefined
  ) {
    results.add(
      ValidatePaymentConfigValidationResult.PeriodicPaymentDayOfMonthUndefined
    );
  }

  // Ensure securityId is present for MMF source type, since we need to sell the right underlying security.
  if (
    args.sourceType === PaymentSourceType.MoneyMarketFund &&
    args.sourceSecurityId === undefined
  ) {
    results.add(ValidatePaymentConfigValidationResult.MMFSourceMissingSymbol);
  }

  // As above, only MMF source type should have a securityId. For Treasury, the client need only specify the payment method
  // and the server later decides which security to sell to fund the payment.
  if (
    args.sourceType === PaymentSourceType.Treasury &&
    args.sourceSecurityId !== undefined
  ) {
    results.add(ValidatePaymentConfigValidationResult.TreasurySourceHasSymbol);
  }

  if (args.destinationType === PaymentDestinationType.CreditCard) {
    const allowedInstitutions = args.bypassSupportedInstitutionsCheck
      ? ALL_CREDIT_CARD_INSTITUTIONS
      : SUPPORTED_CREDIT_CARD_INSTITUTIONS;

    if (
      !allowedInstitutions.find(
        (a) => a.plaidInstitutionId === args.plaidInstitutionId
      )
    ) {
      // address not present
      results.add(
        ValidatePaymentConfigValidationResult.CCPaymentInstitutionNotSupported
      );
    }
  }

  return Array.from(results);
}

/**
 * Based on cash transfer method and source of funds, returns the date when the next payment can be made.
 * If we have enough buffer based on method, return dueDate, else the next payment date we can target.
 */
export function nextPaymentDate(
  dueDate: DateOnly,
  fundSource: PaymentSourceType,
  method: CashTransferMethod,
  plaidInstitutionId?: string
): DateOnly {
  const diff = businessDayDiff(dueDate, DateOnly.now(BUSINESS_TIMEZONE));
  const bufferNeeded = bufferNeededForPayment(
    fundSource,
    method,
    plaidInstitutionId
  );
  if (diff >= bufferNeeded) {
    return dueDate;
  } else {
    return dueDate.add(1, "month");
  }
}

/**
 * Given payment date and transfer method, when should we trigger a withdrawal to make that payment date.
 */
export function datePaymentNeedsToBeInitiated(
  dueDate: DateOnly,
  fundSource: PaymentSourceType,
  method: CashTransferMethod,
  plaidInstitutionId?: string
): DateOnly {
  const bufferNeeded = bufferNeededForPayment(
    fundSource,
    method,
    plaidInstitutionId
  );
  return dueDate.subBusinessDays(bufferNeeded);
}

/**
 * Given source of funds and payment method, calculate buffer needed to trigger payment.
 */
export function bufferNeededForPayment(
  fundSource: PaymentSourceType,
  method: CashTransferMethod,
  plaidInstitutionId?: string
): number {
  switch (method) {
    case CashTransferMethod.Check:
      const ccInst = ALL_CREDIT_CARD_INSTITUTIONS.find(
        (e) => e.plaidInstitutionId === plaidInstitutionId
      );
      const supportsOvernight =
        (ccInst && ccInst.deliveryDetails.supportsOvernight) ?? false;
      const deliveryBuffer = supportsOvernight
        ? CHECK_OVERNIGHT_BUFFER_IN_BUSINESS_DAYS
        : CHECK_STANDARD_BUFFER_IN_BUSINESS_DAYS;
      return fundSource === PaymentSourceType.MoneyMarketFund ||
        fundSource === PaymentSourceType.Treasury
        ? deliveryBuffer + SOURCE_MMF_BUFFER_IN_BUSINESS_DAYS
        : deliveryBuffer;
    case CashTransferMethod.Ach:
      return fundSource === PaymentSourceType.MoneyMarketFund ||
        fundSource === PaymentSourceType.Treasury
        ? ACH_BUFFER_IN_BUSINESS_DAYS + SOURCE_MMF_BUFFER_IN_BUSINESS_DAYS
        : ACH_BUFFER_IN_BUSINESS_DAYS;
    case CashTransferMethod.Wire:
      return fundSource === PaymentSourceType.MoneyMarketFund ||
        fundSource === PaymentSourceType.Treasury
        ? WIRE_BUFFER_IN_BUSINESS_DAYS + SOURCE_MMF_BUFFER_IN_BUSINESS_DAYS
        : WIRE_BUFFER_IN_BUSINESS_DAYS;
  }
}

/**
 * Combine relevant methods to determine date when a payment needs to be initiated.
 */
export function getNextPaymentInitiationDate(
  dayOfMonth: number,
  sourceType: PaymentSourceType,
  plaidInstitutionId: string | undefined
): DateOnly {
  const now = DateOnly.now(BUSINESS_TIMEZONE);
  // create date with dayOfMonth applied
  const dateBasedOnConfigDayOfMonth = new Date(
    Date.UTC(now.getFullYear(), now.getMonthNumber(), dayOfMonth)
  );

  const referenceDate = new DateOnly(
    toISO8601Format(dateBasedOnConfigDayOfMonth)
  );

  // given reference date, get upcoming payment date
  const upcomingPaymentDate = nextPaymentDate(
    referenceDate,
    sourceType,
    DEFAULT_CC_PAYMENT_METHOD,
    plaidInstitutionId
  );

  // get date when the payment needs to be triggered by
  const paymentCashTransferDate = datePaymentNeedsToBeInitiated(
    upcomingPaymentDate,
    sourceType,
    DEFAULT_CC_PAYMENT_METHOD,
    plaidInstitutionId
  );

  return paymentCashTransferDate;
}
