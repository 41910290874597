export enum LOCAL_STORAGE_KEYS {
  BUSINESS_ENTITY_INFO_KEY = "businessEntityInfo",
  LAST_LOGIN_METHOD_KEY = "lastLoginMethod",
  INVITE_CODE_KEY = "inviteCode",
  IS_EMPLOYEE = "isEmployee",
  DEBUG_CLEARING_ACCOUNT = "debugClearingAccount",
  DEBUG_ACCOUNT_LENS_CLEARING_ACCOUNT = "debugAccountLensClearingAccount",
  DEBUG_MESSAGE_LS_KEY = "debugMessages",
  DEBUG_TOUR_LS_KEY = "forceFrecTours",
  DEBUG_MARKET_DATA_SIM_ID = "debugMarketDataSimId",
  TRUST_THIS_DEVICE_TOKEN = "deviceToken",
  TRUST_ENTITY_INFO_KEY = "trustEntityInfo",
  ONBOARDING_TYPE = "onboardingType",
  MFA_VERIFICATION_SENT = "mfaVerificationSent",
}
