import Decimal from "decimal.js";
import { Duration } from "luxon";

import { DocumentLinks } from "../constants";

export type Promotion = {
  key: string;
  name: string;
  deferral: Duration;
  creditAmount: Decimal;
  fromDate: Date;
  toDate: Date;
  terms: string;
};

export enum PromotionKey {
  Frec2024NewYearBonusKey = "frec-2024-new-year-bonus",
}

export const Promotions: Promotion[] = [
  {
    key: PromotionKey.Frec2024NewYearBonusKey,
    name: "2024 New Year’s promotion",
    deferral: Duration.fromObject({ months: 6 }),
    creditAmount: new Decimal(300),
    fromDate: new Date("2024-01-10"),
    toDate: new Date("2024-01-24T23:59:59-08:00"), // end of day 24th Jan Pacific
    terms: DocumentLinks["Frec 2024 New Year Bonus Terms & Conditions"],
  },
];
