import { z } from "zod";

export type OIDCInteractionResponse = z.infer<
  typeof OIDCInteractionResponseSchema
>;

/**
 * TODO: All fields marked as optional to start, should make required as needed
 */
export const OIDCInteractionResponseSchema = z.object({
  client: z
    .object({
      applicationType: z.string().optional(),
      clientId: z.string().optional(),
      clientSecret: z.string().optional(),
      grantTypes: z.array(z.string()).optional(),
      idTokenSignedResponseAlg: z.string().optional(),
      postLogoutRedirectUris: z.array(z.string()).optional(),
      redirectUris: z.array(z.string()).optional(),
      requestUris: z.array(z.string()).optional(),
      requireAuthTime: z.boolean().optional(),
      requireSignedRequestObject: z.boolean().optional(),
      responseTypes: z.array(z.string()).optional(),
      subjectType: z.string().optional(),
      tokenEndpointAuthMethod: z.string().optional(),
    })
    .optional(),
  dbg: z.any().optional(),
  details: z.any().optional(),
  params: z.object({
    client_id: z.string().optional(),
    code_challenge: z.string().optional(),
    code_challenge_method: z.string().optional(),
    redirect_uri: z.string().optional(),
    response_type: z.string().optional(),
    scope: z.string().optional(),
    state: z.string().optional(),
    prompt: z.string().optional(),
  }),
  uid: z.string(),
});

export type OIDCPostInteractionResponse = z.infer<
  typeof OIDCPostInteractionResponseSchema
>;

export const OIDCErrorMessage = z.enum(["Unauthorized Access", "MFA required"]);

export const OIDCPostInteractionErrorSchema = z.object({
  message: OIDCErrorMessage.optional(),
});
export type OIDCPostInteractionError = z.infer<
  typeof OIDCPostInteractionErrorSchema
>;

export const OIDCPostInteractionResponseSchema = z.object({
  location: z.string(),
});

export type OIDCToken = z.infer<typeof OIDCTokenSchema>;
export const OIDCTokenSchema = z.object({
  access_token: z.string(),
  expires_in: z.number(),
  id_token: z.string(),
  refresh_token: z.string().optional(),
  scope: z.string(),
  token_type: z.string(),
});
