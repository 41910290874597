import Decimal from "decimal.js";

// Frec uses the  "Actual/360" accounting approach for daily interest.
export const INTEREST_DAYS_PER_YEAR = 360;

/**
 * Interest rate of 2% is to be passed in as 0.02.
 */
export function getDailyInterest(interestRate: Decimal) {
  return interestRate.dividedBy(INTEREST_DAYS_PER_YEAR);
}

/**
 * Interest rate of 2% is to be passed in as 0.02.
 */
export function getDailyInterestCharge(interestRate: Decimal, loan: Decimal) {
  return interestRate.dividedBy(INTEREST_DAYS_PER_YEAR).mul(loan);
}
