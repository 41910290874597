import { DateTime } from "luxon";

import { BUSINESS_TIMEZONE, DATE_FORMAT } from "./constants";
import {
  dateCompare,
  nextDay,
  previousDay,
  toISO8601Format,
  toISO8601FormatTz,
} from "./utils";

export class DateOnly {
  static FORMAT_LUXON = DATE_FORMAT;

  // Important: since this class is backed by a `Date` object we are required to choose a time
  // so we use 00:00:00 UTC time
  private date: Date;

  /**
   *
   * @param dateString YYYY-MM-DD format
   * @param timezone Defaults to "UTC"
   */
  constructor(dateString: string, timezone = "UTC") {
    const dt = DateTime.fromFormat(dateString, DateOnly.FORMAT_LUXON, {
      zone: timezone,
    });
    if (!dt.isValid) {
      throw new Error(`Invalid DateString in DateOnly : ${dateString}`);
    }
    this.date = dt.toJSDate();
  }

  // private, convenience method
  private toLuxon(): DateTime {
    return DateTime.fromJSDate(this.date, { zone: "UTC" });
  }

  // YYYY-MM-DD
  toString(): string {
    return toISO8601Format(this.date);
  }

  // YYYY-MM-DDTHH:mm:ss.sssZ
  toSqlString(): string {
    return this.date.toISOString();
  }

  // Uses luxon format strings:https://moment.github.io/luxon/#/formatting?id=table-of-tokens
  toFormat(formatString: string): string {
    return this.toLuxon().toFormat(formatString);
  }

  // YYYYMMDD
  toYYYYMMDDFormat(): string {
    return this.toLuxon().toFormat("yyyyMMdd");
  }

  // Sunday:0, Monday:1, ...
  getDayOfWeek(): number {
    return this.date.getUTCDay();
  }

  // Returns day-of-month indexed from 1
  getDay(): number {
    return this.date.getUTCDate();
  }

  /**
   * Returns month indexed from 0
   */
  getMonthNumber(): number {
    return this.date.getUTCMonth();
  }

  /**
   * Returns months as 2 digit number indexed from 1. eg. 2024-03-05 returns "03".
   */
  getFormattedMonth(): string {
    return this.date.toLocaleString("en-US", {
      month: "2-digit",
    });
  }

  getFullYear(): number {
    return this.date.getUTCFullYear();
  }

  prevDay(): DateOnly {
    const ds = toISO8601Format(previousDay(this.date));
    return new DateOnly(ds);
  }

  prevBusinessDay(): DateOnly {
    return prevBusinessDay(this);
  }

  // Note: caps at the last day of the month, e.g. Jan 31 -> Feb 28
  prevMonth(): DateOnly {
    return this.addMonths(-1);
  }

  nextDay(): DateOnly {
    const ds = toISO8601Format(nextDay(this.date));
    return new DateOnly(ds);
  }

  thisOrNextBusinessDay(): DateOnly {
    return thisOrNextBusinessDay(this);
  }

  thisOrPrevBusinessDay(): DateOnly {
    return thisOrPrevBusinessDay(this);
  }

  nextBusinessDay(): DateOnly {
    return nextBusinessDay(this);
  }

  addBusinessDays(n: number): DateOnly {
    return addBusinessDays(this, n);
  }

  subBusinessDays(n: number): DateOnly {
    return subBusinessDays(this, n);
  }

  isBusinessDay(): boolean {
    return isBusinessDay(this);
  }

  isInLeapYear(): boolean {
    const fullYear = this.getFullYear();
    return (fullYear % 4 === 0 && fullYear % 100 !== 0) || fullYear % 400 === 0;
  }

  // Note: caps at the last day of the month, e.g. Jan 31 -> Feb 28
  nextMonth(): DateOnly {
    return this.addMonths(1);
  }

  add(
    x: number,
    unit:
      | "hours"
      | "hour"
      | "days"
      | "day"
      | "weeks"
      | "week"
      | "months"
      | "month"
      | "years"
      | "year"
  ): DateOnly {
    return DateOnly.fromLuxon(this.toLuxon().plus({ [unit]: x }));
  }

  // Note: this is different than adding hours because of addDays is DST aware
  // @deprecated use add instead
  addDays(daysToAdd: number): DateOnly {
    return this.add(daysToAdd, "days");
  }

  // Note: caps at the last day of the month, e.g. Jan 31 -> Feb 28
  // @deprecated use add instead
  addMonths(monthsToAdd: number): DateOnly {
    return this.add(monthsToAdd, "months");
  }

  addYears(yearsToAdd: number): DateOnly {
    return DateOnly.fromLuxon(this.toLuxon().plus({ years: yearsToAdd }));
  }

  /**
   * Returns the delta between this date and another in the given unit.
   * new DateOnly('2022-02-10').diff(new DateOnly('2022-02-05'), 'days') == 5
   * new DateOnly('2022-02-05').diff(new DateOnly('2022-02-10'), 'days') == -5
   */
  diff(other: DateOnly, unit: "days" | "months" | "years"): number {
    const duration = this.toLuxon().diff(other.toLuxon(), unit);
    return duration.as(unit);
  }

  /**
   * Is the `other` date within (ahead of or behind) `n` units of this date?
   */
  isWithin(
    other: DateOnly,
    n: number,
    unit: "days" | "months" | "years"
  ): boolean {
    return Math.abs(this.diff(other, unit)) <= n;
  }

  eq(other: DateOnly): boolean {
    return DateOnly.compare(this, other) === 0;
  }
  lte(other: DateOnly): boolean {
    return DateOnly.compare(this, other) <= 0;
  }
  lt(other: DateOnly): boolean {
    return DateOnly.compare(this, other) < 0;
  }
  gte(other: DateOnly): boolean {
    return DateOnly.compare(this, other) >= 0;
  }
  gt(other: DateOnly): boolean {
    return DateOnly.compare(this, other) > 0;
  }

  isToday(timezone: string): boolean {
    return this.eq(DateOnly.now(timezone));
  }

  // Is this date after `other`?
  isAfter(other: DateOnly): boolean {
    return this.gt(other);
  }

  // Is this date before `other`?
  isBefore(other: DateOnly): boolean {
    return this.lt(other);
  }

  // Returns a `Date` object for this YYYY-MM-DD with time set to 00:00:00 for the given TZ.
  // Note: To those tempted to change this because it looks wrong, it looks weird for a reason.
  // TODO: Replace by toDateStartOfDuration for the clients
  toDateStartOfDay(timezone: string): Date {
    // Convert to ISO8601 string first, then read it back from this TZ.
    return this.toDateStartOfDuration(timezone, "day");
  }

  // Returns a `Date` object for this YYYY-MM-DD with time set to <hour>:<minute>:00 for the given TZ.
  toDateSpecificTime(
    timezone: string,
    hour: number,
    minute: number,
    second?: number
  ): Date {
    return DateTime.fromFormat(toISO8601Format(this.date), "yyyy-MM-dd", {
      zone: timezone,
    })
      .set({ hour, minute, second })
      .toJSDate();
  }

  // Returns a `Date` object for this YYYY-MM-DD with time set to 23:59:59 for the given TZ.
  // TODO: Replace by toDateEndOfDuration for the clients
  toDateEndOfDay(timezone: string): Date {
    // Convert to ISO8601 string first, then read it back from this TZ.
    return this.toDateEndOfDuration(timezone, "day");
  }

  // Returns a `DateTime` object for this YYYY-MM-DD with time set to 00:00:00 for the given TZ.
  // (and optionally to start of the month/year based on the input duration)
  toDateStartOfDuration(
    timezone: string,
    duration: "day" | "month" | "year"
  ): Date {
    // Convert to ISO8601 string first, then read it back from this TZ.
    return DateTime.fromFormat(toISO8601Format(this.date), "yyyy-MM-dd", {
      zone: timezone,
    })
      .startOf(duration)
      .toJSDate();
  }

  // Returns a `DateTime` object for this YYYY-MM-DD with time set to 23:59:59 for the given TZ.
  // (and optionally to last of the month/year based on the input duration)
  toDateEndOfDuration(
    timezone: string,
    duration: "day" | "month" | "year"
  ): Date {
    // Convert to ISO8601 string first, then read it back from this TZ.
    return DateTime.fromFormat(toISO8601Format(this.date), "yyyy-MM-dd", {
      zone: timezone,
    })
      .endOf(duration)
      .toJSDate();
  }

  toDateStartOfDayUTC(): Date {
    return new Date(this.date);
  }

  // millis since epoch (1 January 1970 UTC)
  valueOf(): number {
    return this.date.valueOf();
  }

  // millis since epoch (1 January 1970 UTC)
  getTime(): number {
    return this.date.valueOf();
  }

  /**
   * Used for sorting by date in ascending order
   * 1 if this > b, -1 if this < b, 0 if this === b
   */
  compare(b: DateOnly): 1 | 0 | -1 {
    return DateOnly.compare(this, b);
  }

  /**
   * Used for sorting by date in ascending order
   * return > 0 if this is AFTER b, return < 0 if this BEFORE b, return 0 if this === b
   */
  compareMillis(b: DateOnly): number {
    return this.date.valueOf() - b.date.valueOf();
  }

  /**
   * DateOnly Static methods
   */
  static fromLuxon(d: DateTime): DateOnly {
    return new DateOnly(toISO8601Format(d.toJSDate()));
  }

  // Take the YYYY-MM-DD from the supplied `Date` (dateTime) in the UTC TZ
  static fromDateUTC(date: Date): DateOnly {
    return new DateOnly(toISO8601Format(date));
  }

  // Take the YYYY-MM-DD from the supplied `Date` (dateTime) in the supplied TZ
  // e.g. "What day was it in NY 15 hours ago?"
  static fromDateTz(date: Date, timezone: string): DateOnly {
    return new DateOnly(toISO8601FormatTz(date, timezone));
  }

  // Take the ISO8601 UTC string and applies tz
  // eg: 2022-11-05T01:16:01.310Z
  static fromISO8601FormatStringToTz(
    dateString: string,
    timezone: string
  ): DateOnly {
    return new DateOnly(
      DateTime.fromISO(dateString)
        .setZone(timezone)
        .toFormat(DateOnly.FORMAT_LUXON)
    );
  }

  static fromYYYYMMDDFormat(dateString: string): DateOnly {
    // add a - at positions 4 6 and 8
    const formattedDate = `${dateString.slice(0, 4)}-${dateString.slice(
      4,
      6
    )}-${dateString.slice(6, 8)}`;

    return new DateOnly(formattedDate);
  }

  // compare DateOnly objects
  // 1 if d1 > d2, -1 if d1 < d2, 0 if d1 == d2
  static compare(d1: DateOnly, d2: DateOnly): -1 | 0 | 1 {
    return dateCompare(d1.toDateStartOfDayUTC(), d2.toDateStartOfDayUTC());
  }

  // now as a DateOnly
  static now(timezone: string): DateOnly {
    let date = DateTime.now();
    date = date.setZone(timezone);
    return new DateOnly(date.toFormat(DateOnly.FORMAT_LUXON));
  }

  // Given a date, calculates the number of days in between.
  // daysInBetween("2022-05-03", "2022-05-01") -> 2
  static daysInBetween(d1: DateOnly, d2: DateOnly): number {
    return DateTime.fromFormat(d1.toString(), "yyyy-MM-dd").diff(
      DateTime.fromFormat(d2.toString(), "yyyy-MM-dd"),
      "days"
    ).days;
  }

  static minArr(dArr: DateOnly[]): DateOnly | undefined {
    if (dArr.length === 0) return undefined;
    return dArr.reduce((d1, d2) => DateOnly.min(d1, d2));
  }
  static maxArr(dArr: DateOnly[]): DateOnly | undefined {
    if (dArr.length === 0) return undefined;
    return dArr.reduce((d1, d2) => DateOnly.max(d1, d2));
  }

  /**
   * Returns range of DateOnly objects given start and end dates (inclusive)
   */
  static getDateOnlyRange(
    start: DateOnly,
    end: DateOnly,
    businessDaysOnly = true
  ): DateOnly[] {
    const range: DateOnly[] = [];
    let currentDate = start;

    while (currentDate.compare(end) <= 0) {
      if (
        !businessDaysOnly ||
        (businessDaysOnly && currentDate.isBusinessDay())
      ) {
        range.push(currentDate);
      }
      currentDate = currentDate.add(1, "day");
    }

    return range;
  }

  /**
   * Returns the minimum date of the two dates
   */
  static min(d1: DateOnly, d2: DateOnly): DateOnly {
    const d = d1.compare(d2) < 0 ? d1 : d2;
    return new DateOnly(d.toString());
  }

  /**
   * Returns the maximum date of the two dates
   */
  static max(d1: DateOnly, d2: DateOnly): DateOnly {
    const d = d1.compare(d2) > 0 ? d1 : d2;
    return new DateOnly(d.toString());
  }
}

/**
 *
 * BUSINESS DAY STATIC FUNCTIONS
 *
 */

// The markets are closed on the following days.
export const HOLIDAYS: DateOnly[] = [
  // see :
  // https://www.sifma.org/wp-content/uploads/2017/06/misc-us-historical-holiday-market-recommendations-sifma.pdf
  // https://gist.githubusercontent.com/jameslin101/cf2860eba52a56281427/raw/e127e928bba9f7cbbfc4d8ec55e159e7b4add718/CSV%2520of%2520US%2520Stock%2520market%2520holidays%2520through%25202020
  // https://www.sifma.org/resources/general/us-holiday-archive/

  // 2001
  new DateOnly("2001-01-01"), // 01-Jan-2001	New Year's Day
  new DateOnly("2001-01-15"), // 15-Jan-2001	Martin Luther King Day
  new DateOnly("2001-02-19"), // 19-Feb-2001	Washington's Birthday
  new DateOnly("2001-04-13"), // 13-Apr-2001	Good Friday
  new DateOnly("2001-05-28"), // 28-May-2001	Memorial Day
  new DateOnly("2001-07-04"), // 04-Jul-2001	Independence Day
  new DateOnly("2001-09-03"), // 03-Sep-2001	Labor Day
  new DateOnly("2001-09-11"), // 11-Sep-2001	World Trade Center Event
  new DateOnly("2001-09-12"), // 12-Sep-2001	World Trade Center Event
  new DateOnly("2001-09-13"), // 13-Sep-2001	World Trade Center Event
  new DateOnly("2001-09-14"), // 14-Sep-2001	World Trade Center Event
  new DateOnly("2001-11-22"), // 22-Nov-2001	Thanksgiving
  new DateOnly("2001-12-25"), // 25-Dec-2001	Christmas

  // 2002
  new DateOnly("2002-01-01"), // 01-Jan-2002	New Year's Day
  new DateOnly("2002-01-21"), // 21-Jan-2002	Martin Luther King Day
  new DateOnly("2002-02-18"), // 18-Feb-2002	Washington's Birthday
  new DateOnly("2002-03-29"), // 29-Mar-2002	Good Friday
  new DateOnly("2002-05-27"), // 27-May-2002	Memorial Day
  new DateOnly("2002-07-04"), // 04-Jul-2002	Independence Day
  new DateOnly("2002-09-02"), // 02-Sep-2002	Labor Day
  new DateOnly("2002-11-28"), // 28-Nov-2002	Thanksgiving
  new DateOnly("2002-12-25"), // 25-Dec-2002	Christmas

  // 2003
  new DateOnly("2003-01-01"), // 01-Jan-2003	New Year's Day
  new DateOnly("2003-01-20"), // 20-Jan-2003	Martin Luther King Day
  new DateOnly("2003-02-17"), // 17-Feb-2003	Washington's Birthday
  new DateOnly("2003-04-18"), // 18-Apr-2003	Good Friday
  new DateOnly("2003-05-26"), // 26-May-2003	Memorial Day
  new DateOnly("2003-07-04"), // 04-Jul-2003	Independence Day
  new DateOnly("2003-09-01"), // 01-Sep-2003	Labor Day
  new DateOnly("2003-11-27"), // 27-Nov-2003	Thanksgiving
  new DateOnly("2003-12-25"), // 25-Dec-2003	Christmas

  // 2004
  new DateOnly("2004-01-01"), // 01-Jan-2004	New Year's Day
  new DateOnly("2004-01-19"), // 19-Jan-2004	Martin Luther King Day
  new DateOnly("2004-02-16"), // 16-Feb-2004	Washington's Birthday
  new DateOnly("2004-04-09"), // 09-Apr-2004	Good Friday
  new DateOnly("2004-05-31"), // 31-May-2004	Memorial Day
  new DateOnly("2004-06-11"), // 11-Jun-2004	Presidential Funeral - Ronald Reagan
  new DateOnly("2004-07-05"), // 05-Jul-2004	Independence Day (observed)
  new DateOnly("2004-09-06"), // 06-Sep-2004	Labor Day
  new DateOnly("2004-11-25"), // 25-Nov-2004	Thanksgiving
  new DateOnly("2004-12-24"), // 24-Dec-2004	Christmas (observed)

  // 2005
  new DateOnly("2005-01-17"), // 17-Jan-2005	Martin Luther King Day
  new DateOnly("2005-02-21"), // 21-Feb-2005	Washington's Birthday
  new DateOnly("2005-03-25"), // 25-Mar-2005	Good Friday
  new DateOnly("2005-05-30"), // 30-May-2005	Memorial Day
  new DateOnly("2005-07-04"), // 04-Jul-2005	Independence Day
  new DateOnly("2005-09-05"), // 05-Sep-2005	Labor Day
  new DateOnly("2005-11-24"), // 24-Nov-2005	Thanksgiving
  new DateOnly("2005-12-26"), // 26-Dec-2005	Christmas (observed)

  // 2006
  new DateOnly("2006-01-02"), // 02-Jan-2006	New Year's Day (observed)
  new DateOnly("2006-01-16"), // 16-Jan-2006	Martin Luther King Day
  new DateOnly("2006-02-20"), // 20-Feb-2006	Washington's Birthday
  new DateOnly("2006-04-14"), // 14-Apr-2006	Good Friday
  new DateOnly("2006-05-29"), // 29-May-2006	Memorial Day
  new DateOnly("2006-07-04"), // 04-Jul-2006	Independence Day
  new DateOnly("2006-09-04"), // 04-Sep-2006	Labor Day
  new DateOnly("2006-11-23"), // 23-Nov-2006	Thanksgiving
  new DateOnly("2006-12-25"), // 25-Dec-2006	Christmas

  // 2007
  new DateOnly("2007-01-01"), // 01-Jan-2007	New Year's Day
  new DateOnly("2007-01-02"), // 02-Jan-2007	Day Of Mourning - Gerald Ford
  new DateOnly("2007-01-15"), // 15-Jan-2007	Martin Luther King Day
  new DateOnly("2007-02-19"), // 19-Feb-2007	Washington's Birthday
  new DateOnly("2007-04-06"), // 06-Apr-2007	Good Friday
  new DateOnly("2007-05-28"), // 28-May-2007	Memorial Day
  new DateOnly("2007-07-04"), // 04-Jul-2007	Independence Day
  new DateOnly("2007-09-03"), // 03-Sep-2007	Labor Day
  new DateOnly("2007-11-22"), // 22-Nov-2007	Thanksgiving
  new DateOnly("2007-12-25"), // 25-Dec-2007	Christmas

  // 2008
  new DateOnly("2008-01-01"), // 01-Jan-2008	New Year's Day
  new DateOnly("2008-01-21"), // 21-Jan-2008	Martin Luther King Day
  new DateOnly("2008-02-18"), // 18-Feb-2008	Washington's Birthday
  new DateOnly("2008-03-21"), // 21-Mar-2008	Good Friday
  new DateOnly("2008-05-26"), // 26-May-2008	Memorial Day
  new DateOnly("2008-07-04"), // 04-Jul-2008	Independence Day
  new DateOnly("2008-09-01"), // 01-Sep-2008	Labor Day
  new DateOnly("2008-11-27"), // 27-Nov-2008	Thanksgiving
  new DateOnly("2008-12-25"), // 25-Dec-2008	Christmas

  // 2009
  new DateOnly("2009-01-01"), // 01-Jan-2009	New Year's Day
  new DateOnly("2009-01-19"), // 19-Jan-2009	Martin Luther King Day
  new DateOnly("2009-02-16"), // 16-Feb-2009	Washington's Birthday
  new DateOnly("2009-04-10"), // 10-Apr-2009	Good Friday
  new DateOnly("2009-05-25"), // 25-May-2009	Memorial Day
  new DateOnly("2009-07-03"), // 03-Jul-2009	Independence Day (observed)
  new DateOnly("2009-09-07"), // 07-Sep-2009	Labor Day
  new DateOnly("2009-11-26"), // 26-Nov-2009	Thanksgiving
  new DateOnly("2009-12-25"), // 25-Dec-2009	Christmas

  // 2010
  new DateOnly("2010-01-01"), // 01-Jan-2010	New Year's Day
  new DateOnly("2010-01-18"), // 18-Jan-2010	Martin Luther King Day
  new DateOnly("2010-02-15"), // 15-Feb-2010	Washington's Birthday
  new DateOnly("2010-04-02"), // 02-Apr-2010	Good Friday
  new DateOnly("2010-05-31"), // 31-May-2010	Memorial Day
  new DateOnly("2010-07-05"), // 05-Jul-2010	Independence Day (observed)
  new DateOnly("2010-09-06"), // 06-Sep-2010	Labor Day
  new DateOnly("2010-11-25"), // 25-Nov-2010	Thanksgiving
  new DateOnly("2010-12-24"), // 24-Dec-2010	Christmas (observed)

  // 2011
  new DateOnly("2011-01-17"), // 17-Jan-2011	Martin Luther King Day
  new DateOnly("2011-02-21"), // 21-Feb-2011	President's Day
  new DateOnly("2011-04-22"), // 22-Apr-2011	Good Friday
  new DateOnly("2011-05-30"), // 30-May-2011	Memorial Day
  new DateOnly("2011-07-04"), // 04-Jul-2011	Independence Day
  new DateOnly("2011-09-05"), // 05-Sep-2011	Labor Day
  new DateOnly("2011-11-11"), // 11-Nov-2011	Veterans Day
  new DateOnly("2011-11-24"), // 24-Nov-2011	Thanksgiving Day
  new DateOnly("2011-12-26"), // 26-Dec-2011	Christmas Day (observed)

  // 2012
  new DateOnly("2012-01-02"), // 02-Jan-2012	New Year's Day
  new DateOnly("2012-01-16"), // 16-Jan-2011	Martin Luther King Day
  new DateOnly("2012-02-20"), // 20-Feb-2012	Presidents Day (Washington's Birthday)
  new DateOnly("2012-04-06"), // 06-Apr-2012	Good Friday
  new DateOnly("2012-05-28"), // 28-May-2012	Memorial Day
  new DateOnly("2012-07-04"), // 04-Jul-2012	Independence Day
  new DateOnly("2012-09-03"), // 03-Sep-2012	Labor Day
  new DateOnly("2012-11-22"), // 22-Nov-2012	Thanksgiving Day
  new DateOnly("2012-12-25"), // 25-Dec-2012	Christmas Day

  // 2013
  new DateOnly("2013-01-01"), // 01-Jan-2013	New Year's Day
  new DateOnly("2013-01-21"), // 21-Jan-2013	Martin Luther King Jr. Day
  new DateOnly("2013-02-18"), // 18-Feb-2013	Presidents Day (Washington's Birthday)
  new DateOnly("2013-03-29"), // 29-Mar-2013	Good Friday
  new DateOnly("2013-05-27"), // 27-May-2013	Memorial Day
  new DateOnly("2013-07-04"), // 04-Jul-2013	Independence Day
  new DateOnly("2013-09-02"), // 02-Sep-2013	Labor Day
  new DateOnly("2013-11-28"), // 28-Nov-2013	Thanksgiving Day
  new DateOnly("2013-12-25"), // 25-Dec-2013	Christmas Day

  // 2014
  new DateOnly("2014-01-01"), // 01-Jan-2014	New Year's Day
  new DateOnly("2014-01-20"), // 20-Jan-2014	Martin Luther King Jr. Day
  new DateOnly("2014-02-17"), // 17-Feb-2014	Presidents Day (Washington's Birthday)
  new DateOnly("2014-04-18"), // 18-Apr-2014	Good Friday
  new DateOnly("2014-05-26"), // 26-May-2014	Memorial Day
  new DateOnly("2014-07-04"), // 04-Jul-2014	Independence Day
  new DateOnly("2014-09-01"), // 01-Sep-2014	Labor Day
  new DateOnly("2014-11-27"), // 27-Nov-2014	Thanksgiving Day
  new DateOnly("2014-12-25"), // 25-Dec-2014	Christmas Day

  // 2015
  new DateOnly("2015-01-01"), // 01-Jan-2015	New Year's Day
  new DateOnly("2015-01-19"), // 19-Jan-2015	Martin Luther King Jr. Day
  new DateOnly("2015-02-16"), // 16-Feb-2015	Presidents Day (Washington's Birthday)
  new DateOnly("2015-04-03"), // 03-Apr-2015	Good Friday
  new DateOnly("2015-05-25"), // 25-May-2015	Memorial Day
  new DateOnly("2015-07-03"), // 03-Jul-2015	Independence Day (observed)
  new DateOnly("2015-09-07"), // 07-Sep-2015	Labor Day
  new DateOnly("2015-11-26"), // 26-Nov-2015	Thanksgiving Day
  new DateOnly("2015-12-25"), // 25-Dec-2015	Christmas Day

  // 2016
  new DateOnly("2016-01-01"), // 01-Jan-2016	New Year's Day
  new DateOnly("2016-01-18"), // 18-Jan-2016	Martin Luther King Jr. Day
  new DateOnly("2016-02-15"), // 15-Feb-2016	Presidents Day (Washington's Birthday)
  new DateOnly("2016-03-25"), // 25-Mar-2016	Good Friday
  new DateOnly("2016-05-30"), // 30-May-2016	Memorial Day
  new DateOnly("2016-07-04"), // 04-Jul-2016	Independence Day
  new DateOnly("2016-09-05"), // 05-Sep-2016	Labor Day
  new DateOnly("2016-11-24"), // 24-Nov-2016	Thanksgiving Day
  new DateOnly("2016-12-26"), // 26-Dec-2016	Christmas Day (observed)

  // 2017
  new DateOnly("2017-01-02"), // 02-Jan-2017	New Year's Day (observed)
  new DateOnly("2017-01-16"), // 16-Jan-2017	Martin Luther King Jr. Day
  new DateOnly("2017-02-20"), // 20-Feb-2017	Presidents Day (Washington's Birthday)
  new DateOnly("2017-04-14"), // 14-Apr-2017	Good Friday
  new DateOnly("2017-05-29"), // 29-May-2017	Memorial Day
  new DateOnly("2017-07-04"), // 04-Jul-2017	Independence Day
  new DateOnly("2017-09-04"), // 04-Sep-2017	Labor Day
  new DateOnly("2017-11-23"), // 23-Nov-2017	Thanksgiving Day
  new DateOnly("2017-12-25"), // 25-Dec-2017	Christmas Day

  // 2018
  new DateOnly("2018-01-01"), // 01-Jan-2018	New Year's Day
  new DateOnly("2018-01-15"), // 15-Jan-2018	Martin Luther King Jr. Day
  new DateOnly("2018-02-19"), // 19-Feb-2018	Presidents Day (Washington's Birthday)
  new DateOnly("2018-03-30"), // 30-Mar-2018	Good Friday
  new DateOnly("2018-05-28"), // 28-May-2018	Memorial Day
  new DateOnly("2018-07-04"), // 04-Jul-2018	Independence Day
  new DateOnly("2018-09-03"), // 03-Sep-2018	Labor Day
  new DateOnly("2018-11-22"), // 22-Nov-2018	Thanksgiving Day
  new DateOnly("2018-12-25"), // 25-Dec-2018	Christmas Day

  // 2019
  new DateOnly("2019-01-01"), // 01-Jan-2019	New Year's Day
  new DateOnly("2019-01-21"), // 21-Jan-2019	Martin Luther King Jr. Day
  new DateOnly("2019-02-18"), // 18-Feb-2019	Presidents Day (Washington's Birthday)
  new DateOnly("2019-04-19"), // 19-Apr-2019	Good Friday
  new DateOnly("2019-05-27"), // 27-May-2019	Memorial Day
  new DateOnly("2019-07-04"), // 04-Jul-2019	Independence Day
  new DateOnly("2019-09-02"), // 02-Sep-2019	Labor Day
  new DateOnly("2019-11-28"), // 28-Nov-2019	Thanksgiving Day
  new DateOnly("2019-12-25"), // 25-Dec-2019	Christmas Day

  // 2020
  new DateOnly("2020-01-01"), // 01-Jan-2020	New Year's Day
  new DateOnly("2020-01-20"), // 20-Jan-2020	Martin Luther King Jr. Day
  new DateOnly("2020-02-17"), // 17-Feb-2020	Presidents Day (Washington's Birthday)
  new DateOnly("2020-04-10"), // 10-Apr-2020	Good Friday
  new DateOnly("2020-05-25"), // 25-May-2020	Memorial Day
  new DateOnly("2020-07-03"), // 03-Jul-2020	Independence Day (observed)
  new DateOnly("2020-09-07"), // 07-Sep-2020	Labor Day
  new DateOnly("2020-11-26"), // 26-Nov-2020	Thanksgiving Day
  new DateOnly("2020-12-25"), // 25-Dec-2020	Christmas Day

  // 2021
  new DateOnly("2021-01-01"), // 01-Jan-2021	New Year's Day
  new DateOnly("2021-01-18"), // 18-Jan-2021	Martin Luther King Jr. Day
  new DateOnly("2021-02-15"), // 15-Feb-2021	Presidents Day (Washington's Birthday)
  new DateOnly("2021-04-02"), // 02-Apr-2021	Good Friday
  new DateOnly("2021-05-31"), // 31-May-2021	Memorial Day
  new DateOnly("2021-07-05"), // 05-Jul-2021	Independence Day (observed)
  new DateOnly("2021-09-06"), // 06-Sep-2021	Labor Day
  new DateOnly("2021-11-25"), // 25-Nov-2021	Thanksgiving Day
  new DateOnly("2021-12-24"), // 24-Dec-2021	Christmas Day (observed)

  // 2022
  new DateOnly("2022-01-01"), // 01-Jan-2022	New Year's Day
  new DateOnly("2022-01-17"), // 17-Jan-2022	Martin Luther King Jr. Day
  new DateOnly("2022-02-21"), // 21-Feb-2022	Presidents Day (Washington's Birthday)
  new DateOnly("2022-04-15"), // 15-Apr-2022	Good Friday
  new DateOnly("2022-05-30"), // 30-May-2022	Memorial Day
  new DateOnly("2022-07-04"), // 04-Jul-2022	Independence Day
  new DateOnly("2022-09-05"), // 05-Sep-2022	Labor Day
  new DateOnly("2022-11-24"), // 24-Nov-2022	Thanksgiving Day
  new DateOnly("2022-12-26"), // 26-Dec-2022	Christmas Day (observed)

  // 2023
  new DateOnly("2023-01-02"), // 02-Jan-2023	New Year's Day (observed)
  new DateOnly("2023-01-16"), // 16-Jan-2023	Martin Luther King Day
  new DateOnly("2023-02-20"), // 20-Feb-2023	President's Day
  new DateOnly("2023-04-07"), // 07-Apr-2023	Good Friday
  new DateOnly("2023-05-29"), // 29-May-2023	Memorial Day
  new DateOnly("2023-06-19"), // 19-Jun-2023	Juneteenth
  new DateOnly("2023-07-04"), // 04-Jul-2023	Independence Day
  new DateOnly("2023-09-04"), // 04-Sep-2023	Labor Day
  new DateOnly("2023-11-23"), // 23-Nov-2023	Thanksgiving Day
  new DateOnly("2023-12-25"), // 25-Dec-2023	Christmas Day

  // 2024
  new DateOnly("2024-01-01"), // 01-Jan-2024	New Year's Day
  new DateOnly("2024-01-15"), // 15-Jan-2024	Martin Luther King Day
  new DateOnly("2024-02-19"), // 19-Feb-2024	President's Day
  new DateOnly("2024-03-29"), // 29-Mar-2024	Good Friday
  new DateOnly("2024-05-27"), // 27-May-2024	Memorial Day
  new DateOnly("2024-06-19"), // 19-Jun-2024	Juneteenth
  new DateOnly("2024-07-04"), // 04-Jul-2024	Independence Day
  new DateOnly("2024-09-02"), // 02-Sep-2024	Labor Day
  new DateOnly("2024-11-28"), // 28-Nov-2024	Thanksgiving Day
  new DateOnly("2024-12-25"), // 25-Dec-2024	Christmas Day

  // 2025
  new DateOnly("2025-01-01"), // 01-Jan-2025	New Year's Day
  new DateOnly("2025-01-20"), // 20-Jan-2025	Martin Luther King Day
  new DateOnly("2025-02-17"), // 17-Feb-2025	President's Day
  new DateOnly("2025-04-18"), // 18-Apr-2025	Good Friday
  new DateOnly("2025-05-26"), // 26-May-2025	Memorial Day
  new DateOnly("2025-06-19"), // 19-Jun-2024	Juneteenth
  new DateOnly("2025-07-04"), // 04-Jul-2025	Independence Day
  new DateOnly("2025-09-01"), // 01-Sep-2025	Labor Day
  new DateOnly("2025-11-27"), // 27-Nov-2025	Thanksgiving Day
  new DateOnly("2025-12-25"), // 25-Dec-2025	Christmas Day

  // 2026
  new DateOnly("2026-01-01"), // 01-Jan-2026	New Year's Day
  new DateOnly("2026-01-19"), // 19-Jan-2026	Martin Luther King Day
  new DateOnly("2026-02-16"), // 16-Feb-2026	President's Day
  new DateOnly("2026-04-03"), // 03-Apr-2026	Good Friday
  new DateOnly("2026-05-25"), // 25-May-2026	Memorial Day
  new DateOnly("2026-06-19"), // 19-Jun-2024	Juneteenth
  new DateOnly("2026-07-03"), // 03-Jul-2026	Independence Day
  new DateOnly("2026-09-07"), // 07-Sep-2026	Labor Day
  new DateOnly("2026-11-26"), // 26-Nov-2026	Thanksgiving Day
  new DateOnly("2026-12-25"), // 25-Dec-2026	Christmas Day
  // 2027 -- update as desired
];

export const isWeekend = (d: DateOnly): boolean => {
  const dow = d.getDayOfWeek();
  return dow === 0 || dow === 6;
};

export const isHoliday = (d: DateOnly): boolean => {
  const match = HOLIDAYS.find((d0) => d0.valueOf() === d.valueOf());
  return match !== undefined;
};

export const isBusinessDay = (d: DateOnly): boolean => {
  return !isHoliday(d) && !isWeekend(d);
};

export const thisOrNextBusinessDay = (d: DateOnly): DateOnly => {
  return isBusinessDay(d) ? d : nextBusinessDay(d);
};

export const thisOrPrevBusinessDay = (d: DateOnly): DateOnly => {
  return isBusinessDay(d) ? d : prevBusinessDay(d);
};

export const nextBusinessDay = (d: DateOnly): DateOnly => {
  let d0 = d.nextDay();
  while (!isBusinessDay(d0)) {
    d0 = d0.nextDay();
  }
  return d0;
};

export const prevBusinessDay = (d: DateOnly): DateOnly => {
  let d0 = d.prevDay();
  while (!isBusinessDay(d0)) {
    d0 = d0.prevDay();
  }
  return d0;
};

export const addBusinessDays = (d: DateOnly, n: number): DateOnly => {
  const steps = Math.floor(Math.abs(n));
  const direction = Math.sign(n);

  let d0 = new DateOnly(d.toString());
  for (let i = 0; i < steps; i++) {
    if (direction > 0) d0 = nextBusinessDay(d0);
    else d0 = prevBusinessDay(d0);
  }
  return d0;
};

export const subBusinessDays = (d: DateOnly, n: number): DateOnly => {
  return addBusinessDays(d, -n);
};

export const businessDayDiff = (d1: DateOnly, d2: DateOnly): number => {
  if (d1.valueOf() < d2.valueOf()) return -businessDayDiff(d2, d1);

  // d1 is later
  let diff = 0;
  let d0 = new DateOnly(d2.toString());

  // must be a full day to count
  while (d0.nextDay().valueOf() <= d1.valueOf()) {
    if (isBusinessDay(d0)) diff++;
    d0 = d0.nextDay();
  }
  return diff;
};

/**
 * Returns an array of DateOnly that lie on the same week as the provided DateOnly.
 */
export const daysOfTheWeek = (day: DateOnly): DateOnly[] => {
  let startOfWeek: DateOnly;

  // find the start of the week (Sunday)
  if (day.getDayOfWeek() === 0) {
    startOfWeek = day;
  } else {
    const diff = day.getDayOfWeek();
    startOfWeek = day.add(-diff, "days");
  }

  // create an array for the week
  const week: DateOnly[] = [];
  for (let i = 0; i < 7; i++) {
    week.push(startOfWeek.add(i, "days"));
  }

  return week;
};

export const previousBusinessDayClose = (date?: Date): Date => {
  date = date ?? new Date();
  return DateOnly.fromDateTz(date, BUSINESS_TIMEZONE)
    .prevBusinessDay()
    .toDateSpecificTime(BUSINESS_TIMEZONE, 20, 0);
};

/**
 * Returns the business week that the given date falls in, which is defined
 * using the the first and last business day of the week. E.g. if the given date
 * is Saturday Mar 12th 2022, then the returned first date will be Monday Mar
 * 7th 2022 and the last business day will be Friday Mar 11th 2022.
 *
 * If the given date is Sunday Mar 13th 2022, then the returned date will be
 * Monday Mar 14th 2022 (Sunday is the first day of the week) and the last
 * business day will be Friday Mar 18th 2022.
 */
export const businessWeek = (
  day: DateOnly
): { first: DateOnly; last: DateOnly } => {
  const weekDays = daysOfTheWeek(day);

  const businessDays = weekDays.filter((d) => isBusinessDay(d));
  const first = businessDays[0];
  const last = businessDays[businessDays.length - 1];
  if (first === undefined || last === undefined) {
    throw new Error(`No business days in week of ${day.toString()}`);
  }
  return { first, last };
};

/**
 * Returns a list of business weeks between the start and end dates, inclusive.
 * That is, the first week returned is the week that the start date falls in,
 * and the last week returned is the week that the end date falls in.
 */
export const businessWeeks = (
  start: DateOnly,
  end: DateOnly
): { first: DateOnly; last: DateOnly }[] => {
  if (start.gt(end)) return [];

  const firstWeek = businessWeek(start);
  const lastWeek = businessWeek(end);
  let currentWeek = firstWeek;
  const arr = [];
  do {
    arr.push(currentWeek);
    currentWeek = businessWeek(currentWeek.first.add(7, "days"));
  } while (currentWeek.first.lte(lastWeek.first));
  return arr;
};

/**
 * Returns the business month that the given date falls in, which is defined
 * using the the first and last business day of the month. E.g. if the given
 * date is Saturday Apr 9th 2022, then the returned first date will be Friday
 * Apr 1st 2022 and the last business day will be Friday Apr 29st 2022.
 */
export const businessMonth = (
  day: DateOnly
): { first: DateOnly; last: DateOnly } => {
  const d = day.getDay(); // day of month, indexed from 1
  const first = day.add(-d + 1, "days");
  const last = first.add(1, "months").add(-1, "days");
  return {
    first: first.thisOrNextBusinessDay(),
    last: last.thisOrPrevBusinessDay(),
  };
};

/**
 * Returns a list of business months between the start and end dates, inclusive.
 * That is, the first month returned is the month that the start date falls in,
 * and the last month returned is the month that the end date falls in.
 */
export const businessMonths = (start: DateOnly, end: DateOnly) => {
  if (start.gt(end)) return [];

  const firstMonth = businessMonth(start);
  const lastMonth = businessMonth(end);
  let currentMonth = firstMonth;
  const arr = [];
  do {
    arr.push(currentMonth);
    currentMonth = businessMonth(currentMonth.first.add(1, "months"));
  } while (currentMonth.first.lte(lastMonth.first));
  return arr;
};

export const prettyDuration = (date1: DateOnly, date2: DateOnly): string => {
  if (date1.lt(date2)) {
    return prettyDuration(date2, date1);
  }
  const days = Math.floor(date1.diff(date2, "days"));
  const months = Math.floor(date1.diff(date2, "months"));
  const years = Math.floor(date1.diff(date2, "years"));
  const leftOverDays = Math.round(
    days - years * 365.25 - months * (365.25 / 12)
  );

  if (years > 2) {
    if (months % 12 === 0) {
      return `${years} years`;
    } else if (months % 12 <= 2) {
      return `about ${years} years`;
    } else {
      return `about ${years} years, ${months % 12} months`;
    }
  }

  if (years >= 1) {
    return `${years} years${months % 12 > 0 ? `, ${months % 12} months` : ""}`;
  }

  if (months >= 1) {
    return `${months} months${days % 30 > 0 ? `, ${leftOverDays} days` : ""}`;
  }

  return `${days} days`;
};

// Used for test of  equality
export const dateOnlyEquals = (date1?: DateOnly, date2?: DateOnly) => {
  if (date1 === undefined && date2 === undefined) {
    return true;
  } else if (date1 !== undefined && date2 !== undefined) {
    return DateOnly.compare(date1, date2) === 0;
  }

  return false;
};
