import { CipCategory, UploadDocumentType } from "../generated/graphql";

export const CIP_CATEGORY_NAME_MAP = {
  [CipCategory.Address]: "Address verification document",
  [CipCategory.Dob]: "Date of birth verification document",
  [CipCategory.Name]: "Identity verification document",
  [CipCategory.TaxId]: "Tax ID verification document",
};

export const CIP_CATEGORY_DESCRIPTION_MAP: Partial<
  Record<CipCategory, string>
> = {
  [CipCategory.TaxId]:
    "Social security card, Social security administration letter, IRS ITIN letter, or recent W2 displaying full SSN.",
};

export const CIP_CATEGORY_ALERT_MAP: Partial<Record<CipCategory, string>> = {
  [CipCategory.Address]:
    "Please make sure the document is not expired and shows an address that matches the address you entered.",
};

export const CIP_CATEGORY_TO_UPLOAD_DOCUMENT_TYPE = {
  [CipCategory.Address]: UploadDocumentType.CipInvestigationAddress,
  [CipCategory.Dob]: UploadDocumentType.CipInvestigationDob,
  [CipCategory.Name]: UploadDocumentType.CipInvestigationName,
  [CipCategory.TaxId]: UploadDocumentType.CipInvestigationTaxId,
};

export const CIP_CATEGORY_USER_INFO_TITLE_MAP: Record<CipCategory, string> = {
  [CipCategory.Address]: "Address you entered",
  [CipCategory.Name]: "Name",
  [CipCategory.Dob]: "Date of birth you entered",
  [CipCategory.TaxId]: "",
};

export const ADDITIONAL_DOCUMENTS_REQUIRED_TEXT =
  "Looks like we weren’t able to verify some of your information. You’ll need to upload some documents to proceed with creating your account, and once our team reviews them we’ll send you an email when your account is ready.";

export const UPLOAD_DOCUMENT_TYPE_NAME_MAP: Record<UploadDocumentType, string> =
  {
    // https://uat-api.apexclearing.com/documents/api/v1/document-types/uploads
    [UploadDocumentType.AccountTransferForm]: "Account Transfer Form",
    [UploadDocumentType.AffiliatedApproval]: "Affiliated Approval",
    [UploadDocumentType.DepositExplanation]: "Deposit Explanation",
    [UploadDocumentType.DriversLicense]: "Driver's License",
    [UploadDocumentType.IdentificationCard]: "Identification Card",
    [UploadDocumentType.IraDepositSlip]: "IRA Deposit Slip",
    [UploadDocumentType.LimitedMarginAgreement]: "Limited Margin Agreement",
    [UploadDocumentType.Other]: "Other",
    [UploadDocumentType.Passport]: "Passport",
    [UploadDocumentType.SignatureImage]: "Signature Image",
    [UploadDocumentType.SocialSecurityCard]: "Social Security Card",
    [UploadDocumentType.TransferOnDeathAgreement]:
      "Transfer on Death Agreement",
    // List of files for business entity onboarding
    [UploadDocumentType.ArticlesOfIncorporation]: "Articles of Incorporation",
    [UploadDocumentType.PartnershipAgreement]: "Partnership Agreement",
    [UploadDocumentType.GeneralPartnersAgreement]: "General Partners Agreement",
    [UploadDocumentType.ManagersAgreement]: "Managers Agreement",
    [UploadDocumentType.CorporationAccountForm]: "Corporation Account Form",
    // List of files for trust entity onboarding
    [UploadDocumentType.CertificateOfTrust]: "Certificate of Trust",
    // For CIP investigation
    [UploadDocumentType.CipInvestigationName]: "Identity verification document",
    [UploadDocumentType.CipInvestigationAddress]:
      "Address verification document",
    [UploadDocumentType.CipInvestigationDob]:
      "Date of birth verification document",
    [UploadDocumentType.CipInvestigationTaxId]: "Tax ID verification document",
    // For portfolio analysis
    [UploadDocumentType.BrokerageAccountCostBasis]:
      "Brokerage Account Cost Basis",
    // Clearing account specific
    [UploadDocumentType.EntityFormationDocument]: "Entity Formation Document",
    [UploadDocumentType.W9Form]: "W-9 Certification",
  };
