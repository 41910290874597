import { useMemo } from "react";

import {
  ClearingAccountRestrictionType,
  EntityAccountRequestStatusState,
  MfaQueryResult,
  ProgressFlagCategory,
  ProgressFlagStatus,
  UserQueryResult,
} from "../generated/graphql";

export enum AuthStatus {
  LOADING = "loading",
  GUEST = "guest",
  REGISTERED = "registered",
  MFA_LOGIN = "mfa-login",
  MFA_LOADING = "MFA_LOADING",
}

export const useUserCommon = ({
  userQueryResult,
  mfaQueryResult,
  loading,
  isEmployeeInLocalStorage,
}: {
  userQueryResult: UserQueryResult;
  mfaQueryResult: MfaQueryResult;
  loading: boolean;
  isEmployeeInLocalStorage?: string;
}) => {
  const { data, ...res } = userQueryResult;

  const {
    data: userMfaData,
    loading: isUserMfaLoading,
    refetch: refetchMfa,
  } = mfaQueryResult;

  const userEmailHash = data?.user?.userContactEmail?.emailHash ?? undefined;

  return useMemo(
    () => ({
      ...res,
      user: data?.user ?? null,
      // Is true when user completed onboarding progress and uploaded CIP documents
      isOnboarded:
        data?.user?.userProgressFlags?.find(
          (item) => item?.category === ProgressFlagCategory.Onboarding
        )?.status === ProgressFlagStatus.Completed,
      /**
       * It's possible for user to have clearingAccount created without
       * completing onboarding process either because they didn't finish
       * email verification or they didn't upload CIP documents
       */
      isClearingAccountCreated: !!data?.user?.clearingAccounts?.length,
      // Rejected is a restriction set when the account request is marked as rejected
      isRejected: data?.user?.clearingAccounts?.[0]?.restrictions?.find(
        (restriction) =>
          restriction.restrictionType ===
          ClearingAccountRestrictionType.Rejected
      )?.active,
      loading,
      isEmployee: !!isEmployeeInLocalStorage || data?.user?.isEmployee || false,
      isPendingTrustAccount:
        data?.user?.entityAccountRequestStatus?.state ===
        EntityAccountRequestStatusState.PendingTrustAccount,
      isPendingBusinessAccount:
        data?.user?.entityAccountRequestStatus?.state ===
        EntityAccountRequestStatusState.PendingBusinessAccount,
      mfaStatus: userMfaData?.mfa.userMfaStatus,
      mfaPreferences: userMfaData?.mfa.userMfaPreferences,
      phoneLast4: userMfaData?.mfa.phoneLast4,
      authStatus: loading
        ? AuthStatus.LOADING
        : data?.user
        ? AuthStatus.REGISTERED
        : userMfaData?.mfa
        ? AuthStatus.MFA_LOGIN
        : isUserMfaLoading
        ? AuthStatus.MFA_LOADING
        : AuthStatus.GUEST,
      refetchMfa,
      userInviteCodes: data?.user?.inviteCodes,
      userEmailHash,
    }),
    [
      data?.user,
      userEmailHash,
      isEmployeeInLocalStorage,
      isUserMfaLoading,
      loading,
      refetchMfa,
      res,
      userMfaData?.mfa,
    ]
  );
};
