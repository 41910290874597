import { useEffect } from "react";
export default function ScrollToHash() {
  useEffect(() => {
    // scroll to hash when first loading
    // browser would normally do this for us, but fails when rendering only on client
    if (location.hash) {
      const element = document.getElementById(location.hash.slice(1));
      if (element) {
        element.scrollIntoView();
      }
    }
  }, []);

  return null;
}
